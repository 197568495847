export const handleAddOdds = async (
  e,
  event,
  odd,
  predictions,
  dispatch,
  setPredictions,
  activeSportKey
) => {
  e?.stopPropagation();

  let isExist = false;
  if (predictions) {
    let listItems = [];

    listItems = predictions.filter((i) => {
      if (i.game_id === event.game_id) {
        if (i.oc_pointer === odd.oc_pointer) {
          isExist = true;
        } else {
          return i;
        }
      } else {
        return i;
      }
    });
    localStorage.setItem("createPrediction", JSON.stringify(listItems));
    dispatch(setPredictions(listItems));
  }

  if (isExist) return;

  if (predictions) {
    let items = [];

    items = predictions.filter((i) => i.game_id !== event.game_id);

    items.push({
      sgame_id: event.sgame_id,
      sport_id: event.sport_id,
      sport_name: event.sport_name,
      sport_name_en: event.sport_name_en ? event.sport_name_en : "",
      tournament_id: event.tournament_id,
      tournament_name: event.tournament_name,
      tournament_name_en: event.tournament_name_en
        ? event.tournament_name_en
        : "",
      country_name: event.country_name,
      opp_1_name: event.opp_1_name,
      opp_2_name: event.opp_2_name,
      opp_1_name_en: event.opp_1_name_en ? event.opp_1_name_en : "",
      opp_2_name_en: event.opp_2_name_en ? event.opp_2_name_en : "",
      opp_1_icon: event.opp_1_icon,
      opp_2_icon: event.opp_2_icon,
      oc_group_name: odd?.oc_group_name,
      oc_name: odd?.oc_name,
      oc_rate: odd?.oc_rate,
      oc_block: odd?.oc_block,
      oc_pointer: odd?.oc_pointer,
      change: odd?.change,
      game_id: event.game_id,
      game_start: event.game_start,
      oddsType: activeSportKey === "live" ? "live" : "line",
    });

    localStorage.setItem("createPrediction", JSON.stringify(items));
    dispatch(setPredictions(items));
  }
};

export const handleActiveOdds = (pointer, predictions) => {
  const flag = predictions.some((i) => i.oc_pointer === pointer);
  return flag;
};


export const totalCoef = (predictions) => {
  let total = 1;
  predictions.map((item) => {
    total = total * parseFloat(item.oc_rate);
  });

  return total;
};