import React from "react";
import { useTranslation } from "react-i18next";

import ChartTooltip from "./ChartTooltip/ChartTooltip";

import "./HorizontalChart.css";
import { formatPrice } from "../../../helpers/formater";

const HorizontalChart = ({
  title,
  titleIcon,
  chartData,
  isLoading,
  isCoins,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="horizontal-chart-wrapper">
      <div className="chart-title-wrapper">
        {titleIcon && (
          <img className="chart-title-img" src={titleIcon} alt="icon" />
        )}
        <div className="chart-title">{title}</div>
      </div>

      {isLoading ? (
        <div className="horizontal-chart__loader-wrapper">
          <div className="horizontal-chart__loader"></div>
        </div>
      ) : (
        <>
          <div className="scale">
            <div className="scale-item min-scale-item">
              <div className="scale-number">
                {isCoins
                  ? formatPrice(chartData?.bounds?.min)
                  : chartData?.bounds?.min}
              </div>
              <div className="scale-delimeter"></div>
              <div className="scale-title">{t("chart.min")}</div>
            </div>
            <div className="scale-item middle-scale-item">
              <div className="scale-number">
                {isCoins
                  ? formatPrice(chartData?.bounds?.avg)
                  : chartData?.bounds?.avg}
              </div>
              <div className="scale-delimeter"></div>
              <div className="scale-title">{t("chart.avg")}</div>
            </div>
            <div className="scale-item max-scale-item">
              <div className="scale-number">
                {isCoins
                  ? formatPrice(chartData?.bounds?.max)
                  : chartData?.bounds?.max}
              </div>
              <div className="scale-delimeter"></div>
              <div className="scale-title">{t("chart.max")}</div>
            </div>
            <div className="scale-progress"></div>
          </div>
          <div className="chart-area-wrapper">
            <div className="chart-area-inner">
              <div className="chart-area-y">
                {chartData?.data ? (
                  <>
                    {chartData?.data.map((item) => (
                      <div className="chart-area-y__item" key={item.title}>
                        {item.title}
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
              <div className="chart-area">
                <div className="chart-area-lines">
                  <div className="chart-area-line"></div>
                  <div className="chart-area-line"></div>
                  <div className="chart-area-line"></div>
                  <div className="chart-area-line"></div>
                </div>

                {chartData?.data ? (
                  <>
                    {chartData?.data.map((item) => (
                      <ChartTooltip key={item.title} item={item}>
                        <div className="chart-area__item"></div>
                      </ChartTooltip>
                    ))}
                  </>
                ) : null}
              </div>
            </div>
            <div className="chart-area-x">
              <div className="chart-area-x__item">0%</div>
              <div className="chart-area-x__item item25">25%</div>
              <div className="chart-area-x__item item50">50%</div>
              <div className="chart-area-x__item item75">75%</div>
              <div className="chart-area-x__item">100%</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HorizontalChart;
