import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import AlertNotification from "../../../General/AlertNotification/AlertNotification";
import GameVote from "../GameVote/GameVote";

import { formatDate, formatTeamLinkIcon } from "../../../../helpers/formater";

import "./GameInfo.css";

const GameInfo = ({
  gameId,
  gameDetails,
  gameAPIDetails,
  isLoading,
  isScrolled,
  gameInfoMobileRef,
  getScore
}) => {
  const { t, i18n } = useTranslation();

  const isTablet = useMediaQuery({ maxWidth: 1024 });

  const [showNotification, setShowNotification] = useState(false);

  const goBack = () => {
    window.history.back();
  };

  const prepareLink = () => {
    if (gameAPIDetails.game_id) {
      const path =
        localStorage.getItem("language") === "ua"
          ? "/sportline"
          : "/en/sportline";
      const params = `?key=${gameDetails.gameStatus}&sport=${gameAPIDetails.sport_id}&game=${gameAPIDetails.game_id}`;

      return path + params;
    }
  };

  const copyCurrentLinkToClipboard = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setShowNotification(true);
      })
      .catch((err) => {});
  };

  const renderLiveTimeInfo = () => {
    if (gameDetails?.gameStatus === "live")
      return (
        <div className="game-details__live-status-wrapper">
          <div className="game-details__match-time">
            {gameDetails?.gameStatus && gameDetails.sport_id === 1 && (
              <div className="game-period">
                {gameAPIDetails.period_name === "2 Тайм" ? (
                  <>{t("period_2")}</>
                ) : (
                  <>{t("period_1")}</>
                )}
              </div>
            )}
            <div className="game-details__match-time_delimeter"></div>
            <div className="live-champions-leaue-score-minute">
              {gameAPIDetails.game_desk === "Перерыв" ? (
                <>{t("half_time")}</>
              ) : (
                <>
                  {Math.floor(gameAPIDetails?.timer / 60)}
                  <span className="blinking-animation">'</span>
                  {gameAPIDetails.extra_time &&
                    gameAPIDetails.extra_time !== "" && (
                      <div className="extra-time">
                        <img src="/timer.svg" alt="" />
                        <span>
                          {gameAPIDetails.extra_time} {t("mintute")}
                        </span>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      );
  };

  const renderMobileGameInfo = () => {
    return (
      <div
        className={
          isScrolled
            ? "game-details__game-info scrolled"
            : "game-details__game-info"
        }
        ref={gameInfoMobileRef}
      >
        <div className="game-info__top-tab">
          <div className="back__btn" onClick={goBack}>
            <img src="/gameDetail/back.svg" alt="back" />
          </div>
          <div className="game-info__league-info">
            <div className="game-info__league-name">
              {!gameDetails.game_id ? (
                <div className="skeleton-placeholder-light game-info__league-loader"></div>
              ) : (
                <>
                  {localStorage.getItem("language") === "ua"
                    ? gameDetails.sport_name + ". "
                    : gameDetails.sport_name_en + ". "}
                  {localStorage.getItem("language") === "ua"
                    ? gameDetails.tournament_name
                    : gameDetails.tournament_name_en}
                </>
              )}
            </div>
            <div className="game-info__match">
              {!gameDetails.game_id ? (
                <div className="skeleton-placeholder-light game-info__date-loader"></div>
              ) : (
                <>
                  {gameDetails?.gameStatus === "live" ? (
                    <>{renderLiveTimeInfo()}</>
                  ) : (
                    <>
                      {formatDate(
                        gameDetails.game_start,
                        localStorage.getItem("language")
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="copy-link__btn" onClick={copyCurrentLinkToClipboard}>
            <img src="/gameDetail/link.svg" alt="link" />
          </div>
        </div>
        <div className="game-info__teams-info-wrapper">
          <div className="game-info__team-info team1-info">
            <div className="game-info__team-name team1-name">
              {!gameDetails.game_id ? (
                <div className="skeleton-placeholder-light game-info__team-name-loader"></div>
              ) : (
                <>
                  {localStorage.getItem("language") === "ua"
                    ? gameDetails.opp_1_name
                    : gameDetails.opp_1_name_en}
                </>
              )}
            </div>
            <div className="game-details__team-img-wrapper ">
              {gameDetails.opp_1_icon && (
                <img
                  className="game-details__team-logo"
                  src={formatTeamLinkIcon(gameDetails?.opp_1_icon)}
                  alt="team1"
                />
              )}
            </div>
          </div>
          <div className="game-info__teams-score">
            {getScore(1)} : {getScore(2)}
          </div>
          <div className="game-info__team-info team2-info">
            <div className="game-details__team-img-wrapper">
              {gameDetails.opp_2_icon && (
                <img
                  className="game-details__team-logo"
                  src={formatTeamLinkIcon(gameDetails?.opp_2_icon)}
                  alt="team2"
                />
              )}
            </div>
            <div className="game-info__team-name">
              {!gameDetails.game_id ? (
                <div className="skeleton-placeholder-light game-info__team-name-loader"></div>
              ) : (
                <>
                  {localStorage.getItem("language") === "ua"
                    ? gameDetails.opp_2_name
                    : gameDetails.opp_2_name_en}
                </>
              )}
            </div>
          </div>
        </div>
        {gameDetails.gameStatus !== "completed" && (
          <div className="make-prediction__link-wrapper">
            <a
              className={
                !gameDetails.game_id
                  ? "make-prediction__link loading"
                  : "make-prediction__link"
              }
              href={prepareLink()}
            >
              {t("gameDetail.makePrediction")}
            </a>
          </div>
        )}
      </div>
    );
  };

  const renderTopMobileGameInfo = () => {
    return (
      <div
        className={
          isScrolled
            ? "game-details__top-mobile-panel scrolled"
            : "game-details__top-mobile-panel"
        }
      >
        <div className="game-info__top-tab">
          <div className="back__btn" onClick={goBack}>
            <img src="/gameDetail/back.svg" alt="back" />
          </div>

          <div className="game-info__teams-info-wrapper">
            <div className="game-info__team-info team1-info">
              <div className="game-info__team-name team1-name">
                {!gameDetails.game_id ? (
                  <div className="skeleton-placeholder-light game-info__team-name-loader"></div>
                ) : (
                  <>
                    {localStorage.getItem("language") === "ua"
                      ? gameDetails.opp_1_name
                      : gameDetails.opp_1_name_en}
                  </>
                )}
              </div>
              <div className="game-details__team-img-wrapper ">
                {gameDetails.opp_1_icon && (
                  <img
                    className="game-details__team-logo"
                    src={formatTeamLinkIcon(gameDetails?.opp_1_icon)}
                    alt="team1"
                  />
                )}
              </div>
            </div>
            <div className="game-info__teams-score">
              {getScore(1)}:{getScore(2)}
            </div>
            <div className="game-info__team-info team2-info">
              <div className="game-details__team-img-wrapper">
                {gameDetails.opp_2_icon && (
                  <img
                    className="game-details__team-logo"
                    src={formatTeamLinkIcon(gameDetails?.opp_2_icon)}
                    alt="team2"
                  />
                )}
              </div>
              <div className="game-info__team-name">
                {!gameDetails.game_id ? (
                  <div className="skeleton-placeholder-light game-info__team-name-loader"></div>
                ) : (
                  <>
                    {localStorage.getItem("language") === "ua"
                      ? gameDetails.opp_2_name
                      : gameDetails.opp_2_name_en}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="copy-link__btn" onClick={copyCurrentLinkToClipboard}>
            <img src="/gameDetail/link.svg" alt="link" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isTablet ? (
        <>
          {renderTopMobileGameInfo()}
          {renderMobileGameInfo()}
        </>
      ) : (
        <>
          <div className="game-details__game-info">
            <div className="game-details__controls">
              <div className="back__btn" onClick={goBack}>
                <img src="/gameDetail/back.svg" alt="back" />
              </div>
              <div
                className="copy-link__btn"
                onClick={copyCurrentLinkToClipboard}
              >
                <img src="/gameDetail/link.svg" alt="link" />
              </div>
            </div>
            <div className="game-details__teams-info">
              <div className="game-details__teams-imgs">
                <div className="game-details__team-img-wrapper team1-img-wrapper">
                  {gameDetails.opp_1_icon && (
                    <img
                      className="game-details__team-logo"
                      src={formatTeamLinkIcon(gameDetails?.opp_1_icon)}
                      alt="team1"
                    />
                  )}
                </div>
                <div className="game-details__team-img-wrapper team2-img-wrapper">
                  {gameDetails.opp_2_icon && (
                    <img
                      className="game-details__team-logo"
                      src={formatTeamLinkIcon(gameDetails?.opp_2_icon)}
                      alt="team1"
                    />
                  )}
                </div>
              </div>
              {renderLiveTimeInfo()}
              <div className="game-details__teams-name">
                {gameDetails.game_id ? (
                  <>
                    <div className="game-details__team-name-wrapper">
                      <div className="game-details__team-name">
                        {localStorage.getItem("language") === "ua"
                          ? gameDetails.opp_1_name
                          : gameDetails.opp_1_name_en}
                      </div>
                      <div
                        className={
                          gameDetails?.gameStatus === "live"
                            ? "game-details__team-score live"
                            : "game-details__team-score"
                        }
                      >
                        {getScore(1)}
                      </div>
                    </div>
                    <div className="game-details__team-name-wrapper">
                      <div className="game-details__team-name">
                        {localStorage.getItem("language") === "ua"
                          ? gameDetails.opp_2_name
                          : gameDetails.opp_2_name_en}
                      </div>
                      <div
                        className={
                          gameDetails?.gameStatus === "live"
                            ? "game-details__team-score live"
                            : "game-details__team-score"
                        }
                      >
                        {getScore(2)}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="game-details__team-name-wrapper">
                      <div className="skeleton-placeholder-light game-details_team1-name_loader"></div>
                      <div className="skeleton-placeholder-light game-details_team-score_loader"></div>
                    </div>
                    <div className="game-details__team-name-wrapper">
                      <div className="skeleton-placeholder-light game-details_team2-name_loader"></div>
                      <div className="skeleton-placeholder-light game-details_team-score_loader"></div>
                    </div>
                  </>
                )}
              </div>
              <div className="game-details__info-about">
                {gameDetails.game_id ? (
                  <>
                    <div className="game-details__info-about-item">
                      <img
                        className="info-about-item__img"
                        src={"/sport-logo/" + gameDetails.sport_id + ".svg"}
                        alt="icon"
                      />
                      <div className="info-about-item__text">
                        {localStorage.getItem("language") === "ua"
                          ? gameDetails.sport_name + " "
                          : gameDetails.sport_name_en + " "}
                        {localStorage.getItem("language") === "ua"
                          ? gameDetails.tournament_name
                          : gameDetails.tournament_name_en}
                      </div>
                    </div>
                    <div className="game-details__info-about-item">
                      <img
                        className="info-about-item__img"
                        src="/gameDetail/calendar.svg"
                        alt="icon"
                      />
                      <div className="info-about-item__text">
                        {formatDate(
                          gameDetails.game_start,
                          localStorage.getItem("language")
                        )}
                      </div>
                    </div>
                    <div className="game-details__info-about-item">
                      <img
                        className="info-about-item__img"
                        src="/gameDetail/status.svg"
                        alt="icon"
                      />
                      <div className="info-about-item__text">
                        {gameDetails?.gameStatus === "live"
                          ? t("gameDetail.gameStatuses.live")
                          : gameDetails?.gameStatus === "line"
                          ? t("gameDetail.gameStatuses.pending")
                          : t("gameDetail.gameStatuses.completed")}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="game-details__info-about-item">
                      <div className="skeleton-placeholder-light game-details_info-img_loader"></div>
                      <div className="skeleton-placeholder-light game-details_info-title_loader"></div>
                    </div>
                    <div className="game-details__info-about-item">
                      <div className="skeleton-placeholder-light game-details_info-img_loader"></div>
                      <div className="skeleton-placeholder-light game-details_info-title_loader"></div>
                    </div>
                    <div className="game-details__info-about-item">
                      <div className="skeleton-placeholder-light game-details_info-img_loader"></div>
                      <div className="skeleton-placeholder-light game-details_info-title_loader"></div>
                    </div>
                  </>
                )}
              </div>
              {gameDetails.gameStatus !== "completed" && (
                <a
                  className={
                    isLoading
                      ? "make-prediction__link loading"
                      : "make-prediction__link"
                  }
                  href={prepareLink()}
                >
                  {t("gameDetail.makePrediction")}
                </a>
              )}

              <GameVote
                gameId={gameId}
                gameAPIDetails={gameAPIDetails}
                gameDetails={gameDetails}
              />
            </div>
          </div>
        </>
      )}
      {showNotification && (
        <AlertNotification
          message={t("gameDetail.copyAlert")}
          onClose={() => setShowNotification(false)}
          duration={2000}
          isVisible={showNotification}
        />
      )}
    </>
  );
};

export default GameInfo;
