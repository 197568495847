import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import apiService from "../../../services/apiService";
import { useTranslation } from "react-i18next";
import { prepareFullName, preparePredictionLink } from "../../../helpers/formater";
import ReadMoreLessText from "../../General/ReadMoreLessText/ReadMoreLessText";

const MorePredicrionModel = (props) => {
  useEffect(() => {}, [props]);
  const { t, i18n } = useTranslation();
  const handlePredictionLanguageLocalization = (data) => {
    var localLocale = moment.unix(data);
    localLocale.locale(localStorage.getItem("language") == "ua" ? "uk" : "en");
    return (
      localLocale.format("MMMM D").charAt(0).toUpperCase() +
      localLocale.format("MMMM D").slice(1)
    );
  };
  const calculateReturnsRate = (odds) => {
    let od = 1;
    for (let i of odds) {
      if (i.result.statusText == "Returned") {
      } else {
        od = od * parseFloat(i.oc_rate.toFixed(2));
      }
    }
    return parseFloat(od.toFixed(2));
  };
  const handleOddsReturnData = (item) => {
    // let totalOdds = getOddsMultiply(item.odds);
    let totalOdds = 1;
    item?.odds?.map((i) => {
      totalOdds *= i?.oc_rate.toFixed(2);
      item.totalOddsData = totalOdds;
    });
    if (item.isReturnOdds) {
      const y = item?.totalOdds == 1 ? "0.0" : calculateReturnsRate(item?.odds);
      const x = totalOdds?.toFixed(2) + " / " + y;
      return x;
    } else {
      return totalOdds?.toFixed(2);
    }
  };
  return (
    <>
      <div className="">
        <Modal
          className="more-prediction-model-div"
          show={props.isModel}
          onHide={props.hide}
        >
          <Modal.Header>
            <Modal.Title>
              <div className="addUser-img-date-name d-flex">
                {!props.data.userId?.profileImgUrl ? (
                  <div
                    className="me-3 filter-user-avtar nav-avt rounded-circle d-flex align-items-center justify-content-center"
                    alt="Avatar "
                  >
                    <p className="navbar-letter">
                      {props.data.userId?.firstName?.charAt(0)}
                    </p>
                  </div>
                ) : (
                  <div className=" me-3">
                    <img
                      className="addeduser-img"
                      src={props.data.userId?.profileImgUrl}
                    />
                  </div>
                )}
                <div>
                  <div
                    className="addeduser-name"
                    onClick={() => props.handleUserProfile(props.data?.userId)}
                  >
                    {prepareFullName(props?.data?.userId)}
                  </div>
                  <div className="addeduser-date-time">
                    {/* {moment.unix(item.createdDate).format("MMMM D")} */}
                    {handlePredictionLanguageLocalization(
                      props.data?.createdDate
                    )}
                    , {moment.unix(props.data?.createdDate).format("HH: mm")}
                  </div>
                </div>
              </div>
            </Modal.Title>
            <div className="model-close-btn" onClick={props.hide}>
              <span>
                <img
                  src="/close-squere-btn.svg"
                  alt="close"
                  className="close-button close-squere-button"
                />
              </span>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="prediction-list-content">
              {props.data?.comments ? (
                <div className="prediction-slip-decription prediction-slip-decription-modal">
                  <ReadMoreLessText text={props.data?.comments} />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="prediction-list-model-scroll">
              <div className="model-prdiction-card">
                {props.data.odds?.map((item, index) => (
                  <div key={index} className="model-prediction-team-data">
                    <div className="both-team-league-time-sport d-flex pb-3">
                      <span className="d-flex align-items-center">
                        <img
                          src={"/sport-logo/" + item.sport_id + ".svg"}
                          width="16px"
                          height="16px"
                        />
                      </span>
                      {apiService.getProfileFromStorage()?.Language == "en"
                        ? item.sport_name_en
                          ? item.sport_name_en
                          : item.sport_name
                        : item.sport_name}{" "}
                      .{" "}
                      <span className="earning-coin-tournament_name">
                        {apiService.getProfileFromStorage()?.Language == "en"
                          ? item.tournament_name_en
                            ? item.tournament_name_en
                            : item.tournament_name
                          : item.tournament_name}{" "}
                      </span>
                      |{/* {moment.unix(item.game_start).format("MMMM D")}, */}{" "}
                      <span className="ps-2 d-flex">
                        {handlePredictionLanguageLocalization(item.game_start)},
                        {moment.unix(item.game_start).format("HH: mm")}
                      </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center oc_teams_details">
                        <div className=" both-team-logo-odds-data d-flex justify-content-between align-items-center">
                          <div className="both-Team-logo">
                            <span className="team1-logo">
                              <img
                                src={
                                  process.env.REACT_APP_teamLogo +
                                  item?.opp_1_icon
                                }
                                height="33.42px"
                                width="33.42px"
                                style={{ borderRadius: "50px" }}
                              />
                            </span>
                            <span className="team2-logo">
                              <img
                                className="team-2-logo"
                                src={
                                  process.env.REACT_APP_teamLogo +
                                  item?.opp_2_icon
                                }
                                height="33.42px"
                                width="33.42px"
                                style={{ borderRadius: "50px" }}
                              />
                            </span>
                          </div>
                          <div className="mobile-screen-odds-data d-flex align-items-center pb-3">
                            <div className="single-prediction-result">
                              {item?.result.score
                                ? item?.result.score?.split("(")[0]
                                : "-:-"}
                            </div>
                            <div
                              className="single-prediction-result ms-3"
                              style={{
                                backgroundColor: item.result
                                  ? item.result?.statusText === "Won"
                                    ? "#EEFBFB"
                                    : item.result?.statusText === "Lost"
                                    ? "#FCE8DE"
                                    : item.result?.statusText === "Returned"
                                    ? "#FCF7E4"
                                    : "#F2F3F7"
                                  : "",
                                color: item.result
                                  ? item.result?.statusText === "Won"
                                    ? "#208B8E"
                                    : item.result?.statusText === "Lost"
                                    ? "#D14343"
                                    : item.result?.statusText === "Returned"
                                    ? "#C09E16"
                                    : "#131520"
                                  : "",
                              }}
                            >
                              {item.oc_rate?.toFixed(2)}
                            </div>
                          </div>
                        </div>

                        <div className="both-team-contains">
                          <div className="both-team-name d-flex align-items-center">
                            <a
                              href={preparePredictionLink(item)}
                              className="teams-name"
                            >
                              <span>
                                {apiService.getProfileFromStorage()?.Language ==
                                "en"
                                  ? item?.opp_1_name_en
                                    ? item?.opp_1_name_en
                                    : item?.opp_1_name
                                  : item?.opp_1_name}
                              </span>
                              <span>-</span>
                              <span>
                                {apiService.getProfileFromStorage()?.Language ==
                                "en"
                                  ? item?.opp_2_name_en
                                    ? item?.opp_2_name_en
                                    : item?.opp_2_name
                                  : item?.opp_2_name}
                              </span>
                            </a>
                          </div>
                          <div className="both-team-league-name d-flex">
                            {item.oc_group_name === "1x2"
                              ? "Full-time result"
                              : item.oc_group_name}
                            <span className="px-1"> - </span>
                            <span className="both-team-oc-name">
                              {item.oc_name}
                            </span>
                          </div>
                          <div></div>
                        </div>
                      </div>
                      <div className="large-screen-odds-data d-flex align-items-center">
                        <div className="single-prediction-result">
                          {item?.result.score
                            ? item?.result.score?.split("(")[0]
                            : "-:-"}
                        </div>
                        <div
                          className="single-prediction-result ms-3"
                          style={{
                            backgroundColor: item.result
                              ? item.result?.statusText === "Won"
                                ? "#EEFBFB"
                                : item.result?.statusText === "Lost"
                                ? "#FCE8DE"
                                : item.result?.statusText === "Returned"
                                ? "#FCF7E4"
                                : "#F2F3F7"
                              : "",
                            color: item.result
                              ? item.result?.statusText === "Won"
                                ? "#208B8E"
                                : item.result?.statusText === "Lost"
                                ? "#D14343"
                                : item.result?.statusText === "Returned"
                                ? "#C09E16"
                                : "#131520"
                              : "",
                          }}
                        >
                          {item.oc_rate?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="">
              <div className="row prediction-slip-odds-stakedCoin-possibleWin-type-status-div d-flex">
                {/* odds */}
                <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-odd-div">
                  <div className="odds-title">{t("feed.TotalOdds")}</div>
                  <div className="data">
                    <div className="d-flex align-items-center">
                      {handleOddsReturnData(props.data)}
                      {props.data?.isReturnOdds ? (
                        <div className="ps-1 alert-circle-tooltip-div">
                          <div className="alert-circle-image-tooltip">
                            {t("feed.tooltipMsg")}
                          </div>
                          <span className="alert-circle-image d-flex align-items-center">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.709 10.795L11.568 10.874C10.92 11.236 10.691 11.422 10.696 11.74C10.701 12.154 10.37 12.494 9.956 12.5H9.946C9.536 12.5 9.201 12.171 9.196 11.76C9.179 10.492 10.216 9.912 10.835 9.566L10.976 9.486C11.545 9.173 11.829 8.739 11.829 8.193C11.829 7.29 11.094 6.556 10.191 6.556C9.272 6.556 8.552 7.275 8.552 8.193C8.552 8.607 8.216 8.943 7.802 8.943C7.388 8.943 7.052 8.607 7.052 8.193C7.052 6.463 8.46 5.056 10.191 5.056C11.921 5.056 13.329 6.463 13.329 8.193C13.329 9.296 12.753 10.22 11.709 10.795ZM9.947 14.96C9.533 14.96 9.197 14.639 9.197 14.225V14.195C9.197 13.781 9.533 13.445 9.947 13.445C10.361 13.445 10.697 13.781 10.697 14.195C10.697 14.609 10.361 14.96 9.947 14.96ZM10 0.5C4.761 0.5 0.5 4.762 0.5 10C0.5 15.238 4.761 19.5 10 19.5C15.238 19.5 19.5 15.238 19.5 10C19.5 4.762 15.238 0.5 10 0.5Z"
                                fill="#BFC3D9"
                              />
                            </svg>
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {/* Staked coins */}
                <div className="col-sm-4  col-4 col-lg-4 col-md-4 predictionlist-Staked-coins">
                  <div className="predictionlist-coins-title">
                    {t("feed.StakedCoins")}
                  </div>
                  <div className="data">
                    {props.data?.betTotal ? props.data?.betTotal : "-"}
                  </div>
                </div>
                {/* possible win */}
                <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-possible-win">
                  <div className="win-title">{t("feed.PossibleWin")}</div>
                  <div className="data">
                    {!props.data?.totalOddsData || !props.data?.betTotal
                      ? "-"
                      : (
                          props.data?.totalOddsData?.toFixed(2) *
                          props.data?.betTotal
                        ).toFixed(2)}
                  </div>
                </div>
                {/* type */}
                <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-type-div">
                  <div className="type-title">{t("feed.Type")}</div>
                  <div className="data">
                    {props.handlePredictionListType(props.data)}
                  </div>
                </div>
                {/* status */}
                <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-status-div">
                  <div className="status-title">{t("feed.Status")}</div>
                  <div
                    className="data"
                    style={{
                      backgroundColor: props.data?.BetHeadDetail
                        ? props.data?.BetHeadDetail?.statusText === "Won"
                          ? "#EEFBFB"
                          : props.data?.BetHeadDetail?.statusText === "Lost"
                          ? "#F9DADA"
                          : props.data?.BetHeadDetail?.statusText === "Returned"
                          ? "#FCF7E4"
                          : "#F2F3F7"
                        : "",
                      color: props.data?.BetHeadDetail?.statusText
                        ? props.data?.BetHeadDetail?.statusText === "Won"
                          ? "#0D393A"
                          : props.data?.BetHeadDetail?.statusText === "Lost"
                          ? "#D14343"
                          : props.data?.BetHeadDetail?.statusText === "Returned"
                          ? "#C09E16"
                          : "#131520"
                        : "#131520",
                    }}
                  >
                    {props.data?.BetHeadDetail?.statusText
                      ? props.data.BetHeadDetail?.statusText === "Won"
                        ? t("feed.modelWon")
                        : props.data.BetHeadDetail?.statusText === "Lost"
                        ? t("feed.modelLost")
                        : props.data.BetHeadDetail?.statusText === "Returned"
                        ? t("feed.modelReturn")
                        : t("feed.modelPending")
                      : t("feed.modelPending")}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default MorePredicrionModel;
