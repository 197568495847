import React from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InputButton from "../InputButton";
import Model from "../model/Model";
import BetSlip from "./BetSlip";
import "./addPrediction.css";
import { useState } from "react";
import apiService from "../../../services/apiService";
import { useEffect } from "react";
import { Environment } from "../../../environment";
import { useRef } from "react";
import Notification from "../Notification";
import moment from "moment";
// import 'moment-timezone';
import AddPredictionModel from "../../Custom loader/AddPredictionModelLoader";
import MoreOddsCustomLoader from "../../Custom loader/MoreOddsCustomLoader";
import SearchBar from "../searchBar/SearchBar";
import SportLeagueModelLoader from "../../Custom loader/SportLeagueModelLoader";
import { async } from "q";

const AddPrediction = (props) => {
  const pageSize = 3;
  const [currPage, setCurrPage] = useState(1);
  const listInnerRef = useRef();
  const MediumSizelistInnerRef = useRef();
  const SmallSizelistInnerRef = useRef();
  const [showTurnamentLoader, setShowTurnamentLoader] = useState(true);
  const [sportId, setSportId] = useState("");
  const { t, i18n } = useTranslation();
  const [isSportData, setIsSportData] = useState([]);
  const [tournamentData, setTournamentData] = useState([]);
  // const [activeKey, setActiveKey] = useState(false)
  const [isUpdated, setisUpdated] = useState(0);
  const [isDeleteUpdated, setisDeleteUpdated] = useState(0);
  const [managePredictionData, setManagePrediction] = useState(false);
  const [deleteSportModel, setDeleteSportModel] = useState(false);
  const [selectedSportName, setSelectedSportName] = useState("");
  const [selectedSportId, setSelectedSportId] = useState("");
  const [successPopupShow, setSuccessPopup] = useState(
    "success-popup popup-hide"
  );
  const [isOpenTable, setIsOpenTable] = useState("all-tab-table");
  const [isOddScoreDisplay, setIsOddScoreDisplay] = useState(
    "all-odds-score-display-hide"
  );
  const [extraOdd, setExtraOdd] = useState("");
  const [predicationLIst, setPredictionList] = useState([]);
  const [doRef, setDoRef] = useState(false);
  const [countData, setCountData] = useState(0);
  const [eventList, setEventList] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [extraOddsDataLoader, setExtraOddsDataLoader] = useState(false);
  const [showBetsSlip, setShowBetsSlip] = useState(
    "responsive-addPrediction-right-panel-hide"
  );
  const [AlltournamentsShow, setAllTournamentsShow] = useState(false);
  const [tournamentsFilterBtn, setTournamentsFilterBtn] = useState(false);
  const [tournamentsData, setTournamentsData] = useState("");
  const [AllupcomingsShow, setAllUpcomingShow] = useState(false);
  const [upcomingFilterBtn, setUpcomingFilterBtn] = useState(false);
  const [activeSportId, setActiveSportId] = useState("");
  const [upcomingData, setUpcomingData] = useState("");
  const [filterData, setFilterData] = useState({});
  const [tournamentName, setTournamentName] = useState([]);
  const [tournamentCount, setTournamentCount] = useState("");
  const [tournamentFilter, setTournamentFilter] = useState(true);

  const handleMakePrediction = async () => {
    if (props.predictionTableHide) {
      const res = await apiService.getEventList({
        gameId: props.propularData?.game_id,
        type: "line",
        language: apiService.getProfileFromStorage()?.Language,
      });
      if (res) {
        setEventList(res);
      }
      setExtraOdd(props.propularData);
      setIsOpenTable("all-tab-table-hide");
      setIsOddScoreDisplay("all-odds-score-display");
      setTournamentFilter(false);
    }
  };
  useEffect(() => {
    handleMakePrediction();
  }, [props.gameId, props.predictionTableHide]);
  const getPrediction = () => {
    setDoRef(true);
    if (localStorage.getItem("createPrediction")) {
      const response = apiService.getPredictionFromStorage();
      setPredictionList(response);
    }
  };
  const getSelectedSport = async () => {
    if (localStorage.getItem("prediction-list")) {
      const items = JSON.parse(localStorage.getItem("prediction-list"));
      setIsSportData(items);
    }
  };
  if (
    props.sportId !== "" &&
    props.array &&
    props.sportId !== sportId &&
    props.array.length > 0
  ) {
    setSportId(props.sportId);
  }
  // tournamentClose btn
  const handleAllLeagueList = async (pageNumber = currPage || 1) => {
    let lg = null;
    if (localStorage.getItem("prediction-list")) {
      var items = [];
      var activeKey = false;
      if (localStorage.getItem("prediction-list")) {
        items = JSON.parse(localStorage.getItem("prediction-list"));
        lg = items.find((i) => {
          if (i.activeKey) {
            return i;
          }
        });
      }
      if (!lg) {
        //update localstorage first make active\
        if (items[0]) {
          items[0].activeKey = true;
          localStorage.setItem("prediction-list", JSON.stringify(items));
          lg = items[0];
          await apiService.createFavSport([items]);
        }
      }
    }
    if (!lg) {
      return;
    }
    let isAllEmpty = true;
    const res = await apiService.leagueList({
      isCountryCheck: lg.leagues,
      sportId: lg.sportId,
      language: apiService.getProfileFromStorage().Language,
      pageSize: pageSize,
      pageNumber,
      type: "line",
    });
    setTournamentCount(res?.totalTournament);
    if (res?.tournament) {
      setShowLoader(false);
    }
    const filteredData = res?.tournament?.map((tournament) => {
      tournament.events_list = tournament.events_list.map((event) => {
        const team1 = event.opp_1_name;
        const team2 = event.opp_2_name;
        event.game_oc_list?.map((oc) => {
          const pointer = oc.oc_pointer.split("|");
          if (pointer[1] == 1) {
            if (pointer[2] == 1) {
              event.teamOneWin = oc.oc_rate;
            } else if (pointer[2] == 3) {
              event.teamTwoWin = oc.oc_rate;
            } else {
              event.teamDrowWin = oc.oc_rate;
            }
          }
          if (pointer[1] == 8) {
            if (pointer[2] == 4) {
              event.teamOneWinDouble = oc.oc_rate;
            } else if (pointer[2] == 6) {
              event.teamTwoWinDouble = oc.oc_rate;
            } else {
              event.teamDrowDouble = oc.oc_rate;
            }
          }
        });

        return event;
      });
      if (isAllEmpty) {
        isAllEmpty = tournament.events_list.length == 0;
      }
      return tournament;
    });
    filteredData.isAllEmpty = isAllEmpty;
    if (pageNumber === 1) {
      setTournamentData(filteredData);
    } else {
      let newData = filteredData
        ? [...tournamentData, ...filteredData]
        : tournamentData;
      newData = newData.filter(
        (v, i, a) =>
          a.findIndex((v2) => v2.tournament_id === v.tournament_id) === i
      );
      setTournamentData(newData);
    }
    startInterval();
    var items = [];
    setisUpdated((oldState) => oldState + 1);
  };

  // allLeague
  const handleLeagueList = async (pageNumber = currPage || 1) => {
    let lg = null;
    if (localStorage.getItem("prediction-list")) {
      var items = [];
      var activeKey = false;

      if (localStorage.getItem("prediction-list")) {
        items = JSON.parse(localStorage.getItem("prediction-list"));
        lg = items.find((i) => {
          if (i.activeKey) {
            return i;
          }
        });
      }
      if (!lg) {
        //update localstorage first make active\
        if (items[0]) {
          items[0].activeKey = true;
          localStorage.setItem("prediction-list", JSON.stringify(items));
          lg = items[0];
          await apiService.createFavSport([items]);
        }
      }
    }
    if (!lg) {
      return;
    }
    setShowLoader(true);
    let isAllEmpty = true;
    const res = await apiService.leagueList({
      isCountryCheck:
        tournamentsData !== ""
          ? [`${tournamentsData.tournament_id}`]
          : lg.leagues,
      sportId: lg.sportId,
      language: apiService.getProfileFromStorage().Language,
      pageSize: pageSize,
      pageNumber,
      type: "line",
    });
    setTournamentCount(res?.totalTournament);
    const filteredData = res?.tournament?.map((tournament) => {
      tournament.events_list = tournament.events_list.map((event) => {
        const team1 = event.opp_1_name;
        const team2 = event.opp_2_name;
        event.game_oc_list?.map((oc) => {
          const pointer = oc.oc_pointer.split("|");
          if (pointer[1] == 1) {
            if (pointer[2] == 1) {
              event.teamOneWin = oc.oc_rate;
            } else if (pointer[2] == 3) {
              event.teamTwoWin = oc.oc_rate;
            } else {
              event.teamDrowWin = oc.oc_rate;
            }
          }
          if (pointer[1] == 8) {
            if (pointer[2] == 4) {
              event.teamOneWinDouble = oc.oc_rate;
            } else if (pointer[2] == 6) {
              event.teamTwoWinDouble = oc.oc_rate;
            } else {
              event.teamDrowDouble = oc.oc_rate;
            }
          }
        });
        return event;
      });
      if (isAllEmpty) {
        isAllEmpty = tournament.events_list.length == 0;
      }
      return tournament;
    });
    filteredData.isAllEmpty = isAllEmpty;
    if (pageNumber == 1) {
      setTournamentData(filteredData);
    } else {
      let newData = filteredData
        ? [...tournamentData, ...filteredData]
        : tournamentData;
      newData = newData.filter(
        (v, i, a) =>
          a.findIndex((v2) => v2.tournament_id === v.tournament_id) === i
      );
      setTournamentData(newData);
    }
    startInterval();
    var items = [];
    setisUpdated((oldState) => oldState + 1);
    setShowLoader(false);
  };
  const handleLiveLeagueList = (pageNumber = currPage || 1) => {};
  const startInterval = () => {
    if (
      localStorage.getItem("isInterVal") === "false" &&
      tournamentData.length > 0
    ) {
      // setInterval(() => {
      //     //console.log("set interval running...")
      //     setisInterVal(isInterVal + 1)
      // }, 5000)
      localStorage.setItem("isInterVal", true);
    }
  };
  const handleActiveSport = async (id, leagues) => {
    if (localStorage.getItem("prediction-list")) {
      let items = JSON.parse(localStorage.getItem("prediction-list"));
      items = items.map((i) => {
        if (i.sportId == id) {
          i.activeKey = true;
        } else {
          i.activeKey = false;
        }
        return i;
      });
      localStorage.setItem("prediction-list", JSON.stringify(items));
      setIsSportData(items);
      setIsOddScoreDisplay("all-odds-score-display-hide");
      setIsOpenTable("all-tab-table");
      setTournamentFilter(true);
    } else {
      const res = await apiService.getFavSport();
      res = res.map((i) => {
        if (i.sportId == id) {
          i.activeKey = true;
        } else {
          i.activeKey = false;
        }
        return i;
      });
      setIsSportData(res);
      setIsOddScoreDisplay("all-odds-score-display-hide");
      setIsOpenTable("all-tab-table");
      setTournamentFilter(true);
    }
    let isAllEmpty = true;
    const res = await apiService.leagueList({
      isCountryCheck: leagues,
      sportId: id,
      language: apiService.getProfileFromStorage().Language,
      type: "line",
    });
    const filteredData = res?.tournament?.map((tournament) => {
      tournament.events_list = tournament.events_list?.map((event) => {
        const team1 = event.opp_1_name;
        const team2 = event.opp_2_name;
        event.game_oc_list?.map((oc) => {
          const pointer = oc.oc_pointer.split("|");
          if (pointer[1] == 1) {
            if (pointer[2] == 1) {
              event.teamOneWin = oc.oc_rate;
            } else if (pointer[2] == 3) {
              event.teamTwoWin = oc.oc_rate;
            } else {
              event.teamDrowWin = oc.oc_rate;
            }
          }
          if (pointer[1] == 8) {
            if (pointer[2] == 4) {
              event.teamOneWinDouble = oc.oc_rate;
            } else if (pointer[2] == 6) {
              event.teamTwoWinDouble = oc.oc_rate;
            } else {
              event.teamDrowDouble = oc.oc_rate;
            }
          }
        });
        return event;
      });
      return tournament;
    });
    // filteredData.isAllEmpty = isAllEmpty
    setTournamentData(filteredData);
    var items = [];
    if (localStorage.getItem("prediction-list")) {
      items = JSON.parse(localStorage.getItem("prediction-list"));
      items = items.map((i) => {
        if (i.sportId === id) {
          i.activeKey = true;
        } else {
          i.activeKey = false;
        }
        return i;
      });
      setIsSportData(items);
      localStorage.setItem("prediction-list", JSON.stringify(items));
    } else {
      const res = await apiService.getFavSport();
      res = res.map((i) => {
        if (i.sportId == id) {
          i.activeKey = true;
        } else {
          i.activeKey = false;
        }
        return i;
      });
      setIsSportData(res);
      setIsOddScoreDisplay("all-odds-score-display-hide");
      setIsOpenTable("all-tab-table");
      setTournamentFilter(true);
    }
  };
  useEffect(() => {}, [tournamentData]);
  useEffect(() => {
    handleLeagueList();
    getSelectedSport();
  }, [sportId, isDeleteUpdated, tournamentsData]);

  useEffect(() => {
    getPrediction();
    getSelectedSport();
  }, [isUpdated, doRef]);
  const handleLeaueActive = async (event, team, odds, e) => {
    e?.stopPropagation();
    //check if item already exist
    let isExist = false;
    if (localStorage.getItem("createPrediction")) {
      let listItems = [];
      let itemsLocal = JSON.parse(localStorage.getItem("createPrediction"));
      listItems = itemsLocal.filter((i, index) => {
        const pointer = i.oc_pointer.split("|");
        if (i.game_id == event.game_id) {
          if (pointer[2] == team) {
            isExist = true;
          } else {
            return i;
          }
        } else {
          return i;
        }
      });
      localStorage.setItem("createPrediction", JSON.stringify(listItems));
    }
    setCountData(countData + 1);
    if (isExist) return;
    const ocData = event.game_oc_list.find((oc) => {
      const pointer = oc.oc_pointer.split("|");
      if (pointer[2] == team && pointer[1] == odds) {
        return oc;
      }
    });
    let items = [];
    if (localStorage.getItem("createPrediction")) {
      let itemsLocal = JSON.parse(localStorage.getItem("createPrediction"));
      items = itemsLocal.filter((i, index) => {
        if (i.game_id !== event.game_id) {
          return i;
        }
      });
      localStorage.setItem("createPrediction", JSON.stringify(items));
    }
    items.push({
      sport_id: event.sport_id,
      sport_name: event.sport_name,
      sport_name_en: event.sport_name_en ? event.sport_name_en : "",
      tournament_id: event.tournament_id,
      tournament_name: event.tournament_name,
      tournament_name_en: event.tournament_name_en
        ? event.tournament_name_en
        : "",
      country_name: event.country_name,
      opp_1_name: event.opp_1_name,
      opp_2_name: event.opp_2_name,
      opp_1_name_en: event.opp_1_name_en ? event.opp_1_name_en : "",
      opp_2_name_en: event.opp_2_name_en ? event.opp_2_name_en : "",
      opp_1_icon: event.opp_1_icon,
      opp_2_icon: event.opp_2_icon,
      oc_group_name: ocData?.oc_group_name,
      oc_name: ocData?.oc_name,
      oc_rate: ocData?.oc_rate,
      oc_block: ocData?.oc_block,
      oc_pointer: ocData?.oc_pointer,
      game_id: event.game_id,
      game_start: event.game_start,
      // amount: 1000,
    });
    localStorage.setItem("createPrediction", JSON.stringify(items));
    setCountData(countData + 1);
    setPredictionList((oldState) => {
      return [...items];
    });
  };

  const handleLeaueActiveExtra = async (event, odds, e) => {
    e?.stopPropagation();
    let isExist = false;
    if (localStorage.getItem("createPrediction")) {
      let listItems = [];
      let itemsLocal = JSON.parse(localStorage.getItem("createPrediction"));
      listItems = itemsLocal.filter((i, index) => {
        if (i.game_id == event.game_id) {
          if (i.oc_pointer == odds) {
            isExist = true;
          } else {
            return i;
          }
        } else {
          return i;
        }
      });
      localStorage.setItem("createPrediction", JSON.stringify(listItems));
    }
    setCountData(countData + 1);
    if (isExist) return;
    const ocData = event.game_oc_list.find((oc) => {
      let data = null;
      for (let i of oc.oc_list) {
        if (i.oc_pointer === odds) {
          data = i;
          break;
        }
      }
      return data;
    });
    let items = localStorage.getItem("createPrediction");
    if (items) {
      let itemsLocal = JSON.parse(items);
      items = itemsLocal.filter((i, index) => {
        if (i.game_id !== event.game_id) {
          return i;
        }
      });
      localStorage.setItem("createPrediction", JSON.stringify(items));
    } else {
      items = [];
    }
    const ac = ocData.oc_list?.find((i) => {
      if (i.oc_pointer === odds) return i;
    });
    items.push({
      sport_id: event.sport_id,
      sport_name: event.sport_name,
      sport_name_en: event.sport_name_en ? event.sport_name_en : "",
      tournament_id: event.tournament_id,
      tournament_name: event.tournament_name,
      tournament_name_en: event.tournament_name_en
        ? event.tournament_name_en
        : "",
      country_name: event.country_name,
      opp_1_name: event.opp_1_name,
      opp_2_name: event.opp_2_name,
      opp_1_name_en: event.opp_1_name_en ? event.opp_1_name_en : "",
      opp_2_name_en: event.opp_2_name_en ? event.opp_2_name_en : "",
      opp_1_icon: event.opp_1_icon,
      opp_2_icon: event.opp_2_icon,
      oc_group_name: ac?.oc_group_name,
      oc_name: ac?.oc_name,
      oc_rate: ac?.oc_rate,
      oc_block: ac?.oc_block,
      oc_pointer: ac?.oc_pointer,
      game_id: event.game_id,
      game_start: event.game_start,
    });
    localStorage.setItem("createPrediction", JSON.stringify(items));
    setCountData(countData + 1);
    setPredictionList((oldState) => {
      return [...items];
    });
  };
  const onScroll = async () => {
    if (tournamentsData == "") {
      if (listInnerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
        if (currPage < tournamentCount / pageSize) {
          if (scrollTop + clientHeight >= scrollHeight) {
            setCurrPage(currPage + 1);
            handleLeagueList(currPage);
          }
        }
        setShowLoader(false);
      }
    }
  };
  const mediumOnScroll = async () => {
    if (tournamentsData == "") {
      // if (MediumSizelistInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        MediumSizelistInnerRef.current;
      // const sumH = Math.ceil(scrollTop + clientHeight);
      if (currPage < tournamentCount / pageSize) {
        if (scrollTop + clientHeight >= scrollHeight) {
          setCurrPage(currPage + 1);
          handleLeagueList(currPage);
        }
      }
      // }
      setShowLoader(false);
    }
  };
  const SmallOnScroll = async () => {
    if (tournamentsData == "") {
      // if (SmallSizelistInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        SmallSizelistInnerRef.current;
      if (currPage < tournamentCount / pageSize) {
        if (scrollTop + clientHeight >= scrollHeight) {
          setCurrPage(currPage + 1);
          handleLeagueList(currPage);
        }
        // }
      }
      setShowLoader(false);
    }
  };
  const handleManageSport = () => {
    if (managePredictionData) {
      setManagePrediction(false);
    } else {
      setManagePrediction(true);
    }
  };
  const incrementCount = () => {
    setCountData(countData + 1);
  };
  // delete sport function ---
  const handleDeleteSportHide = () => {
    setDeleteSportModel(false);
  };
  const handleSportDelete = (item) => {
    setSelectedSportId(item.sportId);
    setSelectedSportName(item.sportName);
    setDeleteSportModel(true);
  };
  const handleSportEdit = (item) => {
    props.editLeaugeModel(item);
    props.hideModel();
  };
  const handleDeleteSportData = async () => {
    if (localStorage.getItem("prediction-list")) {
      let items = JSON.parse(localStorage.getItem("prediction-list"));
      let isACtiveSport = false;
      items = items.filter((i) => {
        if (i.sportId !== selectedSportId) {
          return i;
        } else {
          if (i.activeKey) {
            isACtiveSport = true;
          }
        }
      });
      await apiService.createFavSport({ list: items });
      if (isACtiveSport && items.length > 0) {
        items[0].activeKey = true;
      }
      if (items.length > 0) {
        localStorage.setItem("prediction-list", JSON.stringify(items));
      } else {
        localStorage.setItem("prediction-list", JSON.stringify([]));
      }
      setisDeleteUpdated((oldState) => oldState + 1);
    }
    handleDeleteSportHide();
    setSuccessPopup("success-popup popup-show");
    setTimeout(() => setSuccessPopup("success-popup popup-hide"), 1000);
    if (localStorage.getItem("prediction-list")) {
      if (JSON.parse(localStorage.getItem("prediction-list")).length < 1) {
        props.hideModel();
      }
    }
    props.getSportData();
  };
  const handleExtraMatchesData = async (event) => {
    // let onextwo = [];
    // let DoubleChance = [];
    // let Handicap = [];
    // let BothTeamsToScore = [];
    // let BothTeamsTotal = [];
    // let Team1Total = [];
    // event.game_oc_list.map((data) => {
    //     if (data.oc_group_name === "1x2") {
    //         onextwo.push(data)
    //     }
    //     if (data.oc_group_name === "Double Chance") {
    //         DoubleChance.push(data)
    //     }
    //     if (data.oc_group_name === "Handicap") {
    //         Handicap.push(data)
    //     }
    //     if (data.oc_group_name === "Both Teams To Score") {
    //         BothTeamsToScore.push(data)
    //     }
    //     if (data.oc_group_name === "Total") {
    //         let isExist = BothTeamsTotal.find(i => i.value == data.oc_name.split(" ")[2])
    //         if (!isExist) {
    //             isExist = {
    //                 value: data.oc_name.split(" ")[2]
    //             }
    //             if (data.oc_name.split(" ")[1] == "Over") {
    //                 isExist.over = data.oc_rate
    //                 isExist.overObj = data
    //             }
    //             if (data.oc_name.split(" ")[1] == "Under") {
    //                 isExist.under = data.oc_rate
    //                 isExist.underObj = data
    //             }
    //             BothTeamsTotal.push(isExist)
    //         } else {
    //             if (data.oc_name.split(" ")[1] == "Over") {
    //                 isExist.over = data.oc_rate
    //                 isExist.overObj = data
    //             }
    //             if (data.oc_name.split(" ")[1] == "Under") {
    //                 isExist.under = data.oc_rate
    //                 isExist.underObj = data
    //             }
    //             BothTeamsTotal = BothTeamsTotal.map(i => {
    //                 if (i.value == data.oc_name.split(" ")[2]) {
    //                     i = isExist
    //                 }
    //                 return i
    //             })
    //         }
    //     }

    //     if (data.oc_group_name === "Total 1") {
    //         let isExist = Team1Total.find(i => i.value == data.oc_name.split(" ")[4])
    //         if (!isExist) {
    //             isExist = {
    //                 value: data.oc_name.split(" ")[4]
    //             }
    //             if (data.oc_name.split(" ")[3] == "Over") {
    //                 isExist.over = data.oc_rate
    //                 isExist.overObj = data
    //             }
    //             if (data.oc_name.split(" ")[3] == "Under") {
    //                 isExist.under = data.oc_rate
    //                 isExist.underObj = data
    //             }
    //             Team1Total.push(isExist)
    //         } else {
    //             if (data.oc_name.split(" ")[3] == "Over") {
    //                 isExist.over = data.oc_rate
    //                 isExist.overObj = data
    //             }
    //             if (data.oc_name.split(" ")[3] == "Under") {
    //                 isExist.under = data.oc_rate
    //                 isExist.underObj = data
    //             }
    //             Team1Total = Team1Total.map(i => {
    //                 if (i.value == data.oc_name.split(" ")[2]) {
    //                     i = isExist
    //                 }
    //                 return i
    //             })
    //         }
    //     }
    // })
    setExtraOddsDataLoader(true);
    setExtraOdd(event);
    setIsOpenTable("all-tab-table-hide");
    setIsOddScoreDisplay("all-odds-score-display");
    setTournamentFilter(false);
    // setExtraOddEventData({ onextwo, DoubleChance, Handicap, BothTeamsToScore, BothTeamsTotal, Team1Total })
  };
  const handleExtraEventList = async () => {
    if (extraOdd !== "") {
      const res = await apiService.getEventList({
        gameId: extraOdd.game_id,
        type: "line",
        language: apiService.getProfileFromStorage().Language,
      });
      if (res) {
        setExtraOddsDataLoader(false);
      }
      setEventList(res);
    }
  };
  useEffect(() => {
    handleExtraEventList();
  }, [extraOdd]);
  const handleBackToAddPrediction = () => {
    setIsOpenTable("all-tab-table");
    setIsOddScoreDisplay("all-odds-score-display-hide");
    setTournamentFilter(true);
  };

  const handleActiveOdds = (team, gameId) => {
    let flag = false;
    // console.log("team::", team)
    predicationLIst.map((item) => {
      if (item.oc_pointer == team) {
        flag = true;
      }
    });
    return flag;
  };
  useEffect(() => {
    if (localStorage.getItem("createPrediction")) {
      setPredictionList(JSON.parse(localStorage.getItem("createPrediction")));
    } else {
      setPredictionList([]);
      localStorage.setItem("createPrediction", "[]");
    }
  }, [countData]);
  const handleOddsData = (event) => {
    let arrEl = [];
    event?.game_oc_list.map((item, i) => {
      let pt = item?.oc_pointer.split("|");
      if (pt[1] == 1 && pt[2] == 1) {
        arrEl.push({
          seq: 1,
          data: (
            <td
              className="leaue-score champions-leaue-score-data-1"
              key={i}
              onClick={(e) => handleLeaueActive(event, 1, 1, e)}
              style={{ pointerEvents: !event.teamOneWin && "none" }}
            >
              <div
                className={
                  handleActiveOdds(item.oc_pointer, eventList.game_id)
                    ? "champions-leaue-score champions-leaue-score-active"
                    : "champions-leaue-score"
                }
              >
                {!isNaN(event.teamOneWin)
                  ? parseFloat(event.teamOneWin).toFixed(2)
                  : "-"}
              </div>
            </td>
          ),
        });
      }
      if (pt[1] == 1 && pt[2] == 2) {
        arrEl.push({
          seq: 2,
          data: (
            <td
              className="leaue-score champions-leaue-score-data-X"
              onClick={(e) => handleLeaueActive(event, 2, 1, e)}
              style={{ pointerEvents: !event.teamDrowWin && "none" }}
              key={i}
            >
              <div
                className={
                  handleActiveOdds(item.oc_pointer, eventList.game_id)
                    ? "champions-leaue-score champions-leaue-score-active"
                    : "champions-leaue-score"
                }
              >
                {!isNaN(event.teamDrowWin)
                  ? parseFloat(event.teamDrowWin).toFixed(2)
                  : "-"}
              </div>
            </td>
          ),
        });
      }
      if (pt[1] == 1 && pt[2] == 3) {
        arrEl.push({
          seq: 3,
          data: (
            <td
              className="leaue-score champions-leaue-score-data-2"
              onClick={(e) => handleLeaueActive(event, 3, 1, e)}
              style={{ pointerEvents: !event.teamTwoWin && "none" }}
              key={i}
            >
              <div
                className={
                  handleActiveOdds(item.oc_pointer, eventList.game_id)
                    ? "champions-leaue-score champions-leaue-score-active"
                    : "champions-leaue-score"
                }
              >
                {!isNaN(event.teamTwoWin)
                  ? parseFloat(event.teamTwoWin || "-").toFixed(2)
                  : "-"}
              </div>
            </td>
          ),
        });
      }
      if (pt[1] == 8 && pt[2] == 4) {
        arrEl.push({
          seq: 4,
          data: (
            <td
              className="leaue-score champions-leaue-score-data-1X"
              onClick={(e) => handleLeaueActive(event, 4, 8, e)}
              style={{ pointerEvents: !event.teamOneWinDouble && "none" }}
            >
              {" "}
              <div
                className={
                  handleActiveOdds(item.oc_pointer, eventList.game_id)
                    ? "champions-leaue-score champions-leaue-score-active"
                    : "champions-leaue-score"
                }
              >
                {!isNaN(event.teamOneWinDouble)
                  ? parseFloat(event.teamOneWinDouble).toFixed(2)
                  : "-"}
              </div>
            </td>
          ),
        });
      }
      if (pt[1] == 8 && pt[2] == 5) {
        arrEl.push({
          seq: 5,
          data: (
            <td
              className="leaue-score champions-leaue-score-data-12"
              onClick={(e) => handleLeaueActive(event, 5, 8, e)}
              style={{ pointerEvents: !event.teamDrowDouble && "none" }}
            >
              <div
                className={
                  handleActiveOdds(item.oc_pointer, eventList.game_id)
                    ? "champions-leaue-score champions-leaue-score-active"
                    : "champions-leaue-score"
                }
              >
                {!isNaN(event.teamDrowDouble)
                  ? parseFloat(event.teamDrowDouble).toFixed(2)
                  : "-"}
              </div>
            </td>
          ),
        });
      }
      if (pt[1] == 8 && pt[2] == 6) {
        arrEl.push({
          seq: 6,
          data: (
            <td
              className="leaue-score champions-leaue-score-data-X2"
              onClick={(e) => handleLeaueActive(event, 6, 8, e)}
              style={{ pointerEvents: !event.teamTwoWinDouble && "none" }}
            >
              {" "}
              <div
                className={
                  handleActiveOdds(item.oc_pointer, eventList.game_id)
                    ? "champions-leaue-score champions-leaue-score-active"
                    : "champions-leaue-score"
                }
              >
                {!isNaN(event.teamTwoWinDouble)
                  ? parseFloat(event.teamTwoWinDouble).toFixed(2)
                  : "-"}
              </div>
            </td>
          ),
        });
      }
    });
    if (arrEl.length < 1) {
      for (let i = 1; i <= 6; i++) {
        arrEl.push({
          seq: i,
          data: (
            <td
              key={i}
              className="leaue-score"
              style={{ pointerEvents: "none" }}
            >
              {" "}
              <div className="champions-leaue-score">-</div>
            </td>
          ),
        });
      }
    }
    arrEl = arrEl.sort((a, b) => {
      return a.seq - b.seq;
    });
    return arrEl;
  };
  const handleMediumDeviceOddsData = (event, name) => {
    let arrEl = [];
    event?.game_oc_list.map((item) => {
      let pt = item?.oc_pointer.split("|");
      if (pt[1] == 1 && pt[2] == 1) {
        arrEl.push({
          seq: 1,
          data: (
            <td
              className="leaue-score champions-leaue-score-data-1"
              onClick={(e) => handleLeaueActive(event, 1, 1, e)}
              style={{ pointerEvents: !event.teamOneWin && "none" }}
            >
              <div
                className={
                  handleActiveOdds(item.oc_pointer, eventList.game_id)
                    ? "champions-leaue-score champions-leaue-score-active"
                    : "champions-leaue-score"
                }
              >
                {!isNaN(event.teamOneWin)
                  ? parseFloat(event.teamOneWin).toFixed(2)
                  : "-"}
              </div>
            </td>
          ),
        });
      }

      if (pt[1] == 1 && pt[2] == 2) {
        arrEl.push({
          seq: 2,
          data: (
            <td
              className="leaue-score champions-leaue-score-data-X"
              onClick={(e) => handleLeaueActive(event, 2, 1, e)}
              style={{ pointerEvents: !event.teamDrowWin && "none" }}
            >
              <div
                className={
                  handleActiveOdds(item.oc_pointer, eventList.game_id)
                    ? "champions-leaue-score champions-leaue-score-active"
                    : "champions-leaue-score"
                }
              >
                {!isNaN(event.teamDrowWin)
                  ? parseFloat(event.teamDrowWin).toFixed(2)
                  : "-"}
              </div>
            </td>
          ),
        });
      }
      if (pt[1] == 1 && pt[2] == 3) {
        arrEl.push({
          seq: 3,
          data: (
            <td
              className="leaue-score champions-leaue-score-data-2"
              onClick={(e) => handleLeaueActive(event, 3, 1, e)}
              style={{ pointerEvents: !event.teamTwoWin && "none" }}
            >
              <div
                className={
                  handleActiveOdds(item.oc_pointer, eventList.game_id)
                    ? "champions-leaue-score champions-leaue-score-active"
                    : "champions-leaue-score"
                }
              >
                {!isNaN(event.teamTwoWin)
                  ? parseFloat(event.teamTwoWin || "-").toFixed(2)
                  : "-"}
              </div>
            </td>
          ),
        });
      }
    });
    if (arrEl.length < 1) {
      for (let i = 1; i <= 3; i++) {
        arrEl.push({
          seq: i,
          data: (
            <td className="leaue-score" style={{ pointerEvents: "none" }}>
              {" "}
              <div className="champions-leaue-score">-</div>
            </td>
          ),
        });
      }
    }
    arrEl = arrEl.sort((a, b) => {
      return a.seq - b.seq;
    });
    return arrEl;
  };
  const handleMobileDeviceOddsData = (event, name) => {
    let arrEl = [];
    event?.game_oc_list.map((item) => {
      let pt = item?.oc_pointer.split("|");
      if (pt[1] == 1 && pt[2] == 1) {
        arrEl.push({
          seq: 1,
          data: (
            <div
              className="leaue-score-box champions-leaue-score-data-1"
              onClick={(e) => handleLeaueActive(event, 1, 1, e)}
              style={{ pointerEvents: !event.teamOneWin && "none" }}
            >
              <div
                className={
                  handleActiveOdds(item.oc_pointer, eventList.game_id)
                    ? "champions-leaue-score champions-leaue-score-active"
                    : "champions-leaue-score"
                }
              >
                <div>1</div>
                <div>
                  {!isNaN(event.teamOneWin)
                    ? parseFloat(event.teamOneWin).toFixed(2)
                    : "-"}
                </div>
              </div>
            </div>
          ),
        });
      }
      if (pt[1] == 1 && pt[2] == 2) {
        arrEl.push({
          seq: 2,
          data: (
            <div
              className="leaue-score-box champions-leaue-score-data-X"
              onClick={(e) => handleLeaueActive(event, 2, 1, e)}
              style={{ pointerEvents: !event.teamDrowWin && "none" }}
            >
              <div
                className={
                  handleActiveOdds(item.oc_pointer, eventList.game_id)
                    ? "champions-leaue-score champions-leaue-score-active"
                    : "champions-leaue-score"
                }
              >
                <div>X</div>
                <div>
                  {!isNaN(event.teamDrowWin)
                    ? parseFloat(event.teamDrowWin).toFixed(2)
                    : "-"}
                </div>
              </div>
            </div>
          ),
        });
      }
      if (pt[1] == 1 && pt[2] == 3) {
        arrEl.push({
          seq: 3,
          data: (
            <div
              className="leaue-score-box  champions-leaue-score-data-2"
              onClick={(e) => handleLeaueActive(event, 3, 1, e)}
              style={{ pointerEvents: !event.teamTwoWin && "none" }}
            >
              <div
                className={
                  handleActiveOdds(item.oc_pointer, eventList.game_id)
                    ? "champions-leaue-score champions-leaue-score-active"
                    : "champions-leaue-score"
                }
              >
                <div>2</div>
                <div>
                  {!isNaN(event.teamTwoWin)
                    ? parseFloat(event.teamTwoWin || "-").toFixed(2)
                    : "-"}
                </div>
              </div>
            </div>
          ),
        });
      }
      // console.log("arrEl ::::", arrEl)
    });
    if (arrEl.length < 1) {
      for (let i = 1; i <= 3; i++) {
        arrEl.push({
          seq: i,
          data: (
            <div
              className="leaue-score-box champions-leaue-score-data-2"
              style={{ pointerEvents: "none" }}
            >
              <div className="champions-leaue-score">
                <div>-</div>
                <div>-</div>
              </div>
            </div>
          ),
        });
      }
    }
    arrEl = arrEl.sort((a, b) => {
      return a.seq - b.seq;
    });
    return arrEl;
  };
  const handleExtraOddsData = (item, name, index) => {
    // console.log("name ::::", name)
    if (item.group_id == 1) {
      return (
        <>
          <div className="full-time-result">
            <div className="full-time-result-title">
              {apiService.getProfileFromStorage().Language == "en"
                ? item.group_name === "1x2" && "Full-time result"
                : "Повний результат"}
            </div>
            <div className="d-flex full-time-result-data-box">
              {item.oc_list?.map((event, index) => (
                <div
                  className={
                    handleActiveOdds(event.oc_pointer, eventList.game_id)
                      ? `col-sm full-time-team-1 full-time-team-1-active`
                      : `col-sm full-time-team-1`
                  }
                  style={{
                    pointerEvents: !event.oc_name && !event.oc_rate && "none",
                  }}
                  onClick={() =>
                    handleLeaueActiveExtra(eventList, event.oc_pointer)
                  }
                  key={index}
                >
                  <div>{event.oc_name || "-"}</div>
                  <div>
                    {!isNaN(event?.oc_rate)
                      ? parseFloat(event?.oc_rate).toFixed(2)
                      : "-"}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      );
    } else if (item.group_id == 8) {
      return (
        <>
          <div className="Double-chance">
            <div className="Double-chance-title">{item.group_name}</div>
            <div className="d-flex Double-chance-data-box">
              {item.oc_list?.map((event, index) => (
                <div
                  className={
                    handleActiveOdds(event.oc_pointer, eventList.game_id)
                      ? `col-sm full-time-team-1 full-time-team-1-active`
                      : `col-sm full-time-team-1`
                  }
                  style={{
                    pointerEvents: !event.oc_name && !event.oc_rate && "none",
                  }}
                  onClick={() =>
                    handleLeaueActiveExtra(eventList, event.oc_pointer)
                  }
                  key={index}
                >
                  <div>{event.oc_name || "-"}</div>
                  <div>
                    {!isNaN(event?.oc_rate)
                      ? parseFloat(event?.oc_rate).toFixed(2)
                      : "-"}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      );
    } else if (item.group_id == 2) {
      const team1 = [];
      const team2 = [];
      item.oc_list?.map((event) => {
        const pointer = event.oc_pointer.split("|");
        if (pointer[2] == 7) {
          team1.push(event);
        } else {
          team2.push(event);
        }
      });
      return (
        <>
          <div className="Handicap-contains" key={index}>
            <div className="Handicap-title">{item.group_name}</div>
            <div className="d-flex mobile-Real-Madrid-title">
              <div
                className="col-sm Real-Madrid-title Real-Madrid-title-desktop "
                style={{ marginRight: "16px" }}
              >
                <div>
                  {apiService.getProfileFromStorage().Language == "en"
                    ? name.opp_1_name_en
                      ? name.opp_1_name_en
                      : name.opp_1_name
                    : name.opp_1_name}
                </div>
              </div>
              <div className="col-sm Chelsea-title  Chelsea-title-desktop">
                <div>
                  {apiService.getProfileFromStorage().Language == "en"
                    ? name.opp_2_name_en
                      ? name.opp_2_name_en
                      : name.opp_2_name
                    : name.opp_2_name}
                </div>
              </div>
            </div>
            <div className="d-flex pb-3 Handicap-data-box">
              <div className="col">
                {team1.map((event, index) => (
                  <div
                    className={
                      handleActiveOdds(event.oc_pointer, eventList.game_id)
                        ? "col-sm Handicap-team1-score Handicap-team1-score-active"
                        : "col-sm Handicap-team1-score"
                    }
                    onClick={() =>
                      handleLeaueActiveExtra(eventList, event.oc_pointer)
                    }
                    key={index}
                  >
                    <div>{event.oc_name.split("Handicap") || "-"}</div>
                    <div>
                      {!isNaN(event?.oc_rate)
                        ? parseFloat(event?.oc_rate).toFixed(2)
                        : "-"}
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-sm Chelsea-title  Chelsea-title-mobile">
                <div>
                  {apiService.getProfileFromStorage().Language == "en"
                    ? name.opp_2_name_en
                      ? name.opp_2_name_en
                      : name.opp_2_name
                    : name.opp_2_name}
                </div>
              </div>
              <div className="col">
                {team2.map((event, index) => (
                  <div
                    className={
                      handleActiveOdds(event.oc_pointer, eventList.game_id)
                        ? "col-sm Handicap-team1-score Handicap-team1-score-active"
                        : "col-sm Handicap-team1-score"
                    }
                    onClick={() =>
                      handleLeaueActiveExtra(eventList, event.oc_pointer)
                    }
                    key={index}
                  >
                    <div>{event.oc_name.split("Handicap") || "-"}</div>
                    <div>
                      {!isNaN(event?.oc_rate)
                        ? parseFloat(event?.oc_rate).toFixed(2)
                        : "-"}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    } else if (item.group_id == 17) {
      const xb = {};
      // const pointer = event.oc_pointer.split("|");
      // if (pointer[2] == 7) {
      //   team1.push(event);
      // } else {
      //   team2.push(event);
      // }
      for (let i of item.oc_list) {
        const pointer = i.oc_pointer.split("|");
        let name = i.oc_pointer.split("|")[3];
        if (!xb[name]) {
          xb[name] = {};
        }
        if (pointer[2] == 9) {
          xb[name]["Over"] = i;
        }
        if (pointer[2] == 10) {
          xb[name]["Under"] = i;
        }
        //   if (i.oc_name?.split(" ")[1] == "Over") {
        //     xb[name]["Over"] = i;
        //   }
        // console.log("0000000", xb[name]["Under"])
        // if (i.oc_name?.split(" ")[1] == "Under") {
        //   xb[name]["Under"] = i;
        // }
      }
      let dataArray = [];
      let overArray = [];
      let underArray = [];
      for (const data in xb) {
        dataArray.push(data);
        overArray.push(xb[data].Over);
        underArray.push(xb[data].Under);
      }
      return (
        <>
          <div className="total-odds-contains" key={index}>
            <div className="total-odds-title">{item.group_name}</div>
            <div className="desktop-Total-div-box">
              <div className="d-flex">
                <div className="col-sm RealMadridTotal-teams p-3"></div>
                <div
                  className="col-sm Over-title"
                  style={{ marginRight: "16px" }}
                >
                  <div>{t("profile.Over")}</div>
                </div>
                <div className="col-sm Under-title">
                  <div>{t("profile.Under")}</div>
                </div>
              </div>
              <div className="d-flex ">
                <div className="col">
                  {dataArray.map((event, index) => (
                    <div
                      className="col-sm mb-3 RealMadridTotal-teams-score"
                      key={index}
                      style={{ marginRight: "16px" }}
                    >
                      <div>{event}</div>
                    </div>
                  ))}
                </div>
                <div className="col">
                  {overArray.map((event, index) => (
                    <div
                      className={
                        handleActiveOdds(event?.oc_pointer, eventList.game_id)
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : "col-sm mb-3 Over-score"
                      }
                      style={{ marginRight: "16px" }}
                      onClick={() =>
                        handleLeaueActiveExtra(eventList, event.oc_pointer)
                      }
                      key={index}
                    >
                      <div>
                        {!isNaN(event?.oc_rate)
                          ? parseFloat(event?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col">
                  {underArray.map((event, index) => (
                    <div
                      className={
                        handleActiveOdds(event?.oc_pointer, eventList.game_id)
                          ? "col-sm Under-score mb-3 Under-score-active"
                          : "col-sm mb-3 Under-score"
                      }
                      style={{ marginRight: "16px" }}
                      onClick={() =>
                        handleLeaueActiveExtra(eventList, event.oc_pointer)
                      }
                      key={index}
                    >
                      <div>
                        {!isNaN(event?.oc_rate)
                          ? parseFloat(event?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* mobile- design  */}
            <div className="mobile-Total-div-box">
              <div className="col-sm Over-title">
                <div className="pb-3">{t("profile.Over")}</div>
                {dataArray.map((event, index) => (
                  <div key={index}>
                    <div className="col-sm mb-3 RealMadridTotal-teams-score">
                      <div>{event}</div>
                    </div>
                    <div
                      className={
                        handleActiveOdds(
                          overArray[index]?.oc_pointer,
                          eventList.game_id
                        )
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : "col-sm mb-3 Over-score"
                      }
                      onClick={() =>
                        handleLeaueActiveExtra(
                          eventList,
                          overArray[index]?.oc_pointer
                        )
                      }
                      key={index}
                    >
                      <div>
                        {!isNaN(overArray[index]?.oc_rate)
                          ? parseFloat(overArray[index]?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-sm Under-title">
                <div className="pb-3">{t("profile.Under")}</div>
                {dataArray.map((event, index) => (
                  <div key={index}>
                    <div className="col-sm mb-3 RealMadridTotal-teams-score">
                      <div>{event}</div>
                    </div>
                    <div
                      className={
                        handleActiveOdds(
                          underArray[index]?.oc_pointer,
                          eventList.game_id
                        )
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : "col-sm mb-3 Over-score"
                      }
                      onClick={() =>
                        handleLeaueActiveExtra(
                          eventList,
                          underArray[index]?.oc_pointer
                        )
                      }
                      key={index}
                    >
                      <div>
                        {!isNaN(underArray[index]?.oc_rate)
                          ? parseFloat(underArray[index]?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    } else if (item.group_id == 15) {
      const xb = {};
      for (let i of item.oc_list) {
        let name = i.oc_pointer?.split("|")[3];
        let ox = i.oc_pointer?.split("|")[2];
        if (!xb[name]) {
          xb[name] = {};
        }
        if (ox == 11) {
          xb[name]["Over"] = i;
        }
        if (ox == 12) {
          xb[name]["Under"] = i;
        }
      }
      let dataArray = [];
      let overArray = [];
      let underArray = [];
      for (const data in xb) {
        dataArray.push(data);
        overArray.push(xb[data].Over);
        underArray.push(xb[data].Under);
      }
      return (
        <>
          <div className="total-odds-contains">
            <div className="total-odds-title ">{name.opp_1_name}</div>
            <div className="desktop-Total-div-box">
              <div className="d-flex ">
                <div className="col-sm RealMadridTotal-teams p-3"></div>
                <div
                  className="col-sm Over-title"
                  style={{ marginRight: "16px" }}
                >
                  <div>{t("profile.Over")}</div>
                </div>
                <div className="col-sm Under-title">
                  <div>{t("profile.Under")}</div>
                </div>
              </div>
              <div className="d-flex">
                <div className="col">
                  {dataArray.map((event, index) => (
                    <div
                      className="col-sm mb-3 RealMadridTotal-teams-score"
                      key={index}
                      style={{ marginRight: "16px" }}
                    >
                      <div>{event}</div>
                    </div>
                  ))}
                </div>
                <div className="col">
                  {overArray.map((event, index) => (
                    <div
                      className={
                        handleActiveOdds(event?.oc_pointer, eventList.game_id)
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : "col-sm mb-3 Over-score"
                      }
                      style={{ marginRight: "16px" }}
                      onClick={() =>
                        handleLeaueActiveExtra(eventList, event.oc_pointer)
                      }
                      key={index}
                    >
                      <div>
                        {!isNaN(event?.oc_rate)
                          ? parseFloat(event?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col">
                  {underArray.map((event, index) => (
                    <div
                      className={
                        handleActiveOdds(event?.oc_pointer, eventList.game_id)
                          ? "col-sm Under-score mb-3 Under-score-active"
                          : "col-sm mb-3 Under-score"
                      }
                      style={{ marginRight: "16px" }}
                      onClick={() =>
                        handleLeaueActiveExtra(eventList, event.oc_pointer)
                      }
                      key={index}
                    >
                      <div>
                        {!isNaN(event?.oc_rate)
                          ? parseFloat(event?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* mobile- design  */}
            <div className="mobile-Total-div-box">
              <div className="col-sm Over-title">
                <div className="pb-3">{t("profile.Over")}</div>
                {dataArray.map((event, index) => (
                  <div>
                    <div className="col-sm mb-3 RealMadridTotal-teams-score">
                      <div>{event}</div>
                    </div>
                    <div
                      className={
                        handleActiveOdds(
                          overArray[index]?.oc_pointer,
                          eventList.game_id
                        )
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : "col-sm mb-3 Over-score"
                      }
                      onClick={() =>
                        handleLeaueActiveExtra(
                          eventList,
                          overArray[index]?.oc_pointer
                        )
                      }
                      key={index}
                    >
                      <div>
                        {!isNaN(overArray[index]?.oc_rate)
                          ? parseFloat(overArray[index]?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-sm Under-title">
                <div className="pb-3">{t("profile.Under")}</div>
                {dataArray.map((event, index) => (
                  <div key={index}>
                    <div className="col-sm mb-3 RealMadridTotal-teams-score">
                      <div>{event}</div>
                    </div>
                    <div
                      className={
                        handleActiveOdds(
                          underArray[index]?.oc_pointer,
                          eventList.game_id
                        )
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : "col-sm mb-3 Over-score"
                      }
                      onClick={() =>
                        handleLeaueActiveExtra(
                          eventList,
                          underArray[index]?.oc_pointer
                        )
                      }
                      key={index}
                    >
                      <div>
                        {!isNaN(underArray[index]?.oc_rate)
                          ? parseFloat(underArray[index]?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    } else if (item.group_id == 62) {
      const xb = {};
      for (let i of item.oc_list) {
        let name = i.oc_pointer?.split("|")[3];
        let ox = i.oc_pointer?.split("|")[2];
        if (!xb[name]) {
          xb[name] = {};
        }
        if (ox == 13) {
          xb[name]["Over"] = i;
        }
        if (ox == 14) {
          xb[name]["Under"] = i;
        }
      }
      let dataArray = [];
      let overArray = [];
      let underArray = [];
      for (const data in xb) {
        dataArray.push(data);
        overArray.push(xb[data].Over);
        underArray.push(xb[data].Under);
      }
      return (
        <>
          <div className="total-odds-contains">
            <div className="total-odds-title">{name.opp_2_name}</div>
            <div className="desktop-Total-div-box">
              <div className="d-flex">
                <div className="col-sm RealMadridTotal-teams p-3"></div>
                <div
                  className="col-sm Over-title"
                  style={{ marginRight: "16px" }}
                >
                  <div>{t("profile.Over")}</div>
                </div>
                <div className="col-sm Under-title">
                  <div>{t("profile.Under")}</div>
                </div>
              </div>
              <div className="d-flex">
                <div className="col">
                  {dataArray.map((event, index) => (
                    <div
                      className="col-sm mb-3 RealMadridTotal-teams-score"
                      key={index}
                      style={{ marginRight: "16px" }}
                    >
                      <div>{event}</div>
                    </div>
                  ))}
                </div>
                <div className="col">
                  {overArray.map((event, index) => (
                    <div
                      className={
                        handleActiveOdds(event?.oc_pointer, eventList.game_id)
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : "col-sm mb-3 Over-score"
                      }
                      style={{ marginRight: "16px" }}
                      onClick={() =>
                        handleLeaueActiveExtra(eventList, event.oc_pointer)
                      }
                      key={index}
                    >
                      <div>
                        {!isNaN(event?.oc_rate)
                          ? parseFloat(event?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col">
                  {underArray.map((event, index) => (
                    <div
                      className={
                        handleActiveOdds(event?.oc_pointer, eventList.game_id)
                          ? "col-sm Under-score mb-3 Under-score-active"
                          : "col-sm mb-3 Under-score"
                      }
                      style={{
                        marginRight: "16px",
                        pointerEvents: !isNaN(event?.oc_rate) && "none",
                      }}
                      onClick={() =>
                        handleLeaueActiveExtra(eventList, event.oc_pointer)
                      }
                      key={index}
                    >
                      <div>
                        {!isNaN(event?.oc_rate)
                          ? parseFloat(event?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* mobile- design  */}
            <div className="mobile-Total-div-box">
              <div className="col-sm Over-title">
                <div className="pb-3">{t("profile.Over")}</div>
                {dataArray.map((event, index) => (
                  <div key={index}>
                    <div className="col-sm mb-3 RealMadridTotal-teams-score">
                      <div>{event}</div>
                    </div>
                    <div
                      className={
                        handleActiveOdds(
                          overArray[index]?.oc_pointer,
                          eventList.game_id
                        )
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : "col-sm mb-3 Over-score"
                      }
                      onClick={() =>
                        handleLeaueActiveExtra(
                          eventList,
                          overArray[index]?.oc_pointer
                        )
                      }
                      key={index}
                    >
                      <div>
                        {!isNaN(overArray[index]?.oc_rate)
                          ? parseFloat(overArray[index]?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-sm Under-title">
                <div className="pb-3">{t("profile.Under")}</div>
                {dataArray.map((event, index) => (
                  <div key={index}>
                    <div
                      className="col-sm mb-3 RealMadridTotal-teams-score"
                      key={index}
                    >
                      <div>{event}</div>
                    </div>
                    <div
                      className={
                        handleActiveOdds(
                          underArray[index]?.oc_pointer,
                          eventList.game_id
                        )
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : "col-sm mb-3 Over-score"
                      }
                      onClick={() =>
                        handleLeaueActiveExtra(
                          eventList,
                          underArray[index]?.oc_pointer
                        )
                      }
                      key={index}
                    >
                      <div>
                        {!isNaN(underArray[index]?.oc_rate)
                          ? parseFloat(underArray[index]?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="full-time-result-title">{item.group_name}</div>
          <div className="row extra-odds-data-list">
            {item.oc_list?.map((event, index) => (
              // <div className="">
              <div
                className={
                  handleActiveOdds(event?.oc_pointer, eventList.game_id)
                    ? `col-lg-${12 / item.columns} col-sm-12 ${
                        item.columns >= 2 ? "col-md-4" : "col-md-2"
                      } ${
                        item.columns == 2 ? "col-lg-6" : "col-4"
                      } col-4 extra-full-time-team-1 extra-full-time-team-1-active`
                    : `col-lg-${12 / item.columns} col-sm-12  ${
                        item.columns >= 2 ? "col-md-4" : "col-md-2"
                      } ${
                        item.columns == 2 ? "col-lg-6" : "col-4"
                      } col-4 extra-full-time-team-1`
                }
                style={{
                  pointerEvents: !event.oc_name && !event.oc_rate && "none",
                }}
                onClick={() =>
                  handleLeaueActiveExtra(eventList, event.oc_pointer)
                }
                key={index}
              >
                <div>{event.oc_name || "-"}</div>
                <div>
                  {!isNaN(event?.oc_rate)
                    ? parseFloat(event?.oc_rate).toFixed(2)
                    : "-"}
                </div>
              </div>
              // </div>
            ))}
          </div>
        </>
      );
    }
  };
  // large screen
  const g = (event) => {
    if (event.game_oc_list.length > 5) {
      if (event.game_oc_counter >= 1000 && event.game_oc_counter <= 1999) {
        return "+1K";
      } else if (
        event.game_oc_counter >= 2000 &&
        event.game_oc_counter <= 2999
      ) {
        return "+2K";
      } else {
        const x = event.game_oc_counter - 5;
        return "+" + x;
      }
    } else {
      return "-";
    }
  };
  // mediumScreen
  const handleMediumScreenMoreOdds = (event) => {
    if (event.game_oc_list.length > 5) {
      if (event.game_oc_counter >= 1000 && event.game_oc_counter <= 1999) {
        return "+1K";
      } else if (
        event.game_oc_counter >= 2000 &&
        event.game_oc_counter <= 2999
      ) {
        return "+2K";
      } else {
        const x = event.game_oc_counter - 3;
        return "+" + x;
      }
    } else {
      return "-";
    }
  };
  const handleAddPredictionBetSlip = () => {
    if (showBetsSlip === "responsive-addPrediction-right-panel") {
      setShowBetsSlip("responsive-addPrediction-right-panel-hide");
    } else {
      setShowBetsSlip("responsive-addPrediction-right-panel");
    }
  };
  const handleHideBetSlip = () => {
    setShowBetsSlip("responsive-addPrediction-right-panel-hide");
  };
  const handleAllTournamentDataPopup = () => {
    if (localStorage.getItem("prediction-list")) {
      const items = JSON.parse(localStorage.getItem("prediction-list"));
      items.map((data) => {
        if (data.activeKey == true) {
          props.editLeaugeModel(data);
          setActiveSportId(data.sportId);
        }
      });
    }
    props.hideModel();
  };
  const getActiveSportId = () => {
    if (localStorage.getItem("prediction-list")) {
      const items = JSON.parse(localStorage.getItem("prediction-list"));
      items.map((data) => {
        if (data.activeKey == true) {
          setActiveSportId(data.sportId);
        }
      });
    }
  };
  const increamentUserCount = () => {
    setCountData(countData + 1);
  };
  // all tournaments
  const handleAllTournamentData = async () => {
    getActiveSportId();
    if (AlltournamentsShow) {
      setAllTournamentsShow(false);
    } else {
      setAllTournamentsShow(true);
      if (activeSportId !== "") {
        const res = await apiService.getFavTournamentName(
          activeSportId,
          apiService.getProfileFromStorage().Language
        );
        setTournamentName(res);
        if (res) {
          setShowTurnamentLoader(false);
        }
      }
      setAllUpcomingShow(false);
    }
  };
  const handleSelectedTournament = (data) => {
    setTournamentsData(data);
    // setFilterData((s) => {
    //   return {
    //     ...s,
    //     tournament_name: data.tournament_name,
    //   };
    // });
    if (data) {
      setTournamentsFilterBtn(true);
      setAllTournamentsShow(false);
    }

    increamentUserCount();
  };
  const handleTournamentCloseHideShow = () => {
    // setFilterData((s) => {
    //   if (s.tournament_name) {
    //     delete s.tournament_name;
    //   }
    //   return s;
    // });
    setTournamentsData("");
    handleAllLeagueList();
    setTournamentsFilterBtn(false);
    increamentUserCount();
  };
  // all upcoming
  const handleAllUpcomingData = () => {
    if (AllupcomingsShow) {
      setAllUpcomingShow(false);
    } else {
      setAllUpcomingShow(true);
      setAllTournamentsShow(false);
    }
  };
  const handleSelectedUpcoming = (name, data) => {
    let starTime = moment().startOf("day").unix();
    let endTime = moment().endOf("day").unix();
    let TomorrowStartTime = starTime + 86400;
    let TomorrowEndTime = endTime + 86400;
    setUpcomingData(data);
    let isAllEmpty = true;
    const upcomingDataFilter = tournamentData?.map((data) => {
      data.events_list = data.events_list.filter((event) => {
        if (name == "Today") {
          if (event?.game_start > starTime && event?.game_start < endTime) {
            return event;
          }
        }
        if (name == "Tomorrow") {
          if (
            event?.game_start > TomorrowStartTime &&
            event?.game_start < TomorrowEndTime
          ) {
            return event;
          }
        }
      });
      if (isAllEmpty) {
        isAllEmpty = data.events_list.length == 0;
      }
      return data;
    });
    upcomingDataFilter.isAllEmpty = isAllEmpty;
    setTournamentData(upcomingDataFilter);
    increamentUserCount();
    if (data) {
      setUpcomingFilterBtn(true);
      setAllUpcomingShow(false);
    }
  };
  const handleUpcomingCloseHideShow = () => {
    // setFilterData((s) => {
    //   if (s.upcoming) {
    //     delete s.upcoming;
    //   }
    //   return s;
    // });
    handleLeagueList(1);
    increamentUserCount();
    setUpcomingFilterBtn(false);
  };
  useEffect(() => {
    getActiveSportId();
  }, [countData]);

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);

  function listenForOutsideClicks() {
    return () => {
      if (listening) return;
      if (!menuRef.current) return;
      setListening(true);
      ["mousedown"].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          if (menuRef?.current?.contains(evt.target)) {
            // handleLanguageDropDown()
          } else {
            setAllTournamentsShow(false);
          }
        });
      });
    };
  }
  const menuRefCurrency = useRef(null);
  const [listeningCurrency, setListeningCurrency] = useState(false);
  function listenForOutsideClicksCurrency() {
    return () => {
      if (listeningCurrency) return;
      if (!menuRefCurrency.current) return;
      setListeningCurrency(true);
      ["mousedown"].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          if (menuRefCurrency?.current?.contains(evt.target)) {
            // handleLanguageDropDown()
          } else {
            setAllUpcomingShow(false);
          }
        });
      });
    };
  }
  useEffect(listenForOutsideClicks(), [AlltournamentsShow]);
  useEffect(listenForOutsideClicksCurrency(), [AllupcomingsShow]);
  return (
    <>
      <Model
        isModel={props.isModel}
        modelClassName={"add-some-predication-model"}
        modelTitle={t("profile.titleAddPrediction")}
        hideModel={props.hideModel}
      >
        <div className="addSome-prediction-container">
          {/* addPrediction left-panel */}
          <div className="addPrediction-left-panel">
            <div className="buttons-container">
              <div className="top-panel d-flex justify-content-between">
                <div className="addPrediction-buttons">
                  <div className="sportButtons">
                    {isSportData?.map((item, index) => (
                      <div className="sport-btn-loop" key={index}>
                        {managePredictionData ? (
                          ""
                        ) : (
                          <InputButton
                            type={"icon-button"}
                            inputLabel={
                              apiService.getProfileFromStorage()?.Language ==
                              "ua"
                                ? item.sportName_ua
                                  ? item.sportName_ua
                                  : item.sportName
                                : item.sportName
                            }
                            addBtnClass={
                              "add-sport-gray-btn" +
                              (item.activeKey ? " add-sport-btn" : "")
                            }
                            addSpan={
                              <span>
                                <img
                                  src={
                                    item.activeKey
                                      ? "/green-sport-logo/" +
                                        item.sportId +
                                        ".svg"
                                      : "sport-logo/" + item.sportId + ".svg"
                                  }
                                ></img>
                              </span>
                            }
                            onClick={() =>
                              handleActiveSport(item.sportId, item.leagues)
                            }
                          />
                        )}
                        {managePredictionData ? (
                          <InputButton
                            type={"edit-delete-btn"}
                            addBtnClass={"edit-delete-icon-button"}
                            inputLabel={
                              apiService.getProfileFromStorage()?.Language ==
                              "ua"
                                ? item.sportName_ua
                                  ? item.sportName_ua
                                  : item.sportName
                                : item.sportName
                            }
                            addSpan={
                              <img
                                src={"sport-logo/" + item.sportId + ".svg"}
                              ></img>
                            }
                            addEditBtn={
                              <div className="tooltip-edit-div">
                                <div className="edit-button-tooltip">Edit</div>
                                <img
                                  className="edit-icon"
                                  src="edit.svg"
                                  onClick={() => handleSportEdit(item)}
                                ></img>
                              </div>
                            }
                            addRemoveBtn={
                              <div className="tooltip-edit-div">
                                <div className="edit-button-tooltip">
                                  Delete
                                </div>
                                <img
                                  className="remove-icon"
                                  src="remove.svg"
                                  onClick={() => handleSportDelete(item)}
                                ></img>
                              </div>
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="add-prediction-operation-button">
                    <InputButton
                      type={"icon-button"}
                      inputLabel={t("profile.AddSport")}
                      addSpan={
                        <span>
                          <img src="/Plus.svg"></img>
                        </span>
                      }
                      addBtnClass={"add-sport-plus-btn"}
                      onClick={props.addSportShowModel}
                    />
                    <InputButton
                      type={"icon-button"}
                      inputLabel={
                        managePredictionData
                          ? t("profile.ExitManaging")
                          : t("profile.ManageSports")
                      }
                      addSpan={
                        <span>
                          <img
                            src={
                              managePredictionData
                                ? "/extingManaging.svg"
                                : "/Filter.svg"
                            }
                          ></img>
                        </span>
                      }
                      addBtnClass={"manage-sport-btn"}
                      onClick={handleManageSport}
                    />
                  </div>
                  <div className="add-prediction-operation-buttonFor-mobile">
                    <div onClick={props.addSportShowModel}>
                      <img src="/Plus.svg"></img>
                    </div>
                    <div onClick={handleManageSport}>
                      <img
                        src={
                          managePredictionData
                            ? "/extingManaging.svg"
                            : "/Filter.svg"
                        }
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="deleteSportModel">
                  <Model
                    isModel={deleteSportModel}
                    modelClassName={"delete-sport-model"}
                    hideModel={handleDeleteSportHide}
                    modelTitle={t("profile.DeleteSport")}
                  >
                    <div className="delete-sport-Msg">
                      {t("profile.deleteSportMsg")} "{selectedSportName}" ?
                    </div>
                    <div className="delete-sportModel-Buttons">
                      <InputButton
                        type={"secondary"}
                        inputLabel={t("error.avatarDeleteDiscard")}
                        addBtnClass={"deleteDiscardBtn"}
                        onClick={handleDeleteSportHide}
                      />
                      <InputButton
                        type={"primary"}
                        inputLabel={t("error.avatarDeleteConfirm")}
                        addBtnClass={"deleteConfirmBtn"}
                        onClick={handleDeleteSportData}
                      />
                    </div>
                  </Model>
                </div>
                <Notification
                  message={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("profile.deleteSportNotificationMsg", {
                          selectedSportName: selectedSportName,
                        }),
                      }}
                    />
                  }
                  hideShow={successPopupShow + " " + "updateName-pop-up"}
                />
              </div>
            </div>
            {/* <hr /> */}
            <div className="addPrediction-bottom-container">
              <div className="">
                <div className="addPrediction-tab">
                  <div className="tournament-filter-notificatrionPart">
                    {tournamentFilter ? (
                      <div className="groupOftournamentUpcoming">
                        {/* All tournaments */}
                        {tournamentsFilterBtn ? (
                          <div>
                            <InputButton
                              type={"filter-button"}
                              inputLabel={tournamentsData.tournament_name}
                              addSpan={
                                <span>
                                  <img
                                    src={
                                      "country-logo/" +
                                      tournamentsData.country_id +
                                      ".png"
                                    }
                                  />
                                </span>
                              }
                              addBtnClass={"filter-data-icon-button"}
                              filterCloseIcon={
                                <img
                                  src="/filter-close.svg"
                                  width="25px"
                                  height="25px"
                                  className="filter-close-icon"
                                  onClick={handleTournamentCloseHideShow}
                                />
                              }
                            />
                          </div>
                        ) : (
                          <div className="position-relative">
                            <div
                              className="All-tournament-contains"
                              ref={menuRef}
                              onClick={handleAllTournamentData}
                            >
                              <div className="tournament-data">
                                {t("feed.Alltournaments")}
                              </div>
                              {AlltournamentsShow ? (
                                <img
                                  src="/black-up.svg"
                                  width="20px"
                                  height="20px"
                                />
                              ) : (
                                <img
                                  src="/black-down.svg"
                                  width="20px"
                                  height="20px"
                                />
                              )}
                            </div>
                            {/* tornament drop-down */}
                            <div
                              className="sport-DropDown"
                              style={{
                                display: AlltournamentsShow ? "block" : "none",
                              }}
                            >
                              <SearchBar
                                showSuggetions={AlltournamentsShow}
                                addMainContainer={"filter-sport-container"}
                                inputPlaceHolder={t("profile.search")}
                                suggetionData={"Data"}
                                errorMsg={t("feed.NoResultsFound")}
                                isSearchBar={true}
                              >
                                <div className="allTournament-scrollbar">
                                  {showTurnamentLoader ? (
                                    <SportLeagueModelLoader />
                                  ) : (
                                    tournamentName?.map((item, index) => (
                                      <div
                                        key={index}
                                        className="filtered-items-containter"
                                        onClick={() =>
                                          handleSelectedTournament(item)
                                        }
                                      >
                                        <div className="d-flex">
                                          <div className="item-icon">
                                            <img
                                              src={
                                                "country-logo/" +
                                                item.country_id +
                                                ".png"
                                              }
                                            />
                                          </div>
                                          <div className="filtered-items-name">
                                            {item.tournament_name}
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  )}
                                </div>
                                <div
                                  className="Add-tournament-button"
                                  onClick={handleAllTournamentDataPopup}
                                >
                                  <img src="/allTournamentDropdownIcon.svg" />
                                  <div className="title">
                                    {t("feed.Addtournament")}
                                  </div>
                                </div>
                              </SearchBar>
                            </div>
                          </div>
                        )}
                        {/* All upcoming */}
                        {upcomingFilterBtn ? (
                          <div>
                            <InputButton
                              type={"filter-button"}
                              inputLabel={upcomingData}
                              addBtnClass={"filter-data-icon-button"}
                              filterCloseIcon={
                                <img
                                  src="/filter-close.svg"
                                  width="25px"
                                  height="25px"
                                  className="filter-close-icon"
                                  onClick={handleUpcomingCloseHideShow}
                                />
                              }
                            />
                          </div>
                        ) : (
                          <div className="position-relative">
                            <div
                              className="All-upcoming-contains"
                              ref={menuRefCurrency}
                              onClick={handleAllUpcomingData}
                            >
                              <div className="upcoming-data">
                                {t("feed.Allupcoming")}
                              </div>
                              {AllupcomingsShow ? (
                                <img
                                  src="/black-up.svg"
                                  width="20px"
                                  height="20px"
                                />
                              ) : (
                                <img
                                  src="/black-down.svg"
                                  width="20px"
                                  height="20px"
                                />
                              )}
                            </div>
                            {/* upcoming dropdown */}
                            <div
                              className="sport-DropDown"
                              style={{
                                display: AllupcomingsShow ? "block" : "none",
                              }}
                            >
                              <SearchBar
                                showSuggetions={AllupcomingsShow}
                                addMainContainer={"filter-sport-container"}
                                inputPlaceHolder={t("profile.search")}
                                suggetionData={"Data"}
                                errorMsg={t("feed.NoResultsFound")}
                                isSearchBar={true}
                              >
                                <div className="allTournament-scrollbar">
                                  <div
                                    className="filtered-items-containter"
                                    onClick={() =>
                                      handleSelectedUpcoming(
                                        "Today",
                                        t("feed.Today")
                                      )
                                    }
                                  >
                                    <div className="d-flex">
                                      <div className="filtered-items-name">
                                        {t("feed.Today")}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="filtered-items-containter"
                                    onClick={() =>
                                      handleSelectedUpcoming(
                                        "Tomorrow",
                                        t("feed.Tomorrow")
                                      )
                                    }
                                  >
                                    <div className="d-flex">
                                      <div className="filtered-items-name">
                                        {t("feed.Tomorrow")}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </SearchBar>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* notification --- */}
                    <div
                      className={
                        tournamentFilter
                          ? "d-flex align-items-center"
                          : "d-flex align-items-center mb-3 w-100 justify-content-end notificationDataIcon"
                      }
                    >
                      <div
                        className="notification-part"
                        onClick={handleAddPredictionBetSlip}
                      >
                        <img src="/ticket.svg" className="" />
                        <span className="button__badge">
                          {
                            JSON.parse(localStorage.getItem("createPrediction"))
                              ?.length
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    {showLoader ? (
                      ""
                    ) : tournamentData?.isAllEmpty ? (
                      <div className="empty-data-error-message">
                        <div className="ThereAreNoMatches-title">
                          {t("feed.ThereAreNoMatches")}
                        </div>
                        <div className="tournamentOrDay-title">
                          {t("feed.tournamentOrDay")}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {showLoader ? (
                      <AddPredictionModel />
                    ) : (
                      <div className={isOpenTable}>
                        {/* large screen - leptop  */}
                        <div className="largeScreen-addPredictionTable">
                          <div
                            className="all-tab-scroll"
                            ref={listInnerRef}
                            onScroll={onScroll}
                          >
                            <div className="champions-league-table">
                              {tournamentData?.map((item, index) => (
                                <table width="100%" key={index}>
                                  {/* <colgroup>
                                    <col span="1" style={{ width: "40%" }} />
                                    <col span="1" style={{ width: "47px" }} />
                                    <col span="1" style={{ width: "47px" }} />
                                    <col span="1" style={{ width: "47px" }} />
                                    <col span="1" style={{ width: "47px" }} />
                                    <col span="1" style={{ width: "47px" }} />
                                    <col span="1" style={{ width: "47px" }} />
                                    <col span="1" style={{ width: "47px" }} />
                                  </colgroup> */}
                                  {item?.events_list?.length > 0 ? (
                                    <thead>
                                      <tr className="champions-table-Header">
                                        <th className="champions-leaue-country-name">
                                          <div className="d-flex">
                                            <span>
                                              <img
                                                src={
                                                  "country-logo/" +
                                                  item.country_id +
                                                  ".png"
                                                }
                                              ></img>
                                            </span>
                                            {apiService.getProfileFromStorage()
                                              ?.Language == "en"
                                              ? item.tournament_name_en
                                                ? item.tournament_name_en
                                                : item.tournament_name
                                              : item.tournament_name}
                                          </div>
                                        </th>
                                        <th>
                                          <div>1</div>
                                        </th>
                                        <th>
                                          <div>X</div>
                                        </th>
                                        <th>
                                          <div>2</div>
                                        </th>
                                        <th>
                                          <div>1X</div>
                                        </th>
                                        <th>
                                          <div>12</div>
                                        </th>
                                        <th>
                                          <div>X2</div>
                                        </th>
                                        <th className="champions-add-btn">+</th>
                                      </tr>
                                    </thead>
                                  ) : (
                                    ""
                                  )}

                                  {item?.events_list.map((event, index) => (
                                    <tbody
                                      className="champions-leaue-score-body"
                                      key={index}
                                      onClick={() =>
                                        handleExtraMatchesData(event)
                                      }
                                    >
                                      <tr className="leauge-alldata-name-score">
                                        <td className="leaue-score champions-leaue-first-score champions-leaue-score-name">
                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex champions-league-detail">
                                              <div className="champions-leaue-score-date-time">
                                                <div className="champions-leaue-score-date">
                                                  {moment
                                                    .unix(event?.game_start)
                                                    .format("DD/MM")}
                                                </div>
                                                <div className="champions-leaue-score-timer">
                                                  {moment
                                                    .unix(event?.game_start)
                                                    .format("HH:mm")}
                                                </div>
                                              </div>
                                              <div className="logo-leaueName-containt">
                                                <div className="champions-leaue-name champions-top">
                                                  <img
                                                    src={
                                                      process.env
                                                        .REACT_APP_teamLogo +
                                                      event?.opp_1_icon
                                                    }
                                                    className="champions-leaue-logo"
                                                  />
                                                  {apiService.getProfileFromStorage()
                                                    ?.Language == "en"
                                                    ? event?.opp_1_name_en
                                                      ? event?.opp_1_name_en
                                                      : event?.opp_1_name
                                                    : event?.opp_1_name}
                                                </div>
                                                <div className="champions-leaue-name">
                                                  <img
                                                    src={
                                                      process.env
                                                        .REACT_APP_teamLogo +
                                                      event?.opp_2_icon
                                                    }
                                                    className="champions-leaue-logo"
                                                  />
                                                  {apiService.getProfileFromStorage()
                                                    ?.Language == "en"
                                                    ? event?.opp_2_name_en
                                                      ? event?.opp_2_name_en
                                                      : event?.opp_2_name
                                                    : event?.opp_2_name}
                                                </div>
                                              </div>
                                            </div>
                                            {/* <div className='d-flex champions-leaue-name-right-part'>
                                                                                                    <div className="score-0-1">
                                                                                                        <div style={{ paddingBottom: "4px" }}>1</div>
                                                                                                        <div>0</div>
                                                                                                    </div>
                                                                                                    <div className="score-0-1">
                                                                                                        <div style={{ paddingBottom: "4px" }}>0</div>
                                                                                                        <div>1</div>
                                                                                                    </div>
                                                                                                </div> */}
                                          </div>
                                        </td>
                                        {handleOddsData(event).map((i) => {
                                          return i.data;
                                        })}
                                        {/* {
                                                                                            event.game_oc_list.map((it) => {
                                                                                                const arrEl = []
                                                                                                console.log("arrEl::", arrEl)
                                                                                                let pt = it.oc_pointer.split("|")
                                                                                                if (pt[1] == 1 && pt[2] == 3) {
                                                                                                    return <td className='leaue-score champions-leaue-score-data-X' onClick={(e) => handleLeaueActive(event, 3, 1, e)} style={{ pointerEvents: !event.teamDrowWin && "none" }}><div className={handleActiveOdds(it.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>{!isNaN(event.teamDrowWin) ? parseFloat(event.teamDrowWin).toFixed(2) : "-"}</div></td>
                                                                                                }
                                                                                                if (pt[1] == 1 && pt[2] == 2) {
                                                                                                    return <td className='leaue-score champions-leaue-score-data-2' onClick={(e) => handleLeaueActive(event, 2, 1, e)} style={{ pointerEvents: !event.teamTwoWin && "none" }}><div className={handleActiveOdds(it.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>{!isNaN(event.teamTwoWin) ? parseFloat(event.teamTwoWin || "-").toFixed(2) : "-"}</div></td>
                                                                                                }
                                                                                                if (pt[1] == 8 && pt[2] == 4) {
                                                                                                    return <td className='leaue-score champions-leaue-score-data-1X' onClick={(e) => handleLeaueActive(event, 4, 8, e)} style={{ pointerEvents: !event.teamOneWinDouble && "none" }}><div className={handleActiveOdds(it.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>{!isNaN(event.teamOneWinDouble) ? parseFloat(event.teamOneWinDouble).toFixed(2) : "-"}</div></td>
                                                                                                }
                                                                                                if (pt[1] == 8 && pt[2] == 6) {
                                                                                                    return <td className='leaue-score champions-leaue-score-data-12' onClick={(e) => handleLeaueActive(event, 6, 8, e)} style={{ pointerEvents: !event.teamDrowDouble && "none" }}><div className={handleActiveOdds(it.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>{!isNaN(event.teamDrowDouble) ? parseFloat(event.teamDrowDouble).toFixed(2) : "-"}</div></td>
                                                                                                }
                                                                                                if (pt[1] == 8 && pt[2] == 5) {
                                                                                                    return <td className='leaue-score champions-leaue-score-data-X2' onClick={(e) => handleLeaueActive(event, 5, 8, e)} style={{ pointerEvents: !event.teamTwoWinDouble && "none" }}><div className={handleActiveOdds(it.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>{!isNaN(event.teamTwoWinDouble) ? parseFloat(event.teamTwoWinDouble).toFixed(2) : "-"}</div></td>
                                                                                                }
                                                                                            })
                                                                                        } */}
                                        {/* <td className='leaue-score champions-leaue-score-data-X' onClick={(e) => handleLeaueActive(event, 3, 1, e)} style={{ pointerEvents: !event.teamDrowWin && "none" }}><div className={handleActiveOdds(event.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>{!isNaN(event.teamDrowWin) ? parseFloat(event.teamDrowWin).toFixed(2) : "-"}</div></td>
                                                                                        <td className='leaue-score champions-leaue-score-data-2' onClick={(e) => handleLeaueActive(event, 2, 1, e)} style={{ pointerEvents: !event.teamTwoWin && "none" }}><div className={handleActiveOdds(event.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>{!isNaN(event.teamTwoWin) ? parseFloat(event.teamTwoWin || "-").toFixed(2) : "-"}</div></td>
                                                                                        <td className='leaue-score champions-leaue-score-data-1X' onClick={(e) => handleLeaueActive(event, 4, 8, e)} style={{ pointerEvents: !event.teamOneWinDouble && "none" }}><div className={handleActiveOdds(event.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>{!isNaN(event.teamOneWinDouble) ? parseFloat(event.teamOneWinDouble).toFixed(2) : "-"}</div></td>
                                                                                        <td className='leaue-score champions-leaue-score-data-12' onClick={(e) => handleLeaueActive(event, 6, 8, e)} style={{ pointerEvents: !event.teamDrowDouble && "none" }}><div className={handleActiveOdds(event.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>{!isNaN(event.teamDrowDouble) ? parseFloat(event.teamDrowDouble).toFixed(2) : "-"}</div></td>
                                                                                        <td className='leaue-score champions-leaue-score-data-X2' onClick={(e) => handleLeaueActive(event, 5, 8, e)} style={{ pointerEvents: !event.teamTwoWinDouble && "none" }}><div className={handleActiveOdds(event.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>{!isNaN(event.teamTwoWinDouble) ? parseFloat(event.teamTwoWinDouble).toFixed(2) : "-"}</div></td> */}
                                        <td
                                          className="leaue-score champions-leaue-last-score"
                                          onClick={() =>
                                            handleExtraMatchesData(event)
                                          }
                                        >
                                          <div className="champions-leaue-score last-score-box">
                                            <div>{t("feed.More")}</div>
                                            <div>
                                              {/* {handleMoreOddsData(event)} */}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))}
                                </table>
                              ))}
                            </div>
                          </div>
                        </div>
                        {/*  medium screen / teblet */}
                        <div className="mediumScreen-addPredictionTable">
                          <div
                            className="all-tab-scroll"
                            ref={MediumSizelistInnerRef}
                            onScroll={mediumOnScroll}
                          >
                            <div className="champions-league-table">
                              {tournamentData?.map((item, index) => (
                                <table width="100%" key={index}>
                                  <colgroup>
                                    <col span="1" style={{ width: "55%" }} />
                                    <col span="1" style={{ width: "210px" }} />
                                    <col span="1" style={{ width: "210px" }} />
                                    <col span="1" style={{ width: "210px" }} />
                                    <col span="1" style={{ width: "210px" }} />
                                  </colgroup>
                                  <thead>
                                    <tr className="champions-table-Header">
                                      <th className="champions-leaue-country-name">
                                        <div className="d-flex">
                                          <span>
                                            <img
                                              src={
                                                "country-logo/" +
                                                item.country_id +
                                                ".png"
                                              }
                                            ></img>
                                          </span>
                                          {apiService.getProfileFromStorage()
                                            ?.Language == "en"
                                            ? item.tournament_name_en
                                              ? item.tournament_name_en
                                              : item.tournament_name
                                            : item.tournament_name}
                                        </div>
                                      </th>
                                      <th>
                                        <div>1</div>
                                      </th>
                                      <th>
                                        <div>X</div>
                                      </th>
                                      <th>
                                        <div>2</div>
                                      </th>
                                      <th className="champions-add-btn">+</th>
                                    </tr>
                                  </thead>
                                  {item.events_list.map((event, index) => (
                                    <tbody
                                      className="champions-leaue-score-body"
                                      key={index}
                                      onClick={() =>
                                        handleExtraMatchesData(event)
                                      }
                                    >
                                      <tr className="leauge-alldata-name-score">
                                        <td className="leaue-score champions-leaue-first-score champions-leaue-score-name">
                                          <div className="d-flex justify-content-between">
                                            <div className="d-flex champions-league-detail">
                                              <div className="champions-leaue-score-date-time">
                                                <div className="champions-leaue-score-date">
                                                  {moment
                                                    .unix(event?.game_start)
                                                    .format("DD/MM")}
                                                </div>
                                                <div className="champions-leaue-score-timer">
                                                  {moment
                                                    .unix(event?.game_start)
                                                    .format("HH:mm")}
                                                </div>
                                              </div>
                                              <div className="logo-leaueName-containt">
                                                <div className="champions-leaue-name champions-top">
                                                  <img
                                                    src={
                                                      process.env
                                                        .REACT_APP_teamLogo +
                                                      event?.opp_1_icon
                                                    }
                                                    className="champions-leaue-logo"
                                                  />
                                                  {apiService.getProfileFromStorage()
                                                    ?.Language == "en"
                                                    ? event?.opp_1_name_en
                                                      ? event?.opp_1_name_en
                                                      : event?.opp_1_name
                                                    : event?.opp_1_name}
                                                </div>
                                                <div className="champions-leaue-name">
                                                  <img
                                                    src={
                                                      process.env
                                                        .REACT_APP_teamLogo +
                                                      event?.opp_2_icon
                                                    }
                                                    className="champions-leaue-logo"
                                                  />
                                                  {apiService.getProfileFromStorage()
                                                    ?.Language == "en"
                                                    ? event?.opp_2_name_en
                                                      ? event?.opp_2_name_en
                                                      : event?.opp_2_name
                                                    : event?.opp_2_name}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        {handleMediumDeviceOddsData(event).map(
                                          (i) => {
                                            return i.data;
                                          }
                                        )}
                                        {/* {
                                                                                            event.game_oc_list.map((it) => {
                                                                                                let pt = it.oc_pointer.split("|")
                                                                                                if (pt[1] == 1 && pt[2] == 1) {
                                                                                                    return <td className='leaue-score champions-leaue-score-data-1' onClick={(e) => handleLeaueActive(event, 1, 1, e)} style={{ pointerEvents: !event.teamOneWin && "none" }}><div className={handleActiveOdds(it.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"} >{!isNaN(event.teamOneWin) ? parseFloat(event.teamOneWin).toFixed(2) : "-"}</div></td>
                                                                                                }
                                                                                                if (pt[1] == 1 && pt[2] == 3) {
                                                                                                    return <td className='leaue-score champions-leaue-score-data-X' onClick={(e) => handleLeaueActive(event, 3, 1, e)} style={{ pointerEvents: !event.teamDrowWin && "none" }}><div className={handleActiveOdds(it.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>{!isNaN(event.teamDrowWin) ? parseFloat(event.teamDrowWin).toFixed(2) : "-"}</div></td>
                                                                                                }
                                                                                                if (pt[1] == 1 && pt[2] == 2) {
                                                                                                    return <td className='leaue-score champions-leaue-score-data-2' onClick={(e) => handleLeaueActive(event, 2, 1, e)} style={{ pointerEvents: !event.teamTwoWin && "none" }}><div className={handleActiveOdds(it.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>{!isNaN(event.teamTwoWin) ? parseFloat(event.teamTwoWin || "-").toFixed(2) : "-"}</div></td>
                                                                                                }
                                                                                            })
                                                                                        } */}
                                        {/* <td className='leaue-score champions-leaue-score-data-1' onClick={(e) => handleLeaueActive(event, 1, 1, e)} style={{ pointerEvents: !event.teamOneWin && "none" }}><div className={handleActiveOdds(event.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"} >{!isNaN(event.teamOneWin) ? parseFloat(event.teamOneWin).toFixed(2) : "-"}</div></td>
                                                                                        <td className='leaue-score champions-leaue-score-data-X' onClick={(e) => handleLeaueActive(event, 1, 3, e)} style={{ pointerEvents: !event.teamDrowWin && "none" }}><div className={handleActiveOdds(event.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>{!isNaN(event.teamDrowWin) ? parseFloat(event.teamDrowWin).toFixed(2) : "-"}</div></td>
                                                                                        <td className='leaue-score champions-leaue-score-data-2' onClick={(e) => handleLeaueActive(event, 1, 2, event.opp_2_name, e)} style={{ pointerEvents: !event.teamTwoWin && "none" }}><div className={handleActiveOdds(event.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>{!isNaN(event.teamTwoWin) ? parseFloat(event.teamTwoWin || "-").toFixed(2) : "-"}</div></td> */}
                                        <td
                                          className="leaue-score champions-leaue-last-score"
                                          onClick={() =>
                                            handleExtraMatchesData(event)
                                          }
                                        >
                                          <div className="champions-leaue-score last-score-box">
                                            <div>{t("feed.More")}</div>

                                            {/* <div>
                                              {handleMediumScreenMoreOdds(
                                                event
                                              )}
                                            </div> */}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))}
                                </table>
                              ))}
                            </div>
                          </div>
                        </div>

                        {/* small device and mobile  */}
                        <div className="smallScreen-addPredictionTable">
                          <div
                            className="all-tab-scroll"
                            ref={SmallSizelistInnerRef}
                            onScroll={SmallOnScroll}
                          >
                            <div className="champions-league-table">
                              {tournamentData?.map((item, index) => (
                                <div className="w-100" key={index}>
                                  <div>
                                    <div className="champions-table-Header">
                                      <div className="champions-leaue-country-name">
                                        <div className="d-flex">
                                          <span>
                                            <img
                                              src={
                                                "country-logo/" +
                                                item.country_id +
                                                ".png"
                                              }
                                            ></img>
                                          </span>
                                          {apiService.getProfileFromStorage()
                                            ?.Language == "en"
                                            ? item?.tournament_name_en
                                              ? item?.tournament_name_en
                                              : item?.tournament_name
                                            : item?.tournament_name}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {item.events_list.map((event, index) => (
                                    <div
                                      className="champions-table-containts"
                                      key={index}
                                    >
                                      <div className="champions-leaue-score-date-time ">
                                        <div className="champions-leaue-score-date pb-3">
                                          {moment
                                            .unix(event?.game_start)
                                            .format("DD/MM/YYYY")}{" "}
                                          <span className="px-2">|</span>{" "}
                                          {moment
                                            .unix(event?.game_start)
                                            .format("HH:mm")}
                                        </div>
                                      </div>
                                      <div className="d-flex champions-league-detail">
                                        <div className="logo-leaueName-containt">
                                          <div className="champions-leaue-name champions-top">
                                            <img
                                              src={
                                                process.env.REACT_APP_teamLogo +
                                                event?.opp_1_icon
                                              }
                                              className="champions-leaue-logo"
                                            />
                                            {apiService.getProfileFromStorage()
                                              ?.Language == "en"
                                              ? event?.opp_1_name_en
                                                ? event?.opp_1_name_en
                                                : event?.opp_1_name
                                              : event?.opp_1_name}
                                          </div>
                                          <div className="champions-leaue-name">
                                            <img
                                              src={
                                                process.env.REACT_APP_teamLogo +
                                                event?.opp_2_icon
                                              }
                                              className="champions-leaue-logo"
                                            />
                                            {apiService.getProfileFromStorage()
                                              ?.Language == "en"
                                              ? event?.opp_2_name_en
                                                ? event?.opp_2_name_en
                                                : event?.opp_2_name
                                              : event?.opp_2_name}
                                          </div>
                                        </div>
                                        {/* <div className='d-flex champions-leaue-name-right-part'>
                                                                                                <div className="score-0-1">
                                                                                                    <div style={{ paddingBottom: "4px" }}>1</div>
                                                                                                    <div>0</div>
                                                                                                </div>
                                                                                                <div className="score-0-1">
                                                                                                    <div style={{ paddingBottom: "4px" }}>0</div>
                                                                                                    <div>1</div>
                                                                                                </div>
                                                                                            </div> */}
                                      </div>
                                      <div className="leaue-score leaue-score-test d-flex justify-content-between">
                                        {handleMobileDeviceOddsData(event).map(
                                          (i) => {
                                            return i.data;
                                          }
                                        )}
                                        {/* {
                                                                                            event.game_oc_list.map((it) => {
                                                                                                let pt = it.oc_pointer.split("|")
                                                                                                if (pt[1] == 1 && pt[2] == 1) {
                                                                                                    return <div className='leaue-score-box champions-leaue-score-data-1' onClick={(e) => handleLeaueActive(event, 1, 1, e)} style={{ pointerEvents: !event.teamOneWin && "none" }}>
                                                                                                        <div className={handleActiveOdds(it.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>
                                                                                                            <div>1</div>
                                                                                                            <div>{!isNaN(event.teamOneWin) ? parseFloat(event.teamOneWin).toFixed(2) : "-"}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                if (pt[1] == 1 && pt[2] == 3) {
                                                                                                    return <div className='leaue-score-box champions-leaue-score-data-X' onClick={(e) => handleLeaueActive(event, 3, 1, e)} style={{ pointerEvents: !event.teamDrowWin && "none" }}>
                                                                                                        <div className={handleActiveOdds(it.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>
                                                                                                            <div>X</div>
                                                                                                            <div>{!isNaN(event.teamDrowWin) ? parseFloat(event.teamDrowWin).toFixed(2) : "-"}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                if (pt[1] == 1 && pt[2] == 2) {
                                                                                                    return <div className='leaue-score-box  champions-leaue-score-data-2' onClick={(e) => handleLeaueActive(event, 2, 1, e)} style={{ pointerEvents: !event.teamTwoWin && "none" }}>
                                                                                                        <div className={handleActiveOdds(it.oc_pointer, eventList.game_id) ? "champions-leaue-score champions-leaue-score-active" : "champions-leaue-score"}>
                                                                                                            <div>2</div>
                                                                                                            <div>{!isNaN(event.teamTwoWin) ? parseFloat(event.teamTwoWin || "-").toFixed(2) : "-"}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                            })
                                                                                        } */}
                                        <div
                                          className="leaue-score-box champions-leaue-last-score"
                                          onClick={() =>
                                            handleExtraMatchesData(event)
                                          }
                                        >
                                          <div className="champions-leaue-score last-score-box">
                                            <div>{t("feed.More")}</div>

                                            {/* <div>
                                              {handleMediumScreenMoreOdds(
                                                event
                                              )}
                                            </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {extraOddsDataLoader ? (
                      <MoreOddsCustomLoader />
                    ) : (
                      <div className={isOddScoreDisplay}>
                        <div>
                          {/* header --------- */}

                          <div className="extra-Champions-League">
                            {props.predictionTableHide ? (
                              " "
                            ) : (
                              <div
                                className="back-btn"
                                onClick={handleBackToAddPrediction}
                              >
                                <img src="Arrow---Left.svg"></img>
                              </div>
                            )}
                            <div className="Champions-League-name">
                              <span>
                                <img
                                  src={
                                    extraOdd.country_id
                                      ? "country-logo/" +
                                        extraOdd.country_id +
                                        ".png"
                                      : "sport-logo/" +
                                        eventList.sport_id +
                                        ".svg"
                                  }
                                ></img>
                              </span>
                              {eventList.tournament_name}
                            </div>
                          </div>
                          {/* time-date-teams */}
                          <div className="teamName-data-time">
                            <div className="match-date-time">
                              {" "}
                              {moment
                                .unix(eventList.game_start)
                                .format("DD/MM/YYYY")}{" "}
                              {moment
                                .unix(eventList.game_start)
                                .format("HH:mm")}
                            </div>
                            <div className="d-flex justify-content-center">
                              <div className="d-flex align-items-center eventListOpp_1_data">
                                <div className="team-1-name">
                                  {eventList.opp_1_name}
                                </div>
                                <span
                                  style={{
                                    marginRight: "16px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_teamLogo +
                                      eventList.opp_1_icon
                                    }
                                    width="64px"
                                    height="64px"
                                  />
                                </span>
                              </div>
                              <div className="minus-btn">-</div>
                              <div className="d-flex align-items-center eventListOpp_2_data">
                                <span
                                  style={{
                                    marginLeft: "16px",
                                    marginRight: "10px",
                                  }}
                                  className=""
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_teamLogo +
                                      eventList.opp_2_icon
                                    }
                                    width="64px"
                                    height="64px"
                                  />
                                </span>
                                <div className="team-2-name">
                                  {eventList.opp_2_name}
                                </div>
                              </div>
                            </div>
                          </div>

                          {eventList.game_oc_list?.map((item, index) =>
                            handleExtraOddsData(item, eventList, index)
                          )}

                          {/* {eventList.game_oc_list?.map((item, index) => (
                                                        <div className='full-time-result' key={index}>
                                                            <div key={index}>
                                                                {item.group_name === "1x2" ?
                                                                    <div>
                                                                        <div className="full-time-result-title">{item.group_name === "1x2" && "Full-time result"}</div>
                                                                        <div className="row">
                                                                            {item.oc_list?.map((event, index) => (
                                                                                <div className={handleActiveOdds(item.group_name, event.oc_name, eventList.game_id) ? `col-lg-${12 / item.columns} col-sm-12 col-md-4 col-4 full-time-team-1 full-time-team-1-active` : `col-lg-${12 / item.columns} col-sm-12 col-md-4 col-4 full-time-team-1`} style={{ pointerEvents: !event.oc_name && !event.oc_rate && "none" }} onClick={() => handleLeaueActiveExtra(eventList, item.group_name, event.oc_name)} key={index}>
                                                                                    <div>{event.oc_name || "-"}</div>
                                                                                    <div>{!isNaN(event.oc_rate) ? parseFloat(event.oc_rate).toFixed(2) : "-"}</div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div> : ""
                                                                }
                                                            </div>
                                                        </div>
                                                    ))} */}

                          {/* {extraOddEventData.onextwo?.length > 0 ? <div className='full-time-result'>
                                                        <div className="full-time-result-title">{t("profile.FullTimeResult")}</div>
                                                        <div className="d-flex">
                                                            {extraOddEventData.onextwo?.map((item, index) => (
                                                                < div className={handleActiveOdds("1x2", item.oc_name, extraOdd.game_id) ? "col-sm full-time-team-1 full-time-team-1-active" : "col-sm full-time-team-1"} style={{ pointerEvents: !item.oc_name && !item.oc_rate && "none", marginLeft: "16px" }} onClick={() => handleLeaueActive(extraOdd, "1x2", item.oc_name)} key={index}>
                                                                    <div>{item.oc_name || "-"}</div>
                                                                    <div>{!isNaN(item.oc_rate) ? parseFloat(item.oc_rate).toFixed(2) : "-"}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div> : ""} */}

                          {/* double-time-result */}
                          {/* {extraOddEventData.DoubleChance?.length > 0 ? <div className='Double-chance' >
                                                        <div className="Double-chance-title">{t("profile.DoubleChance")}</div>
                                                        <div className="d-flex">
                                                            {extraOddEventData.DoubleChance?.map((item, index) => (
                                                                <div className={handleActiveOdds("Double Chance", item.oc_name, extraOdd.game_id) ? "col-sm Double-chance-team-1 Double-chance-team-1-active" : "col-sm Double-chance-team-1"} key={index} style={{ marginLeft: "16px" }} onClick={() => handleLeaueActive(extraOdd, "Double Chance", item.oc_name)}>
                                                                    <div>{item.oc_name || "-"}</div>
                                                                    <div>{!isNaN(item.oc_rate) ? parseFloat(item.oc_rate).toFixed(2) : "-"}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div> : ""} */}

                          {/* totle-odds-part */}
                          {/* {extraOddEventData.BothTeamsTotal?.length > 0 ? <div className='total-odds-contains'>
                                                        <div className="total-odds-title">{t("profile.Total")}</div>
                                                        <div className="d-flex">
                                                            <div className="col-sm total-teams" style={{ marginRight: "16px" }}>
                                                            </div>
                                                            <div className="col-sm Over-title" style={{ marginRight: "16px" }}>
                                                                <div>{t("profile.Over")}</div>
                                                            </div>
                                                            <div className="col-sm Under-title">
                                                                <div>{t("profile.Under")}</div>
                                                            </div>
                                                        </div>
                                                        {extraOddEventData.BothTeamsTotal?.map((item, index) => (
                                                            <div className="d-flex pb-3" key={index}>
                                                                <div className="col-sm total-teams-score" style={{ marginRight: "16px" }}>
                                                                    <div>{!isNaN(item.value) ? parseFloat(item.value).toFixed(2) : "-"}</div>
                                                                </div>
                                                                <div className={handleActiveOdds("Total", item.overObj.oc_name, extraOdd.game_id) ? "col-sm Over-score Over-score-active" : "col-sm Over-score"} style={{ marginRight: "16px" }} onClick={() => handleLeaueActive(extraOdd, "Total", item.overObj.oc_name)}>
                                                                    <div>{!isNaN(item.over) ? parseFloat(item.over).toFixed(2) : "-"}</div>
                                                                </div>
                                                                <div className={handleActiveOdds("Total", item.underObj.oc_name, extraOdd.game_id) ? "col-sm Under-score Under-score-active" : "col-sm Under-score"} onClick={() => handleLeaueActive(extraOdd, "Total", item.underObj.oc_name)}>
                                                                    <div>{!isNaN(item.under) ? parseFloat(item.under).toFixed(2) : "-"}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div> : ""} */}

                          {/* Handicap-part */}
                          {/* {extraOddEventData.Handicap?.length > 0 ? <div className='Handicap-contains'>
                                                        <div className="Handicap-title">{t("profile.Handicap")}</div>
                                                        <div className="d-flex">
                                                            <div className="col-sm Real-Madrid-title" style={{ marginRight: "16px" }}>
                                                                <div>{extraOdd.opp_1_name}</div>
                                                            </div>
                                                            <div className="col-sm Chelsea-title">
                                                                <div>{extraOdd.opp_2_name}</div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex pb-3">
                                                            {extraOddEventData.Handicap?.map((item, index) => (
                                                                <div className={handleActiveOdds("Handicap", item.oc_name, extraOdd.game_id) ? "col-sm Handicap-team1-score Handicap-team1-score-active" : "col-sm Handicap-team1-score"} key={index} style={{ marginRight: "16px" }} onClick={() => handleLeaueActive(extraOdd, "Handicap", item.oc_name)}>
                                                                    <div>{item.oc_name.split("Handicap") || "-"}</div>
                                                                    <div>{!isNaN(item.oc_rate) ? parseFloat(item.oc_rate).toFixed(2) : "-"}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div> : ""} */}

                          {/* team 1 Total */}
                          {/* {extraOddEventData.Team1Total?.length > 0 ? <div className='RealMadridTotal-contains'>
                                                        <div className='RealMadridTotal-title'>{extraOdd.opp_1_name} Total</div>
                                                        <div className="d-flex">
                                                            <div className="col-sm RealMadridTotal-teams p-3">
                                                            </div>
                                                            <div className="col-sm Over-title" style={{ marginRight: "16px" }}>
                                                                <div>{t("profile.Over")}</div>
                                                            </div>
                                                            <div className="col-sm Under-title">
                                                                <div>{t("profile.Under")}</div>
                                                            </div>
                                                        </div>
                                                        {extraOddEventData.Team1Total?.map((item, index) => (
                                                            <div className="d-flex pb-3" key={index}>
                                                                <div className="col-sm RealMadridTotal-teams-score" style={{ marginRight: "16px" }}>
                                                                    <div>{!isNaN(item.value) ? parseFloat(item.value).toFixed(2) : "-"}</div>
                                                                </div>
                                                                <div className={handleActiveOdds("Total 1", item.overObj.oc_name, extraOdd.game_id) ? "col-sm Over-score Over-score-active" : "col-sm Over-score"} style={{ marginRight: "16px" }} onClick={() => handleLeaueActive(extraOdd, "Total 1", item.overObj.oc_name)}>
                                                                    <div>{!isNaN(item.over) ? parseFloat(item.over).toFixed(2) : "-"}</div>
                                                                </div>
                                                                <div className={handleActiveOdds("Total 1", item.underObj.oc_name, extraOdd.game_id) ? "col-sm Under-score Under-score-active" : "col-sm Under-score"} onClick={() => handleLeaueActive(extraOdd, "Total 1", item.underObj.oc_name)}>
                                                                    <div>{!isNaN(item.under) ? parseFloat(item.under).toFixed(2) : "-"}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div> : ""} */}

                          {/* Team to score the goal № */}
                          {/* <div className='Teamtoscorethegoal-contains'>
                                                        <div className='Teamtoscorethegoal-title'>{t("profile.Teamtoscorethegoal")}</div>
                                                        <div className="d-flex">
                                                            <div className="col-sm Over-title" style={{ marginRight: "16px" }}>

                                                            </div>
                                                            <div className="col-sm Over-title" style={{ marginRight: "16px" }}>
                                                                <div>{t("profile.RealMadrid")}</div>
                                                            </div>
                                                            <div className="col-sm Over-title" style={{ marginRight: "16px" }}>
                                                                <div>{t("profile.None")}</div>
                                                            </div>
                                                            <div className="col-sm Under-title">
                                                                <div>{t("profile.Chelse")}</div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex pb-3">
                                                            <div className="col-sm teams-score" style={{ marginRight: "16px" }}>
                                                                <div>1</div>
                                                            </div>
                                                            <div className="col-sm RealMadrid-score" style={{ marginRight: "16px" }}>
                                                                <div>1.22</div>
                                                            </div>
                                                            <div className="col-sm None-score" style={{ marginRight: "16px" }}>
                                                                <div>1.22</div>
                                                            </div>
                                                            <div className="col-sm Chelse-score">
                                                                <div>1.22</div>
                                                            </div>
                                                        </div>


                                                    </div> */}

                          {/* Both teams to score */}
                          {/* {extraOddEventData.BothTeamsToScore?.length > 0 ? <div className='Bothteamstoscore-contains'>
                                                        <div className="Bothteamstoscore-title">{t("profile.Bothteamstoscore")}</div>
                                                        <div className="d-flex pb-3">
                                                            {extraOddEventData.BothTeamsToScore?.map((item, index) => (
                                                                <div className={handleActiveOdds("Both Teams To Score", item.oc_name, extraOdd.game_id) ? "col-sm Handicap-team1-score col-sm Handicap-team1-score-active" : "col-sm Handicap-team1-score"} key={index} style={{ marginRight: "16px" }} onClick={() => handleLeaueActive(extraOdd, "Both Teams To Score", item.oc_name)}>
                                                                    <div>{item.oc_name.split("-")[1] || "-"}</div>
                                                                    <div>{!isNaN(item.oc_rate) ? parseFloat(item.oc_rate).toFixed(2) : "-"}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div> : ""} */}

                          {/* Correct score */}
                          {/* <div className='Correctscore-contains'>
                                                        <div className='Correctscore-title'>{t("profile.Correctscore")}</div>
                                                        <div className="d-flex pb-3">
                                                            <div className="col-sm full-time-team-1" style={{ marginRight: "16px" }}>
                                                                <div>1-0</div>
                                                                <div>1.46</div>
                                                            </div>
                                                            <div className="col-sm full-time-team-2" style={{ marginRight: "16px" }}>
                                                                <div>0-0</div>
                                                                <div>2.46</div>
                                                            </div>
                                                            <div className="col-sm full-time-team-3">
                                                                <div>0-1</div>
                                                                <div>3.46</div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex pb-3">
                                                            <div className="col-sm full-time-team-1" style={{ marginRight: "16px" }}>
                                                                <div>2-0</div>
                                                                <div>1.46</div>
                                                            </div>
                                                            <div className="col-sm full-time-team-2" style={{ marginRight: "16px" }}>
                                                                <div>1-1</div>
                                                                <div>1.46</div>
                                                            </div>
                                                            <div className="col-sm full-time-team-3">
                                                                <div>0-2</div>
                                                                <div>1.46</div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex pb-3">
                                                            <div className="col-sm full-time-team-1" style={{ marginRight: "16px" }}>
                                                                <div>2-1</div>
                                                                <div>1.46</div>
                                                            </div>
                                                            <div className="col-sm full-time-team-2" style={{ marginRight: "16px" }}>
                                                                <div>2-2</div>
                                                                <div>1.46</div>
                                                            </div>
                                                            <div className="col-sm full-time-team-3">
                                                                <div>1-2</div>
                                                                <div>1.46</div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex pb-3">
                                                            <div className="col-sm full-time-team-1" style={{ marginRight: "16px" }}>
                                                                <div>3-1</div>
                                                                <div>1.46</div>
                                                            </div>
                                                            <div className="col-sm" style={{ marginRight: "16px" }}>

                                                            </div>
                                                            <div className="col-sm full-time-team-3">
                                                                <div>1-3</div>
                                                                <div>1.46</div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  addPrediction right-panel */}
          {/* defalut betSlip */}
          <div className="addPrediction-right-panel">
            <BetSlip
              increamentUserCount={props.increamentUserCount}
              countData={countData}
              incrementCount={incrementCount}
              hideModel={props.hideModel}
            />
          </div>
          {/* leptop betSlip */}
          <div className={showBetsSlip}>
            <BetSlip
              increamentUserCount={props.increamentUserCount}
              countData={countData}
              showBetsSlip={showBetsSlip}
              incrementCount={incrementCount}
              hideModel={props.hideModel}
              mediumBetSlip={true}
              hideBetSlip={handleHideBetSlip}
              mobileBetSlip={true}
            />
          </div>
        </div>
      </Model>
    </>
  );
};
export default AddPrediction;
