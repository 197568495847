import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import YouTube from "react-youtube";

import Model from "../../../molecule/model/Model";
import ToolTip from "../../../General/ToolTip/ToolTip";
import AlertNotification from "../../../General/AlertNotification/AlertNotification";

import apiService from "../../../../services/apiService";

import "./GameYoutubeVideo.css";

const GameYoutubeVideo = ({ gameId, gameVideo, setGameVideo }) => {
  const user = JSON.parse(localStorage.getItem("profile"));
  const { t, i18n } = useTranslation();

  const [youTubeVideoLink, setYouTubeVideoLink] = useState(gameVideo.link);
  const [youTubeVideoId, setYouTubeVideoId] = useState(gameVideo.link);

  const [showAddEditVideoLinkModal, setShowAddEditVideoLinkModal] =
    useState(false);
  const [showRemoveVideoLinkModal, setShowRemoveVideoLinkModal] =
    useState(false);
  const [addEditVideoModalTitle, setAddEditVideoModalTitle] = useState(
    t("gameDetail.videoReview.addVideo")
  );
  const [addEditVideoModalBtnTitle, setAddEditVideoModalBtnTitle] = useState(
    t("gameDetail.videoReview.addVideo")
  );

  const [showUpdateNotification, setShowUpdateNotification] = useState(false);
  const [showRemoveNotification, setShowRemoveNotification] = useState(false);

  const getYouTubeVideoId = (url) => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.searchParams.get("v");
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    setYouTubeVideoLink(gameVideo.link);
    setYouTubeVideoId(getYouTubeVideoId(gameVideo.link));
  }, [gameVideo]);

  const handleEdit = () => {
    setShowAddEditVideoLinkModal(true);
    setAddEditVideoModalTitle(t("gameDetail.videoReview.editVideo"));
    setAddEditVideoModalBtnTitle(t("gameDetail.videoReview.save"));
  };

  const handleAddGameVideo = async () => {
    const data = {
      gameId: gameId,
      link: youTubeVideoLink,
    };

    const res = await apiService.addGameVideo(gameId, data);

    if (res) {
      setGameVideo(res);
      setShowAddEditVideoLinkModal(false);
      setShowUpdateNotification(true);
    }
  };

  const handleDeleteGameVideo = async () => {
    const res = await apiService.deleteGameVideo(gameId);

    if (res) {
      setGameVideo({});
      setShowRemoveNotification(true);
    }

    setShowRemoveVideoLinkModal(false);
    setAddEditVideoModalTitle(t("gameDetail.videoReview.addVideo"));
    setAddEditVideoModalBtnTitle(t("gameDetail.videoReview.addVideo"));
  };

  return (
    <div>
      <div className="game-details__content-title youtube">
        {t("gameDetail.videoOverView")}

        {user?.role.includes("Admin") && gameVideo.link && (
          <div className="game-details__title-controls">
            <div
              className="game-details__title-controls_edit"
              onClick={handleEdit}
            >
              <ToolTip text={t("gameDetail.videoReview.edit")}>
                <img src="/edit.svg" alt="remove"></img>
              </ToolTip>
            </div>
            <div
              className="game-details__title-controls_remove"
              onClick={() => setShowRemoveVideoLinkModal(true)}
            >
              <ToolTip text={t("gameDetail.videoReview.remove")}>
                <img src="/remove.svg" alt="edit"></img>
              </ToolTip>
            </div>
          </div>
        )}
      </div>
      <div className="game-details__content-wrapper game-details__video-overview-wrapper">
        {gameVideo.link ? (
          <YouTube
            videoId={youTubeVideoId}
            opts={{
              height: "370",
              width: "100%",
              playerVars: {
                autoplay: 0,
              },
            }}
          />
        ) : (
          <>
            {user && user?.role.includes("Admin") && (
              <div className="game-details__add-video-wrapper">
                <div className="game-details__add-video-txt">
                  {t("gameDetail.videoReview.addVideoTxt")}
                </div>
                <div
                  className="game-details__add-video-btn"
                  onClick={() => setShowAddEditVideoLinkModal(true)}
                >
                  {t("gameDetail.videoReview.addVideo")}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <Model
        isModel={showAddEditVideoLinkModal}
        modelClassName={"add-edit-game-video__modal"}
        hideModel={() => setShowAddEditVideoLinkModal(false)}
        modelTitle={addEditVideoModalTitle}
      >
        <div className="game-video__wrapper">
          <input
            className="add-edit-game-video__input"
            placeholder="https://www.youtube.com..."
            type="text"
            onChange={(e) => setYouTubeVideoLink(e.target.value)}
            value={youTubeVideoLink}
            tabIndex="0"
          />
          <div className="game-video__controls">
            <div
              className="game-video__cancel-btn"
              onClick={() => setShowAddEditVideoLinkModal(false)}
            >
              {t("gameDetail.videoReview.cancel")}
            </div>
            <div
              className="game-details__add-video-btn"
              onClick={() => handleAddGameVideo()}
            >
              {addEditVideoModalBtnTitle}
            </div>
          </div>
        </div>
      </Model>

      <Model
        isModel={showRemoveVideoLinkModal}
        modelClassName={"remove-game-video__modal"}
        hideModel={() => setShowRemoveVideoLinkModal(false)}
        modelTitle={t("gameDetail.videoReview.removeVideo")}
      >
        <div className="game-video__wrapper">
          <div className="remove-game-video__warning">
            {t("gameDetail.videoReview.removeWarning")}
          </div>
          <div className="game-video__controls">
            <div
              className="game-video__cancel-btn"
              onClick={() => setShowRemoveVideoLinkModal(false)}
            >
              {t("gameDetail.videoReview.cancel")}
            </div>
            <div
              className="game-details__remove-video-btn"
              onClick={() => handleDeleteGameVideo()}
            >
              {t("gameDetail.videoReview.yes")}
            </div>
          </div>
        </div>
      </Model>
      
      {showUpdateNotification && (
        <AlertNotification
          message={t("gameDetail.videoReview.videoUpdated")}
          onClose={() => setShowUpdateNotification(false)}
          duration={2000}
          isVisible={showUpdateNotification}
        />
      )}

      {showRemoveNotification && (
        <AlertNotification
          message={t("gameDetail.videoReview.videoRemoved")}
          onClose={() => setShowRemoveNotification(false)}
          duration={2000}
          isVisible={showRemoveNotification}
          removed={true}
        />
      )}
    </div>
  );
};

export default GameYoutubeVideo;
