import axios from "axios";
import socketService from "./socketService";

class ApiService {
  BASE_API_URL = process.env.REACT_APP_baseUrl;
  reqQueue = {};
  constructor() {}
  async GET(path, h = {}) {
    const nPath = path.split("?")[0];
    // if (!this.reqQueue.hasOwnProperty(path)) {
    try {
      this.reqQueue[nPath] = nPath;
      const headers = {
        headers: h,
      };
      const res = await axios.get(this.BASE_API_URL + path, headers);
      // delete this.reqQueue[nPath];
      return { data: res.data, error: null };
    } catch (error) {
      // delete this.reqQueue[nPath];
      return { data: null, error: error.data };
      // if (error.response.status === 401) {
      //   window.open("https://dev.capperauditor.com", "_self")
      // } else {
      //   return { data: null, error: error.data };
      // }
    }
    // } else {
    //   return setTimeout(async () => {
    //     return this.GET(path, h);
    //   }, 1000);
    // }
  }
  async DELETE(path, h = {}) {
    try {
      const headers = {
        headers: h,
      };
      const res = await axios.delete(this.BASE_API_URL + path, headers);
      return { data: res.data, error: null };
    } catch (error) {
      return { data: null, error: error.data };

      // if (error.response.status === 401) {
      //   window.open("https://dev.capperauditor.com", "_self")
      // } else {
      //   return { data: null, error: error.data };
      // }
    }
  }
  async POST(path, data, h = {}) {
    try {
      const headers = {
        headers: h,
      };
      const res = await axios.post(this.BASE_API_URL + path, data, headers);
      return { data: res.data, error: null };
    } catch (error) {
      return { data: null, error: error.message };
    }
  }
  async signUp(data) {
    // console.log("data.l", data.language);
    const signupData = {
      email: data.email,
      password: data.password,
    };
    const res = await this.POST(`/auth?language=${data.language}`, signupData);
    return res;
  }
  async checkValidation(data) {
    const validation = {
      type: data.type,
      text: data.text,
    };
    return await this.POST("/auth/checkValidationType", validation);
  }
  async loginApi(data) {
    const loginData = {
      email: data.email,
      password: data.password,
    };
    const res = await this.POST("/auth/login", loginData);
    if (res.data) {
      localStorage.setItem("token", res.data.Token);
      localStorage.setItem("userId", res.data.user._id);
      localStorage.setItem("profile", JSON.stringify(res.data.user));
      localStorage.setItem("currency", res.data.Currency || "usd");
      // localStorage.setItem(
      //   "language",
      //   res.data?.user.Language ? res.data?.user.Language : "ua"
      // );
      // localStorage.setItem("bets", JSON.stringify([]));
    }
    return res;
  }

  async resetPassword(data) {
    const reqData = {
      email: data.email,
    };
    const headers = {
      Authorization: localStorage.getItem("token"),
    };
    const res = await this.POST("/auth/forgotPassword", reqData, headers);
    return res;
  }

  async updateProfile(data, token = null) {
    const headers = {
      Authorization: token ? "Bearer " + token : localStorage.getItem("token"),
    };
    const res = await this.POST("/auth/updateProfile", data, headers);

    if (res.data) {
      localStorage.setItem("token", token);
      localStorage.setItem("userId", res.data._id);
      localStorage.setItem("profile", JSON.stringify(res.data));
      localStorage.setItem(
        "language",
        res.data?.Language ? res.data?.Language : "ua"
      );
    }
    return res;
  }

  async updateProfileOAuth(data) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST("/auth/updateProfile/oauth", data, headers);
    if (res.data) {
      localStorage.setItem("profile", JSON.stringify(res.data));
      // localStorage.setItem(
      //   "language",
      //   res.data?.Language ? res.data?.Language : "ua"
      // );
    }
    return res;
  }
  async changePassword(newpass, token) {
    const reqData = {
      newPassword: newpass,
    };
    const headers = {
      Authorization: "Bearer " + token,
    };
    const res = await this.POST("/auth/changePassword", reqData, headers);
    return res;
  }

  async varifyEmailId(token) {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const res = await this.POST("/auth/varifyEmail", {}, headers);
    return res;
  }

  async getUserName(data) {
    const getData = {
      email: data.email,
    };
    const res = await this.POST("/auth/getuserName", getData);
    return res;
  }

  async getUserProfile() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET("/users/getProfile", headers);
    if (res.data) {
      localStorage.setItem("profile", JSON.stringify(res.data));
    }
    return res.data;
  }
  async getUserProfileCheckAndSet() {
    const profData = localStorage.getItem("profile");
    if (profData !== "null") {
      return JSON.parse(profData);
    } else {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      const res = await this.GET("/users/getProfile", headers);
      if (res.data) {
        localStorage.setItem("profile", JSON.stringify(res.data));
      }
      return res.data;
    }
  }

  async updateUserProfile(data) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST("/users/updateprofile", data, headers);
    if (res.data) {
      localStorage.setItem("profile", JSON.stringify(res.data));
      localStorage.setItem("currency", res.data.Currency || "usd");
      if (res.data.Language) {
        // localStorage.setItem("language", res.data.Language);
      }
    }
    return res.data;
  }

  async getOtheruserProfile(userName) {
    const res = await this.GET(`/users/otherUserProfile/${userName}`);
    return res.data;
  }
  async unfollowUser(data) {
    const unfollowUser = {
      id: data,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/users/unFollowUser`, unfollowUser, headers);
    if (res.data) {
      localStorage.setItem("profile", JSON.stringify(res.data));
    }
    return res;
  }

  async followUser(data) {
    const followUser = {
      id: data,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/users/followUser`, followUser, headers);
    if (res.data) {
      localStorage.setItem("profile", JSON.stringify(res.data));
    }
    return res;
  }

  async userChangePassword(data) {
    const passwordData = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    socketService.init();
    const res = await this.POST(`/users/changePassword`, passwordData, headers);

    return res;
  }

  async changeEmail(data) {
    const changeEmail = {
      email: data,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/users/changeEmail`, changeEmail, headers);
    return res;
  }

  async verifyEmail(token) {
    const headers = {
      Authorization: "Bearer " + token,
    };
    const res = await this.POST("/users/updateEmail", {}, headers);
    return res;
  }

  async getAllSports(data) {
    let queryStr = "";
    if (data.search) {
      queryStr += `search=${data.search}`;
    }
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(`/sport/getAllSport?${queryStr}`, headers);
    if (res.data) {
      localStorage.setItem("allSportData", JSON.stringify(res.data));
    }
    return res.data;
  }

  async getCountrys(data) {
    let queryStr = "";
    if (data.search) {
      queryStr += `search=${data.search}`;
    }
    if (data.sportId) {
      queryStr += `&sportId=${data.sportId}`;
    }
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(`/country/getAllCountry?${queryStr}`, headers);
    return res.data;
  }

  async getLeagues(data) {
    let queryStr = "";
    if (data.search) {
      queryStr += `&search=${data.search}`;
    }
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(
      `/league/getLeague/${data.id}?language=${data.language}${queryStr}`,
      headers
    );
    return res.data;
  }

  getProfileFromStorage() {
    return JSON.parse(localStorage.getItem("profile"));
  }

  isAdmin() {
    const role = this.getProfileFromStorage()?.role;
    if (role?.[0] == "Admin") {
      return true;
    }
    return false;
  }

  async leagueList(data) {
    const body = {
      tournament_id: data.isCountryCheck,
      pageNumber: data.pageNumber,
      pageSize: data.pageSize,
    };
    // console.log("body:::", body.tournament_id);
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    if (body.tournament_id.length < 1) {
      return { tournament: [], totalTournament: 0 };
    }
    const res = await this.POST(
      `/league/createLeagueList/${data.type}?sportid=${data.sportId}&language=${data.language}&filterType=${data.filterType}&timezone=${data.timezone}`,
      body,
      headers
    );
    return res.data;
  }

  async getEventList(data) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(
      `/league/getEvent/${data.gameId}?language=${data.language}&dataType=${data.type}`,
      headers
    );
    return res.data?.body;
  }
  async getFavSport() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(`/sport/favSport`, headers);
    if (res.data.list) {
      localStorage.setItem("prediction-list", JSON.stringify(res.data.list));
    } else {
      localStorage.setItem("prediction-list", JSON.stringify([]));
    }
    return res.data.list;
  }
  async createFavSport(data) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/sport/favSport`, data, headers);
    return res.data.list;
  }

  getPredictionListFromStorage() {
    const data = JSON.parse(localStorage.getItem("prediction-list"));
    return data;
  }

  async createPrediction(data) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST("/league/createPrediction", data, headers);
    if (res.data) {
      localStorage.setItem("createPrediction", JSON.stringify(res.data));
    }
    return res.data;
  }
  getPredictionFromStorage() {
    const data = JSON.parse(localStorage.getItem("createPrediction"));
    return data;
  }
  async deletePrediction(id) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.DELETE(`/league/deletePrediction/${id}`, headers);
    if (res.data) {
      localStorage.setItem("createPrediction", JSON.stringify(res.data));
    }
    return res.data;
  }

  async clearAllOdds() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.DELETE(`/league/deletePredictionByUser`, headers);
    if (res.data) {
      localStorage.setItem("createPrediction", JSON.stringify([]));
    }
    return res.data;
  }

  async createPredictionOrder(data, selectedTab) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(
      `/PredictionOrder/createPredictionOrder?dataType=${selectedTab}`,
      data,
      headers
    );
    // if (res.data) {
    //   localStorage.setItem("bets", JSON.stringify(res.data));
    // } else {
    //   localStorage.setItem("bets", JSON.stringify([]));
    // }
    return res.data;
  }

  async manageLikeDislike(data) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/PredictionOrder/likedislike`, data, headers);
    return res.data;
  }

  async getPredictionOrderList(pageSize, pageNumber) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(
      `/PredictionOrder/getPredictionOrderList?pageSize=${pageSize}&pageNumber=${pageNumber}`,
      headers
    );
    return res.data;
  }
  async getPredictionOrder(data, pageSize, pageNumber) {
    let queryStr = "";
    // if (pageSize && pageSize !== "") {
    //   queryStr += `&pageSize=${pageSize}`;
    // }
    if (pageNumber && pageNumber !== "") {
      queryStr += `&pageNumber=${pageNumber}`;
    }
    if (data.search && data.search !== "") {
      queryStr += `&search=${data.search}`;
    }
    if (data.sport_id && data.sport_id !== "") {
      queryStr += `&sport_id=${data.sport_id}`;
    }
    if (data.tournament_id && data.tournament_id !== "") {
      queryStr += `&tournament_id=${data.tournament_id}`;
    }
    if (data.following && data.following !== "") {
      queryStr += `&following=${data.following}`;
    }
    if (data.predictionType && data.predictionType !== "") {
      queryStr += `&predictionType=${data.predictionType}`;
    }
    if (data.oddMin && data.oddMin !== "") {
      queryStr += `&oddMin=${data.oddMin}`;
    }
    if (data.oddMax && data.oddMax !== "") {
      queryStr += `&oddMax=${data.oddMax}`;
    }
    if (data.sortBy && data.sortBy !== "") {
      queryStr += `&sortBy=${data.sortBy}`;
    }
    if (data.statusType && data.statusType !== "") {
      queryStr += `&statusType=${data.statusType}`;
    }
    if (data.userId && data.userId !== "") {
      queryStr += `&userId=${data.userId}`;
    }
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(
      `/PredictionOrder/getPredictionOrder?pageSize=${pageSize}${queryStr}`,
      headers
    );
    // if (res.data) {
    //   localStorage.setItem("bets", JSON.stringify(res.data));
    // } else {
    //   localStorage.setItem("bets", JSON.stringify([]));
    // }
    return res.data;
  }

  async getPredictionOrderByUserId(id, pageSize, pageNumber) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(
      `/PredictionOrder/getPredictionOrderByUserId/${id}?pageSize=${pageSize}&pageNumber=${pageNumber}`,
      headers
    );
    return res.data;
  }
  async getPopularEvents() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(`/league/getPopularEvents`, headers);
    return res.data;
  }

  async getPopularLeague() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(`/league/getPopularLeauge`, headers);
    return res.data;
  }
  async getPopularPublicEvents() {
    const res = await this.GET(`/league/getPopularPublicEvents`);
    return res.data;
  }
  async getPopularPublicLeague() {
    const res = await this.GET(`/league/getPopularPublicLeauge`);
    return res.data;
  }
  async getAnalyticsGraph(data, id) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    if (id !== "") {
      const res = await this.GET(
        `/PredictionOrder/getAnalyticsGraph?analytics=${data}&id=${id}`,
        headers
      );
      return res.data;
    }
  }
  async getAnalyticsUserCoin(id) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    if (id !== "") {
      const res = await this.GET(
        `/coin/getAnalyticsUserCoin?id=${id}`,
        headers
      );
      return res.data;
    }
  }
  // admin panel
  async getAllUser(data) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(`/admin/getAllUser?search=${data}`, headers);
    return res.data;
  }
  // delete user
  async deleteUserByAdmin(data) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.DELETE(`/admin/deleteUserByAdmin/${data}`, headers);
    return res.data;
  }
  // edit user
  async changeUserPermission(role, id, balanceData) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    let queryStr = "";
    if (balanceData && balanceData !== "") {
      queryStr += `&balance=${balanceData}`;
    }
    const res = await this.POST(
      `/admin/changeUserPermission?role=${role}&userId=${id}${queryStr}`,
      {},
      headers
    );
    return res.data;
  }
  async getTodaysUserAndPrediction() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(`/admin/getTodaysUserAndPrediction`, headers);
    return res.data;
  }
  async upcomingGameList(data, Language, sportId, timezone) {
    let queryStr = "";
    if (data.upcoming && data.upcoming !== "") {
      queryStr += `&upcoming=${data.upcoming}`;
    }
    if (data.tournament_name && data.tournament_name !== "") {
      queryStr += `&tournament_name=${data.tournament_name}`;
    }
    if (timezone && timezone !== "") {
      queryStr += `&timezone=${timezone}`;
    }
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(
      `/league/upcomingGameList?language=${Language}&sport_id=${sportId}${queryStr}`,
      headers
    );
    return res.data;
  }
  async getFavTournamentName(sportId, Language) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(
      `/league/getFavTournamentName?language=${Language}&sport_id=${sportId}`,
      headers
    );
    return res.data;
  }
  async getNotification() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(`/notification/getNotification`, headers);
    return res.data;
  }
  async getNotificationDetail(id) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    let queryStr = "";
    if (id && id !== "") {
      queryStr += `?notificationId=${id}`;
    }
    const res = await this.GET(
      `/notification/getNotificationDetail${queryStr}`,
      headers
    );
    return res.data;
  }
  async getAllNotification(data) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    let queryStr = "";
    if (data.size && data.size !== "") {
      queryStr += `&size=${data.size}`;
    }
    if (data.activeTabKey && data.activeTabKey !== "") {
      queryStr += `&notificationType=${data.activeTabKey}`;
    }
    const res = await this.GET(
      `/notification/getAllNotification?page=${data.page}${queryStr}`,
      headers
    );
    return res.data;
  }
  async getLatestPrediction(id) {
    if (id && id !== "") {
      const res = await this.GET(
        `/PredictionOrder/getLatestPrediction?id=${id}`
      );
      return res.data;
    }
  }
  async getTopPrediction(id) {
    if (id && id !== "") {
      const res = await this.GET(`/PredictionOrder/getTopPrediction?id=${id}`);
      return res.data;
    }
  }
  async creategiveaway(body) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/giveaway/creategiveaway`, body, headers);
    return res.data;
  }
  async getAllGiveaway(data) {
    let queryStr = "";
    if (data.size && data.size !== "") {
      queryStr += `&size=${data.size}`;
    }
    const res = await this.GET(
      `/giveaway/getAllGiveaway?page=${data.page}${queryStr}`
    );
    return res.data;
  }
  async JoinGiveaway(id) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/giveaway/JoinGiveaway?id=${id}`, {}, headers);
    return res.data;
  }
  async confirmSubscription(data) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(
      `/giveaway/confirmSubscription?instagramUserName=${data.instagramUserName}&tikTokUserName=${data.TiktokUserName}`,
      {},
      headers
    );
    return res.data;
  }
  async getGiveaway(id) {
    const res = await this.GET(`/giveaway/getGiveaway?id=${id}`);
    return res.data;
  }
  async makePrediction(oc_pointer, giveawayId) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(
      `/giveaway/makePrediction?oc_pointer=${oc_pointer}&giveawayId=${giveawayId}`,
      {},
      headers
    );
    return res.data;
  }
  async getpredictionParticipate(oc_pointer, giveawayId) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(
      `/giveaway/getpredictionParticipate?oc_pointer=${oc_pointer}&giveawayId=${giveawayId}`,
      headers
    );
    return res.data;
  }
  async confirmWinner(giveawayId, body) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(
      `/giveaway/confirmWinner?giveawayId=${giveawayId}`,
      body,
      headers
    );
    return res.data;
  }

  async getWinnersAndOtherParticipateOfWinners(giveawayId) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(
      `/giveaway/getWinnersAndOtherParticipateOfWinners?giveawayId=${giveawayId}`,
      headers
    );
  }
  async getAllSportLine(datatype) {
    // let queryStr = "";
    // if (datatype.datatype && datatype.datatype !== "") {
    //   queryStr += `&dataType=${datatype.datatype}`;
    // }
    const res = await this.GET(`/league/getAllSportLine?dataType=${datatype}`);
    return res.data;
  }
  async getAllPopularLeague(datatype) {
    // let queryStr = "";
    // if (datatype.datatype && datatype.datatype !== "") {
    //   queryStr += `&dataType=${datatype.datatype}`;
    // }
    const res = await this.GET(
      `/league/getAllPopularLeague?dataType=${datatype}`
    );
    // console.log("res:::::", res.data)
    return res.data;
  }
  async createHomeLeagueList(body) {
    const bodyData = {
      data: body.body,
    };
    let queryStr = "";
    if (body.datatype && body.datatype !== "") {
      queryStr += `&dataType=${body.datatype}`;
    }
    if (Object.keys(bodyData.data).length > 0) {
      const res = await this.POST(
        `/league/createHomeLeagueList?language=${body.language}${queryStr}`,
        bodyData
      );
      // console.log("res:::::", res)
      return res.data;
    }
  }
  async getSportWiseLeagueData(data) {
    const res = await this.GET(
      `/league/getSportWiseLeagueData/${data.id}?language=${data.language}`
    );
    return res.data;
  }
  async getUserCoin() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(`/coin/getUserCoin`, headers);
    return res.data;
  }
  async getEarningHistory(body) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    let queryStr = "";
    // if (body.pageSize && body.pageSize !== "") {
    //   queryStr += `&pageSize=${body.pageSize}`;
    // }
    if (body.pageNumber && body.pageNumber !== "") {
      queryStr += `&pageNumber=${body.pageNumber}`;
    }
    const res = await this.GET(
      `/coin/getCoinHistory?coinType=${body.data}${queryStr}`,
      headers
    );
    return res.data;
  }
  async collectCoins() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/coin/collectCoins`, {}, headers);
    return res.data;
  }
  async getCollectCoin() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(`/coin/getCollcetCoin`, headers);
    return res.data;
  }
  async getSignUpReward() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(`/admin/getSignUpReward`, headers);
    return res.data;
  }
  async updateSignUpReward(data) {
    const body = {
      signUpReward: data,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    const res = await this.POST(`/admin/updateSignUpReward`, body, headers);
    return res.data;
  }
  async editPrediction(id, body) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/admin/editPrediction/${id}`, body, headers);
    return res.data;
  }
  async deletePredictionData(id) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.DELETE(`/admin/deletePrediction/${id}`, headers);
    return res.data;
  }
  async uploadImage(body) {
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await this.POST(`/auth/uploadImage`, body, headers);
    return res.data;
  }
  async addProduct(body) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/admin/addProduct`, body, headers);
    return res.data;
  }
  // edit product
  async editProduct(body, id) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/admin/editProduct/${id}`, body, headers);
    return res.data;
  }
  async getAllProducts() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(`/users/getAllProduct`, headers);
    return res.data;
  }
  async deleteProductData(id) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.DELETE(`/admin/deleteProduct/${id}`, headers);
    return res.data;
  }
  async purchaseProduct(id) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/users/purchaseProduct/${id}`, {}, headers);
    return res.data;
  }
  async getAuthSignUpReward() {
    const res = await this.GET(`/auth/getSignUpReward`);
    return res.data;
  }
  async clearAllNotification() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(
      `/notification/clearAllNotification`,
      {},
      headers
    );
    return res.data;
  }
  async getHomePageData() {
    const res = await this.GET(`/auth/getHomePageData`);
    return res.data;
  }

  //me
  async getUsersRating(timePeriod) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(
      `/users/getTopUsers?timePeriod=${timePeriod}`,
      headers
    );
    return res.data;
  }

  async getTop10Users() {
    const res = await this.GET(
      `/users/getTop10Users`
    );
    return res.data;
  }

  async getUserRewards() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(`/users/getUserRewards`, headers);

    return res.data;
  }

  async getUserCurrentRewards(userId) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(`/users/getUserCurrentRewards?userId=${userId}`, headers);

    return res.data;
  }

  async getUserRewardsCount() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.GET(`/users/getUserRewardsCount`, headers);

    return res.data;
  }

  async addUserReward(data) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/users/addReward`, data, headers);

    return res.data;
  }

  async updateUserRewardStatus() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/users/updateUserRewardStatus`, [], headers);

    return res.data;
  }

  async getGameDetails(gameId) {
    const res = await this.GET(`/sportline/getGameDetails/${gameId}`);
    return res.data;
  }

  async getGameStatistic(gameId) {
    const res = await this.GET(`/sportline/getGameStatistic/${gameId}`);
    return res.data;
  }

  async getGameVideo(gameId) {
    const res = await this.GET(`/sportline/getGameVideo/${gameId}`);
    return res.data;
  }

  async deleteGameVideo(gameId) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    const res = await this.DELETE(`/sportline/deleteGameVideo/${gameId}`, headers);
    return res.data;
  }

  async getGameChartInfo(gameId) {
    const res = await this.GET(`/sportline/getGameChartInfo/${gameId}`);
    return res.data;
  }

  async getGameTopPredictions(gameId) {
    const res = await this.GET(`/sportline/getGameTopPredictions/${gameId}`);
    return res.data;
  }

  async addGameVote(data) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/sportline/addGameVote`, data, headers);

    return res.data;
  }

  async addGameVideo(gameId, data) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await this.POST(`/sportline/addGameVideo/${gameId}`, data, headers);

    return res.data;
  }

  async getGameVote(gameId) {
    const res = await this.GET(`/sportline/getGameVote/${gameId}`);

    return res.data;
  }

  async getUserVote(gameId) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    const res = await this.GET(`/sportline/getUserVote/${gameId}`, headers);

    return res.data;
  }
}

export default new ApiService();
