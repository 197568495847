import Chart from "chart.js/auto";
import { t } from "i18next";
import { event } from "jquery";
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useEffect, useState } from "react";
import apiService from "../../../services/apiService";
import "./chartSummary.css";
import { async } from "q";
import TopPredictionList from "./TopPredictionList";

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");
  if (!tooltipEl) {
    tooltipEl = document.getElementsByClassName("custom-tooltip")[0];
    // tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  return tooltipEl;
};
// const handleLanguageLocalization = (dates) => {

//   const formattedDates = dates.map(date => {
//     const localLocale = moment(date);
//     localLocale.locale(localStorage.getItem("language") == "ua" ? "uk" : "en");
//     const formattedDate = localLocale.format("MMMM D");
//     return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
//   });
//   return formattedDates
// };
const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  if (tooltip.yAlign) {
    tooltipEl.classList.add(tooltip.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);
    const date = document.getElementsByClassName("tooltip-title-date")[0];
    const winData = document.getElementsByClassName("windata-count")[0];
    const lostData = document.getElementsByClassName("lostdata-count")[0];
    const returnData = document.getElementsByClassName("returndata-count")[0];
    date.innerText = titleLines[0];
    bodyLines.map((data) => {
      let key = data[0].split(":")[0];
      let value = data[0].split(":")[1].trim();
      if (key == "Win") {
        winData.innerText = value;
      } else if (key == "Lost") {
        lostData.innerText = value;
      } else if (key == "Return") {
        returnData.innerText = value;
      }
    });
  }
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top =
    positionY + tooltip.caretY - tooltipEl.clientHeight - 5 + "px";
  // tooltipEl.style.font = tooltip.options.bodyFont.string;
  // tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';

  // function myFunction(x) {
  //     if (x.matches) { // If media query matches
  //         tooltipEl.style.left = ((positionX + mouseX) - 15) + "px";
  //     }
  // }
  // var x = window.matchMedia("(max-width: 768px)")
  // myFunction(x) // Call listener function at run time
  // x.addListener(myFunction) // Attach listener function on state changes
  // // medium screen
  // function myFunctionTeblet(x) {
  //     if (x.matches) { // If media query matches
  //         tooltipEl.style.left = ((positionX + mouseX) - 30) + "px";
  //     }
  // }
  // var x = window.matchMedia("(min-width: 769px)")
  // myFunctionTeblet(x) // Call listener function at run time
  // x.addListener(myFunctionTeblet) // Attach listener function on state changes
};
const ChartSummary = (props) => {
  const [BackGroundColor7Days, setBackGroundColor7Days] = useState(true);
  const [backgroundColor1Month, setBackGroundColor1Month] = useState(false);
  const [backgroundColor3Month, setBackGroundColor3Month] = useState(false);
  const [backgroundColor6Month, setBackGroundColor6Month] = useState(false);
  const [backgroundColorYear, setBackGroundColorYear] = useState(false);
  const [backgroundColorAll, setBackGroundColorAll] = useState(false);
  const [paiChartCount, setPaiChartCount] = useState({});
  const [dataOfXaxies, setDataOfXaxies] = useState([]);
  const [wonData, setWonData] = useState([]);
  const [LostData, setLostData] = useState([]);
  const [returnData, setReturnData] = useState([]);
  const [avgPrediction, setavgPrediction] = useState("");
  const [balanceData, setBalanceData] = useState({});
  const [chartEmptyMsg, setChartEmptyMsg] = useState(
    t("profile.Notenoughdata7Days")
  );
  const [barChartData, setBarChartData] = useState([]);
  const handleBalaceData = async () => {
    const res = await apiService.getAnalyticsUserCoin(props?.id);
    setBalanceData(res);
  };
  const handleXAxiesDateLanguageLocalization = (dates) => {
    const formattedDates = dates.map(date => {
      const localLocale = moment(date);
      localLocale.locale(localStorage.getItem("language") == "ua" ? "uk" : "en");
      const formattedDate = localLocale.format("MMMM D");
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    });
    return formattedDates
  };
  // bar chart dataa
  const handleBarChartData = async () => {
    const res = await apiService.getLatestPrediction(props?.id);
    const data = res?.reverse();
    setBarChartData(data);
  };
  // 7 days
  const handle7DayChart = async () => {
    var data = {
      date: [],
      Won: [],
      Returned: [],
      Lost: [],
    };
    setBackGroundColor7Days(true);
    setBackGroundColor1Month(false);
    setBackGroundColor3Month(false);
    setBackGroundColor6Month(false);
    setBackGroundColorYear(false);
    setBackGroundColorAll(false);
    setChartEmptyMsg(t("profile.Notenoughdata7Days"));
    const res = await apiService.getAnalyticsGraph("week", props?.id);
    if (res) {
      setavgPrediction(res?.avgPrediction);
      for (let elem of res.data) {
        // let dt = new Date(elem.date)
        data.date.push(moment(elem.date).format("MMMM D"));
        data.Won.push(
          elem.odds.find((item) => item.status == "Won")
            ? elem.odds.find((item) => item.status == "Won").count
            : 0
        );
        data.Returned.push(
          elem.odds.find((item) => item.status == "Returned")
            ? elem.odds.find((item) => item.status == "Returned").count
            : 0
        );
        data.Lost.push(
          elem.odds.find((item) => item.status == "Lost")
            ? elem.odds.find((item) => item.status == "Lost").count
            : 0
        );
      }
      setWonData(data.Won);
      setLostData(data.Lost);
      setReturnData(data.Returned);
      setDataOfXaxies(data.date);
      setPaiChartCount(res.total);
    }
  };
  // 1 month
  const handleOneMonthChart = async () => {
    var data = {
      date: [],
      Won: [],
      Returned: [],
      Lost: [],
    };
    setBackGroundColor1Month(true);
    setBackGroundColor7Days(false);
    setBackGroundColor3Month(false);
    setBackGroundColor6Month(false);
    setBackGroundColorYear(false);
    setBackGroundColorAll(false);
    setChartEmptyMsg(t("profile.Notenoughdata1Month"));
    const res = await apiService.getAnalyticsGraph("month", props?.id);
    for (let elem of res.data) {
      data.date.push(moment(elem.date).format("MMMM D"));
      data.Won.push(
        elem.odds.find((item) => item.status == "Won")
          ? elem.odds.find((item) => item.status == "Won").count
          : 0
      );
      data.Returned.push(
        elem.odds.find((item) => item.status == "Returned")
          ? elem.odds.find((item) => item.status == "Returned").count
          : 0
      );
      data.Lost.push(
        elem.odds.find((item) => item.status == "Lost")
          ? elem.odds.find((item) => item.status == "Lost").count
          : 0
      );
    }
    setWonData(data.Won);
    setLostData(data.Lost);
    setReturnData(data.Returned);
    setDataOfXaxies(data.date);
    setPaiChartCount(res.total);
  };
  // 3 month
  const handle3Month = async () => {
    var data = {
      date: [],
      Won: [],
      Returned: [],
      Lost: [],
    };
    setBackGroundColor3Month(true);
    setBackGroundColor1Month(false);
    setBackGroundColor7Days(false);
    setBackGroundColor6Month(false);
    setBackGroundColorYear(false);
    setBackGroundColorAll(false);
    setChartEmptyMsg(t("profile.Notenoughdata3Month"));
    const res = await apiService.getAnalyticsGraph("threemonth", props?.id);
    for (let elem of res.data) {
      data.date.push(moment(elem.date).format("MMMM D"));
      data.Won.push(
        elem.odds.find((item) => item.status == "Won")
          ? elem.odds.find((item) => item.status == "Won").count
          : 0
      );
      data.Returned.push(
        elem.odds.find((item) => item.status == "Returned")
          ? elem.odds.find((item) => item.status == "Returned").count
          : 0
      );
      data.Lost.push(
        elem.odds.find((item) => item.status == "Lost")
          ? elem.odds.find((item) => item.status == "Lost").count
          : 0
      );
    }
    setWonData(data.Won);
    setLostData(data.Lost);
    setReturnData(data.Returned);
    setDataOfXaxies(data.date);
    setPaiChartCount(res.total);
  };
  // 6 Month
  const handle6Month = async () => {
    var data = {
      date: [],
      Won: [],
      Returned: [],
      Lost: [],
    };
    const res = await apiService.getAnalyticsGraph("sixmonth", props?.id);
    for (let elem of res.data) {
      data.date.push(moment(elem.date).format("MMMM D"));
      data.Won.push(
        elem.odds.find((item) => item.status == "Won")
          ? elem.odds.find((item) => item.status == "Won").count
          : 0
      );
      data.Returned.push(
        elem.odds.find((item) => item.status == "Returned")
          ? elem.odds.find((item) => item.status == "Returned").count
          : 0
      );
      data.Lost.push(
        elem.odds.find((item) => item.status == "Lost")
          ? elem.odds.find((item) => item.status == "Lost").count
          : 0
      );
    }
    setWonData(data.Won);
    setLostData(data.Lost);
    setReturnData(data.Returned);
    setDataOfXaxies(data.date);
    setPaiChartCount(res.total);
    setBackGroundColor6Month(true);
    setBackGroundColor7Days(false);
    setBackGroundColor1Month(false);
    setBackGroundColor3Month(false);
    setBackGroundColorYear(false);
    setBackGroundColorAll(false);
    setChartEmptyMsg(t("profile.Notenoughdata6Month"));
  };
  // 1 year
  const handle1Year = async () => {
    var data = {
      date: [],
      Won: [],
      Returned: [],
      Lost: [],
    };
    const res = await apiService.getAnalyticsGraph("year", props?.id);
    for (let elem of res.data) {
      data.date.push(moment(elem.date).format("MMMM D"));
      data.Won.push(
        elem.odds.find((item) => item.status == "Won")
          ? elem.odds.find((item) => item.status == "Won").count
          : 0
      );
      data.Returned.push(
        elem.odds.find((item) => item.status == "Returned")
          ? elem.odds.find((item) => item.status == "Returned").count
          : 0
      );
      data.Lost.push(
        elem.odds.find((item) => item.status == "Lost")
          ? elem.odds.find((item) => item.status == "Lost").count
          : 0
      );
    }
    setWonData(data.Won);
    setLostData(data.Lost);
    setReturnData(data.Returned);
    setDataOfXaxies(data.date);
    setPaiChartCount(res.total);
    setBackGroundColorYear(true);
    setBackGroundColor6Month(false);
    setBackGroundColor7Days(false);
    setBackGroundColor1Month(false);
    setBackGroundColor3Month(false);
    setBackGroundColorAll(false);
    setChartEmptyMsg(t("profile.Notenoughdata1Year"));
  };
  const handleAllYear = async () => {
    var data = {
      date: [],
      Won: [],
      Returned: [],
      Lost: [],
    };
    const res = await apiService.getAnalyticsGraph("all", props?.id);
    for (let elem of res.data) {
      data.date.push(moment(elem.date).format("MMMM D"));
      data.Won.push(
        elem.odds.find((item) => item.status == "Won")
          ? elem.odds.find((item) => item.status == "Won").count
          : 0
      );
      data.Returned.push(
        elem.odds.find((item) => item.status == "Returned")
          ? elem.odds.find((item) => item.status == "Returned").count
          : 0
      );
      data.Lost.push(
        elem.odds.find((item) => item.status == "Lost")
          ? elem.odds.find((item) => item.status == "Lost").count
          : 0
      );
    }
    setWonData(data.Won);
    setLostData(data.Lost);
    setReturnData(data.Returned);
    setDataOfXaxies(data.date);
    setPaiChartCount(res.total);
    setBackGroundColorAll(true);
    setBackGroundColorYear(false);
    setBackGroundColor6Month(false);
    setBackGroundColor7Days(false);
    setBackGroundColor1Month(false);
    setBackGroundColor3Month(false);
    setChartEmptyMsg(t("profile.NotenoughdataAllYear"));
  };
  useEffect(() => {
    if (BackGroundColor7Days) {
      handle7DayChart();
      handleBarChartData();
    }
    handleBalaceData();
  }, [props.id]);
  var myPaiChart;
  var chart;
  var myBarChart;

  useEffect(() => {
    var ctx = document.getElementById("myChart");
    let chartStatus = Chart.getChart("myChart"); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
    chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: dataOfXaxies.length > 1 ? handleXAxiesDateLanguageLocalization(dataOfXaxies) : [1, 2, 3, 4, 5, 6, 7],
        datasets: [
          {
            label: "Win",
            backgroundColor: "#0D393A",
            borderColor: "#0D393A",
            data: dataOfXaxies.length > 1 ? wonData : "",
          },
          {
            label: "Lost",
            backgroundColor: "#D14343",
            borderColor: "#D14343",
            data: dataOfXaxies.length > 1 ? LostData : "",
          },
          {
            label: "Return",
            backgroundColor: "#EBCC4F",
            borderColor: "#EBCC4F",
            data: dataOfXaxies.length > 1 ? returnData : "",
          },
        ],
      },

      // Configuration options go here
      options: {
        pointRadius: 0,
        pointHoverRadius: 3,
        pointHoverBorderWidth: 5,
        pointHitRadius: 10,
        interaction: {
          mode: "index",
          intersect: false,
        },
        scales: {
          x: {
            ticks: {
              padding: 10,
            },
            grid: {
              display: false,
            },
            border: {
              color: "#F2F3F7",
            },
          },
          y: {
            ticks: {
              beginAtZero: true,
              padding: 25,
            },
            gridLines: {
              display: false,
            },
            border: {
              display: false,
            },
            beginAtZero: true,
            steps: 10,
            stepValue: 1,
            suggestedMax: 10,
            suggestedMin: 0,
            grid: {
              color: "#F2F3F7",
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
            position: "nearest",
            external: externalTooltipHandler,
          },
          legend: {
            display: false,
          },
          corsair: {
            dash: [7, 5],
            color:
              dataOfXaxies.length <= 1 ? "FFFFFF" :
                wonData.length > 0 && LostData.length > 0 && returnData.length > 0
                  ? "#0D393A"
                  : "#FFFFFF",
            width: 1.5,
          },
        },
      },
      plugins: [
        {
          id: "corsair",
          afterInit: (chart) => {
            chart.corsair = {
              x: 0,
              y: 0,
            };
          },
          afterEvent: (chart, evt) => {
            const {
              chartArea: { top, bottom, left, right },
            } = chart;
            const {
              event: { x, y },
            } = evt;
            if (x < left || x > right || y < top || y > bottom) {
              chart.corsair = {
                x,
                y,
                draw: false,
              };
              chart.draw();
              return;
            }
            chart.corsair = {
              x,
              y,
              draw: true,
            };
            chart.draw();
          },
          afterDatasetsDraw: (chart, _, opts) => {
            //   console.log("opt:::",opts)
            const {
              ctx,
              chartArea: { top, bottom, left, right },
            } = chart;
            const { x, y, draw } = chart.corsair;
            if (!draw) {
              return;
            }
            ctx.lineWidth = opts.width || 0;
            ctx.setLineDash(opts.dash || []);
            ctx.strokeStyle = opts.color || "black";
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, bottom);
            ctx.lineTo(x, top);
            //   ctx.moveTo(left, y);
            //   ctx.lineTo(right, y);
            ctx.stroke();
            ctx.restore();
          },
        },
      ],
    });
    // console.log("dataOfXaxies.length <= 1", dataOfXaxies.length <= 1)
    // pai chart
    var ctx2 = document.getElementById("myPaiChart").getContext("2d");
    chartStatus = Chart.getChart("myPaiChart"); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
    myPaiChart = new Chart(ctx2, {
      type: "doughnut",
      data: {
        labels: ["Won", "Lost", "Return"],
        datasets: [
          {
            data: [
              paiChartCount.Won ? paiChartCount.Won : 0,
              paiChartCount.Lost ? paiChartCount.Lost : 0,
              paiChartCount.Returned ? paiChartCount.Returned : 0,
              paiChartCount.Pending ? paiChartCount.Pending : 0,
            ],
            backgroundColor: ["#0D393A", "#D14343", "#EBCC4F", "#F2F3F7"],
            borderColor: ["#0D393A", "#D14343", "#EBCC4F", "#F2F3F7"],
            borderRadius: 10,
            spacing: 5,
          },
        ],
      },
      options: {
        cutout: 125,
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: false,
          },
          // title: {
          //     display: true,
          //     text: 'Total predictions',
          //     position: 'top',
          //     align: 'start'
          // },
          legend: {
            position: false,
            // labels: {
            //   generateLabels: function (chart) {
            //     var data = chart.data;
            //     if (data.labels.length && data.datasets.length) {
            //       return data.labels.map(function (label, i) {
            //         var meta = chart.getDatasetMeta(0);
            //         var ds = data.datasets[0];
            //         var arc = meta.data[i];
            //         // console.log(ds)
            //         var fill = ds.backgroundColor[i];
            //         var stroke = ds.borderColor[i];
            //         return {
            //           text: label + ": " + ds.data[i],
            //           fillStyle: fill,
            //           strokeStyle: stroke,
            //           hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
            //           index: i,
            //         };
            //       });
            //     }
            //     return [];
            //   },
            // },
          },
        },
      },
    });
    // bar chart
    var ctx3 = document.getElementById("myBarChart").getContext("2d");
    chartStatus = Chart.getChart("myBarChart"); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
    const yAxiesData = barChartData?.map((item) => {
      return parseFloat(item.totalOdds).toFixed(2);
    });
    const yAxiesColor = barChartData?.map((item) => {
      if (item.statusText == "Lost") {
        return "#FDF4F4";
      } else if (item.statusText == "Won") {
        return "#EDF3F3";
      } else {
        return "#FDFAED";
      }
    });
    const yAxiesBorderColor = barChartData?.map((item) => {
      if (item.statusText == "Lost") {
        return "#D14343";
      } else if (item.statusText == "Won") {
        return "#0D393A";
      } else {
        return "#EBCC4F";
      }
    });
    var xValues = ["", "", "", "", ""];
    var yValues = yAxiesData;
    var barColors = yAxiesColor;
    myBarChart = new Chart(ctx3, {
      type: "bar",
      data: {
        labels: xValues,
        datasets: [
          {
            backgroundColor: barColors,
            data: yValues,
            minBarLength: "40",
            borderWidth: {
              top: 1,
              bottom: 4,
              right: 1,
              left: 1,
            },
            borderRadius: 6,
            borderSkipped: false,
            borderColor: yAxiesBorderColor,
            barPercentage: 1.13,
          },
        ],
      },
      options: {
        responsive: false,
        legend: { display: false },
        title: {
          display: false,
        },
        hover: { mode: null },
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
          datalabels: {
            color: "#000000",
            font: {
              weight: 600,
              size: 14,
              family: "SF UI Display",
            },
          },
        },
        scales: {
          y: {
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }, [dataOfXaxies]);
  return (
    <>
      <div>
        <div className="d-flex justify-content-between chart-summary-div ">
          <div className="chart-summary-sub-div">
            {/* line chart */}
            <div className="line-chart-part">
              <div className="d-flex justify-content-between map-part">
                <div className="d-flex">
                  <div className="myChart-title-part">
                    {t("feed.Detailedstatistics")}{" "}
                  </div>
                  {/* <span className="d-flex align-items-center">
                    <img src="/alert-circle.svg" />
                  </span> */}
                </div>

                <div className="d-flex line-chart-left-panel w-100 justify-content-between">
                  <div className="d-flex chart-prediction-status">
                    <div
                      className="chart-prediction-line d-flex align-items-center"
                    // style={{ marginRight: "30px" }}
                    >
                      <div
                        className="won-border"
                        style={{ marginTop: "15px" }}
                      ></div>
                      <div className="won-title ps-3">
                        {t("chartSummary.titleBarWon")}
                      </div>
                    </div>
                    <div
                      className=" chart-prediction-line d-flex align-items-center"
                    // style={{ marginRight: "30px" }}
                    >
                      <div
                        className="Lost-border"
                        style={{ marginTop: "15px" }}
                      ></div>
                      <div className="Lost-title ps-3">
                        {t("chartSummary.titleBarLost")}
                      </div>
                    </div>
                    <div
                      className=" chart-prediction-line d-flex align-items-center"
                    // style={{ marginRight: "30px" }}
                    >
                      <div
                        className="Return-border"
                        style={{ marginTop: "15px" }}
                      ></div>
                      <div className="Return-title ps-3">
                        {t("chartSummary.titleBarReturns")}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex duration-part">
                    <div
                      className="chart-timer-duration"
                      style={{
                        backgroundColor: BackGroundColor7Days ? "#F2F3F7" : "",
                        borderRadius: "6px",
                      }}
                      onClick={handle7DayChart}
                    >
                      {t("feed.D7")}
                    </div>
                    <div
                      className="chart-timer-duration"
                      style={{
                        backgroundColor: backgroundColor1Month ? "#F2F3F7" : "",
                        borderRadius: "6px",
                      }}
                      onClick={handleOneMonthChart}
                    >
                      {t("feed.m1")}
                    </div>
                    <div
                      className="chart-timer-duration"
                      style={{
                        backgroundColor: backgroundColor3Month ? "#F2F3F7" : "",
                        borderRadius: "6px",
                      }}
                      onClick={handle3Month}
                    >
                      {t("feed.m3")}
                    </div>
                    <div
                      className="chart-timer-duration"
                      style={{
                        backgroundColor: backgroundColor6Month ? "#F2F3F7" : "",
                        borderRadius: "6px",
                      }}
                      onClick={handle6Month}
                    >
                      {t("feed.m6")}
                    </div>
                    <div
                      className="chart-timer-duration"
                      style={{
                        backgroundColor: backgroundColorYear ? "#F2F3F7" : "",
                        borderRadius: "6px",
                      }}
                      onClick={handle1Year}
                    >
                      {t("feed.y1")}
                    </div>
                    <div
                      className="chart-timer-duration"
                      style={{
                        backgroundColor: backgroundColorAll ? "#F2F3F7" : "",
                        borderRadius: "6px",
                      }}
                      onClick={handleAllYear}
                    >
                      {t("feed.All")}
                    </div>
                  </div>
                </div>
              </div>
              {dataOfXaxies.length <= 1 ? (
                <div className="Not-enough-data-div">
                  <div>
                    <div className="Notenoughdata-msg">{chartEmptyMsg}</div>
                    <div className="tryAgainLine-msg">
                      {t("profile.tryAgainLine")}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div>
                <canvas id="myChart"></canvas>
              </div>
            </div>
            {/* top 3 predictionList  */}
            <div className="topPredictionList-div">
              <TopPredictionList id={props.id} />
            </div>
          </div>
          <div className="pai-chart-main">
            <div className="pai-chart-part">
              {/* Balance data */}
              <div className="balance-data-div mb-4">
                <div className="Shape-data-title pe-2">
                  {t("chartSummary.Balance")}
                </div>
                <div className="total-coin-data-chart d-flex align-items-center pt-4">
                  <img
                    src="/wallet-coin/chartSummaryBalance.svg"
                    width={32}
                    height={32}
                    alt=""
                  />
                  <div className="wallet-balance-title ps-2">
                    {balanceData?.totalBalance?.toFixed(2)}
                  </div>
                </div>
                <div className="d-flex justify-content-between pt-3">
                  {/* <div className="chart-summary-collectCoin">
                    <div className="title pb-1">
                      {t("chartSummary.CollectedCoins")}
                    </div>
                    <div className="sub-title">{balanceData?.collectCoin?.toFixed(2)}</div>
                  </div>
                  <div className="chart-summary-collectCoin">
                    <div className="title pb-1"> {t("wallet.EarnedCoins")}</div>
                    <div className="sub-title">{balanceData?.earnCoin?.toFixed(2)}</div>
                  </div> */}
                  {/* <div className="chart-summary-collectCoin">
                    <div className="title pb-1"> {t("wallet.SpentCoins")}</div>
                    <div className="sub-title">{balanceData?.spentCoin}</div>
                  </div> */}
                </div>
              </div>
              {/* Shape data */}
              <div className="Shape-data-div">
                <div className="d-flex align-items-center shape-data-title-icon-div pb-4">
                  <div className="Shape-data-title pe-2">
                    {t("profile.Shapedata")}
                  </div>
                  <div className="alert-circle-tooltip-div">
                    <div className="alert-circle-image-tooltip">
                      {t("profile.alertCircleTooltipMsg")}
                    </div>
                    <span className="alert-circle-image d-flex align-items-center">
                      {/* <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="alert-circle-image-path" fillRule="evenodd" clipRule="evenodd" d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#7F87B3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path className="alert-circle-image-path" d="M9 6V9" stroke="#7F87B3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <circle className="alert-circle-image-circle" cx="9" cy="12" r="0.75" fill="#7F87B3" />
                      </svg> */}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="alert-circle-image-path"
                          d="M17.5 10C17.5 5.85757 14.1424 2.5 10 2.5C5.85757 2.5 2.5 5.85757 2.5 10C2.5 14.1416 5.85757 17.5 10 17.5C14.1424 17.5 17.5 14.1416 17.5 10Z"
                          stroke="#7F87B3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          className="alert-circle-image-path"
                          d="M9.95605 13.5206V13.4971"
                          stroke="#7F87B3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          className="alert-circle-image-path"
                          d="M9.95517 11.458C9.94542 10.7152 10.6208 10.4007 11.1228 10.1144C11.7349 9.77716 12.1493 9.23958 12.1493 8.49441C12.1493 7.39012 11.2565 6.50391 10.1595 6.50391C9.05517 6.50391 8.16895 7.39012 8.16895 8.49441"
                          stroke="#7F87B3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                {/* --------------------- */}
                {/* empty-graph */}
                <div
                  className="emptyData-graph"
                  style={{
                    display: barChartData?.length <= 0 ? "block" : "none",
                  }}
                >
                  <div className="Notenoughdata-msg">
                    {t("profile.Notdatadisplay")}
                  </div>
                  <div className="tryAgainLine-msg">
                    {t("profile.tryAgainLine")}
                  </div>
                </div>
                <div
                  style={{
                    display: barChartData?.length > 0 ? "block" : "none",
                  }}
                >
                  {/* chart */}
                  <div>
                    <canvas id="myBarChart" style={{ width: "100%" }}></canvas>
                  </div>
                  {/* Latest 5 predictions */}
                  <div className="Latest-5-predictions pt-3">
                    <div>{t("profile.Latest5predictions")}</div>
                    <div>{t("profile.Latest")}</div>
                  </div>
                </div>
              </div>
              {/* Average odd */}
              <div className="Average-Odds-div">
                <div className="Average-Odds-title">
                  {t("profile.Averageodd")}
                </div>
                <div className="Average-Odds-count">
                  <img src="/AverageOdd.svg" />
                  <span className="ps-2">{avgPrediction}</span>
                </div>
              </div>
              {/* paichart */}
              <div className="pai-chart-graph">
                <div className="Total-predictions-title">
                  {paiChartCount.total == 0
                    ? t("profile.predictions")
                    : t("feed.Generalstatistics")}
                </div>
                {/* empty-graph */}
                <div
                  className="emptyData-graph"
                  style={{
                    display:
                      paiChartCount.total == 0 &&
                        paiChartCount.Won == 0 &&
                        paiChartCount.Lost == 0 &&
                        paiChartCount.Returned == 0 &&
                        paiChartCount.Pending == 0
                        ? "block"
                        : "none",
                    padding: "35px",
                  }}
                >
                  <div className="Notenoughdata-msg">
                    {t("profile.Notdatadisplay")}
                  </div>
                  <div className="tryAgainLine-msg">
                    {t("profile.tryAgainLine")}
                  </div>
                </div>
                <div
                  className="justify-content-center mobile-paiChartCount-border-div"
                  // style={{
                  //     display: paiChartCount.Pending > 0 ? "flex" : "none",
                  // }}
                  style={{
                    display:
                      paiChartCount.total == 0 &&
                        paiChartCount.Won == 0 &&
                        paiChartCount.Lost == 0 &&
                        paiChartCount.Returned == 0
                        ? "none"
                        : "flex",
                  }}
                >
                  <div className="padding-pai-chart-div">
                    <div className="position-absolute text-center pending-paichart-center-div">
                      <div className="pai-chart-score">
                        {paiChartCount.total}
                      </div>
                      <div className=" pai-chart-title">
                        {t("profile.predictions")}
                      </div>
                    </div>
                    <canvas
                      id="myPaiChart"
                      className="padding-pai-chart"
                    ></canvas>
                  </div>
                  <div className="profit-border-panel-list">
                    <div className="won-border-div">
                      <div className="won-border pai-chart-border"></div>
                      <div className="mb-3 won-title-main-div">
                        <div className="won-title paichart-won-title">
                          {t("feed.win")}
                        </div>
                        <div className="paichart-score">
                          {paiChartCount.Won}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="Lost-border-div">
                        <div className="Lost-border pai-chart-border"></div>
                        <div className="mb-3 Lost-title-main-div">
                          <div className="Lost-title paichart-won-title">
                            {t("chartSummary.tooltipLosses")}
                          </div>
                          <div className="paichart-score">
                            {paiChartCount.Lost}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="Return-border-div">
                        <div className="Return-border pai-chart-border"></div>
                        <div className="mb-3 Return-title-main-div">
                          <div className="Return-title paichart-won-title">
                            {t("chartSummary.tooltipReturns")}
                          </div>
                          <div className="paichart-score">
                            {paiChartCount.Returned}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="Pending-border-div">
                        <div className="Pending-border pai-chart-border"></div>
                        <div className="Pending-title-main-div">
                          <div className="Pending-title paichart-won-title">
                            {t("feed.statusPending")}
                          </div>
                          <div className="paichart-score">
                            {paiChartCount.Pending}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="paiChart-side-border-list">
                    <div className="pai-chart-1div">
                      <div className="pai-chart-menu pe-3">
                        <div className="won-border pai-chart-border"></div>
                        <div className="">
                          <div className="won-title paichart-won-title">
                            {t("feed.win")}
                          </div>
                          <div className="paichart-score">
                            {paiChartCount.Won}
                          </div>
                        </div>
                      </div>
                      <div className="pai-chart-menu">
                        <div className="Return-border pai-chart-border"></div>
                        <div className="">
                          <div className="Return-title paichart-won-title">
                            {t("feed.statusReturn")}
                          </div>
                          <div className="paichart-score">
                            {paiChartCount.Returned}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pai-chart-2div">
                      <div className="pai-chart-menu">
                        <div className="Lost-border pai-chart-border"></div>
                        <div className="">
                          <div className="Lost-title paichart-won-title">
                            {t("feed.statusLost")}
                          </div>
                          <div className="paichart-score">
                            {paiChartCount.Lost}
                          </div>
                        </div>
                      </div>
                      <div className="pai-chart-menu Pending-border-pai-chart-menu">
                        <div className="Pending-border pai-chart-border"></div>
                        <div className="">
                          <div className="Pending-title paichart-won-title">
                            {t("feed.statusPending")}
                          </div>
                          <div className="paichart-score">
                            {paiChartCount.Pending}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* tooltip */}
        <div className="custom-tooltip">
          <div className="tooltip-title-date pb-1">September 3</div>
          <div className="tooltip-status d-flex justify-content-between">
            <div className="win-data pe-2">
              <div className="windata-title pb-1">{t("feed.win")}</div>
              <div className="windata-count"></div>
            </div>
            <div className="lost-data pe-2">
              <div className="lostdata-title pb-1">{t("chartSummary.tooltipLosses")}</div>
              <div className="lostdata-count"></div>
            </div>
            <div className="return-data ">
              <div className="returndata-title pb-1">{t("chartSummary.tooltipReturns")}</div>
              <div className="returndata-count"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChartSummary;
