import moment from "moment";
import "moment/locale/uk";
import "moment/locale/en-gb";

export const formatPrice = (num, isTitle) => {
  const floatNum = parseFloat(num);
  if (floatNum) {
    let symbol = "";
    let number = parseFloat(floatNum);
    if (floatNum >= 10000 && floatNum < 1000000) {
      number = Math.floor(floatNum / 100) / 10;
      symbol = "K";
    } else if (floatNum >= 1000000) {
      number = Math.floor(floatNum / 100000) / 10;
      symbol = "M";
    } else {
      if (isTitle) {
        number = floatNum.toFixed(2);
      } else {
        number = floatNum.toFixed(0);
      }
    }

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + symbol;
  }
  return 0;
};

export const getThousandsNumber = (number, lang) => {
  let prefix = "";
  let result = 0;
  if (number >= 1000) {
    result = Math.floor(number / 1000);
    prefix = lang === "ua" ? " тис." : "k";
  } else {
    result = Math.floor(number / 100) * 100;
  }

  return result + prefix;
};

export const getHoudredNumber = (number, lang) => {
  let prefix = "";
  let result = 0;
  if (number >= 1000) {
    result = Math.floor((number / 1000) * 10) / 10;
    prefix = lang === "ua" ? " тис." : "k";
  } else {
    result = Math.floor(number / 100) * 100;
  }

  return result + prefix;
};

export const prepareFullName = (userInfo) => {
  let fullName = "";
  if (userInfo) {
    if (userInfo.firstName) {
      const fisrtName = userInfo.firstName.toLowerCase();
      fullName += fisrtName.charAt(0).toUpperCase() + fisrtName.slice(1);
    }

    if (userInfo.lastName) {
      const lastName = userInfo.lastName.toLowerCase();
      fullName += " " + lastName.charAt(0).toUpperCase() + lastName.slice(1);
    }

    if (fullName === "" && userInfo.email) {
      return userInfo.email;
    }
  }

  return fullName;
};

export const prepareUserName = (userInfo) => {
  if (!userInfo) return "Unknown User";

  if (userInfo.firstName && userInfo.lastName) {
    return `${userInfo.firstName} ${userInfo.lastName}`;
  }

  return (
    userInfo.firstName || userInfo.lastName || userInfo.userName || userInfo.id
  );
};

export const formatTeamLinkIcon = (url) => {
  if (url) {
    let link = url;

    if (!url.endsWith(".png")) {
      link = `${url}.png`;
    }

    if (url.endsWith(".png.png")) {
      link = url.slice(0, -4);
    }

    return process.env.REACT_APP_teamLogo + link;
  }
};

export const formatDate = (timestamp, lang) => {
  if (lang === "ua") {
    lang = "uk";
  }

  moment.locale(lang);

  const date = moment.unix(timestamp);
  let formattedDate = date.format("D MMMM, HH:mm");

  return formattedDate;
};

export const formatNumber = (num, digits = 2) => {
  if (num) {
    const rounded = num.toFixed(digits);
    return Number.isInteger(num) ? rounded.split(".")[0] : rounded;
  }
};

export const preparePredictionLink = (prediction) => {
  const lang = localStorage.getItem("language");
  const linkId = prediction.sgame_id || prediction.game_id;
  return `/${lang === "en" ? "en/" : ""}predictions/${linkId}`;
};
