import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { format, isToday } from "date-fns";
import { uk, enUS } from "date-fns/locale";

import ToolTip from "../../ToolTip/ToolTip";

const ChatMessage = ({ msg }) => {
  const { t, i18n } = useTranslation();

  const [truncatedText, setTruncatedText] = useState('');

  const lang = localStorage.getItem("language") === "en" ? enUS : uk;

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const textRef = useRef(null);

  const [showFullText, setShowFullText] = useState(false);
  const [isOverLines, setIsOverLines] = useState(false);

  // useEffect(() => {
  //   if (textRef.current) {
  //     const lineHeight = parseInt(
  //       window.getComputedStyle(textRef.current).lineHeight
  //     );

  //     const lines = isMobile ? 5 : 4  ;
  //     const maxHeight = lineHeight * lines;

  //     if (textRef.current.scrollHeight > maxHeight) {
  //       setIsOverLines(true);
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   const div = textRef.current;
    
  //   // Отримуємо висоту одного рядка тексту
  //   const lineHeight = parseInt(window.getComputedStyle(div).lineHeight, 10);
  //   const maxHeight = lineHeight * 3.5; // висота для 3.5 рядка
  //   let truncated = '';

  //   // Якщо висота більше ніж maxHeight, обрізаємо текст
  //   if (div.scrollHeight > maxHeight) {
  //     const words = msg.message.split(' ');
  //     let currentText = '';

  //     // Додаємо слова поки не досягнемо обмеження висоти
  //     for (let i = 0; i < words.length; i++) {
  //       currentText += words[i] + ' ';
  //       div.innerText = currentText;

  //       // Якщо висота контенту перевищує maxHeight, додаємо "..." і виходимо
  //       if (div.scrollHeight > maxHeight) {
  //         setTruncatedText(currentText.trim() + '...');
  //         break;
  //       }
  //     }
  //   } else {
  //     // Якщо текст вміщується без обрізки
  //     setTruncatedText(msg.message);
  //   }
  // }, [msg.message]);

  const formatEditDate = () => {
    return isToday(new Date(msg.editDate))
      ? lang === enUS
        ? `Today at ${format(new Date(msg.editDate), "HH:mm", {
            locale: lang,
          })}`
        : `Сьогодні о ${format(new Date(msg.editDate), "HH:mm", {
            locale: lang,
          })}`
      : lang === enUS
      ? `${format(new Date(msg.editDate), "d MMMM", {
          locale: lang,
        })} at ${format(new Date(msg.editDate), "HH:mm", { locale: lang })}`
      : `${format(new Date(msg.editDate), "d MMMM", {
          locale: lang,
        })} о ${format(new Date(msg.editDate), "HH:mm", { locale: lang })}`;
  };

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div className="message__msg-text">
      <div
        ref={textRef}
        // className={`read-more-less_text ${
        //   showFullText ? "read-more-less_full-text" : ""
        // }`}
      >
        {msg.message}{" "}
        {msg.isEdited && (
          <ToolTip text={formatEditDate()}>
            <span className="message__msg-edited">({t("chat.edited")})</span>
          </ToolTip>
        )}
      </div>
      {!showFullText && isOverLines && (
        <button className="read-more-less_btn" onClick={toggleFullText}>
          {t("readMore")}
        </button>
      )}
    </div>
  );
};

export default ChatMessage;
