import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { getEvent, getLeagues, getSportEvents } from "../../../store/betSlice";
import { setPredictions } from "../../../store/betSlice";
import { STATUS_COMPLETED, STATUS_LOADING } from "../../../consts/StatusConst";

import moment from "moment";

import { Tab, Tabs } from "react-bootstrap";
import GamePage from "./GamePage";
import OddItem from "./OddItem";
import BreadCrumbs from "../BreadCrumbs";
import TournamentTabLoader from "../../CustomSkeletonLoaders/TournamentTabLoader";
import TournamentTabLoaderMobile from "../../CustomSkeletonLoaders/TournamentTabLoaderMobile";
import CountriesSideBar from "../CountriesSideBar";
import { formatTeamLinkIcon } from "../../../helpers/formater";

const TournamentTab = ({
  handleOpenPredictionBetSlip,
  setIsToTopBtnVisible,
  setCouponVisible,
  setShowMobilePredictionNotification,
}) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const isSmallDesktop = useMediaQuery({ maxWidth: 1280 });
  const listInnerRef = useRef();
  const listRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams({});
  const [tournaments, setTournaments] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const {
    sportEvents,
    event,
    leagues,
    sportEventsStatus,
    eventLoadingStatus,
    leaguesLoadingStatus,
    lang,
  } = useSelector((state) => state.bet);
  const dispatch = useDispatch();

  const activeSport = searchParams.get("sport");
  const activeSportKey = searchParams.get("key");
  const activeCountry = searchParams.get("country");
  const activeLeague = searchParams.get("league");
  const activeGame = searchParams.get("game");

  useEffect(() => {
    setDataLoaded(false);

    if (
      sportEventsStatus === STATUS_COMPLETED &&
      leaguesLoadingStatus === STATUS_COMPLETED
    ) {
      setTournaments(sportEvents);
      updatePredictions(sportEvents);
      setDataLoaded(true);
    }
  }, [dispatch, sportEventsStatus, leaguesLoadingStatus]);

  useEffect(() => {
    if (activeGame) {
      dispatch(
        getEvent({
          eventId: activeGame,
          activeSportKey,
        })
      );
    }
  }, [dispatch, activeGame, lang]);

  useEffect(() => {
    dispatch(setPredictions(getPredictionsFromStorage()));
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        getLeagues({
          activeSport,
          activeSportKey,
        })
      );
      dispatch(getSportEvents({ activeSport, activeLeague, activeSportKey }));

      const newData = compareAndUpdateOcRate(tournaments, sportEvents);
      setTournaments(newData);
    }, 30000);

    return () => clearInterval(interval);
  }, [
    dispatch,
    activeSport,
    activeSportKey,
    activeLeague,
    activeGame,
    sportEvents,
  ]);

  const handleEventListScroll = (e) => {
    const scrolledDistance = e.target.scrollTop;

    if (scrolledDistance > 150) {
      setIsToTopBtnVisible(true);
      if (isSmallDesktop) {
        setCouponVisible(true);
      }
    } else {
      setIsToTopBtnVisible(false);
      if (isSmallDesktop) {
        setCouponVisible(false);
      }
    }
  };

  const getPredictionsFromStorage = () => {
    const storedData = localStorage.getItem("createPrediction");
    if (storedData) {
      return JSON.parse(storedData);
    }
    return [];
  };

  const updatePredictions = (tournamentsData) => {
    const predictionsData = getPredictionsFromStorage();
    if (predictionsData.length > 0 && tournamentsData.length > 0) {
      predictionsData.map((prediction) => {
        for (let i = 0; i < tournamentsData.length; i++) {
          if (tournamentsData[i].events_list) {
            const event = tournamentsData[i].events_list.find(
              (event) => event.game_id === prediction.game_id
            );
            if (event) {
              const pointer = event.game_oc_list.find(
                (oc) => oc.oc_pointer === prediction.oc_pointer
              );
              if (pointer) {
                prediction.change = pointer.change || 0;
                prediction.oc_rate = pointer.oc_rate;
              }
            }
          }
        }
      });
    }

    dispatch(setPredictions(predictionsData));
    localStorage.setItem("createPrediction", JSON.stringify(predictionsData));
  };

  const compareAndUpdateOcRate = (oldArray, newArray) => {
    let modifiedArray = JSON.parse(JSON.stringify(newArray));
    // console.log("start comapre old and new data");

    modifiedArray.forEach((newTournament) => {
      let oldTournament = oldArray.find(
        (t) => t.tournament_id === newTournament.tournament_id
      );
      if (oldTournament) {
        newTournament.events_list.forEach((newEvent) => {
          let oldEvent = oldTournament.events_list.find(
            (e) => e.game_id === newEvent.game_id
          );
          if (oldEvent) {
            newEvent.game_oc_list.forEach((newOc) => {
              let oldOc = oldEvent.game_oc_list.find(
                (oc) => oc.oc_pointer === newOc.oc_pointer
              );
              if (oldOc) {
                newOc.change =
                  newOc.oc_rate > oldOc.oc_rate
                    ? 1
                    : newOc.oc_rate < oldOc.oc_rate
                    ? -1
                    : 0;
              }
            });
          }
        });
      }
    });
    // console.log("finish comapre old and new data");
    return modifiedArray;
  };

  const handleExtraMatchData = (game) => {
    setSearchParams(
      (prev) => {
        prev.set("sport", game.sport_id);
        prev.set("country", game.country_id);
        prev.set("league", game.tournament_id);
        prev.set("game", game.game_id);
        return prev;
      },
      { replace: true }
    );
  };

  const renderOddsData = (event) => {
    const elDesktop = (i) => {
      return (
        <td key={i} className="leaue-score" style={{ pointerEvents: "none" }}>
          <div className="champions-leaue-score">-</div>
        </td>
      );
    };

    const elMobile = (i) => {
      return (
        <div key={i} className="leaue-score" style={{ pointerEvents: "none" }}>
          <div className="champions-leaue-score">-</div>
        </div>
      );
    };

    let arrEl = [];
    event?.game_oc_list.map((item, i) => {
      let pt = item?.oc_pointer.split("|");
      if (pt[1] === "1" && pt[2] === "1") {
        arrEl.push({
          seq: 1,
          data: <OddItem odd={item} event={event} seq={1} />,
        });
      }
      if (pt[1] === "1" && pt[2] === "2") {
        arrEl.push({
          seq: 2,
          data: <OddItem odd={item} event={event} seq={2} />,
        });
      }
      if (pt[1] === "1" && pt[2] === "3") {
        arrEl.push({
          seq: 3,
          data: <OddItem odd={item} event={event} seq={3} />,
        });
      }
    });

    if (arrEl.length < 1) {
      if (activeSportKey === "live") {
        for (let i = 1; i <= 3; i++) {
          arrEl.push({
            seq: i,
            data: isMobile ? elMobile(i) : elDesktop(i),
          });
        }
      } else {
        for (let i = 1; i <= 3; i++) {
          arrEl.push({
            seq: i,
            data: isMobile ? elMobile(i) : elDesktop(i),
          });
        }
      }
    } else {
      if (arrEl.length !== 3) {
        const availableSeq = arrEl.map((i) => i.seq);
        for (let i = 1; i <= 3; i++) {
          if (!availableSeq.includes(i)) {
            arrEl.push({
              seq: i,
              data: isMobile ? elMobile(i) : elDesktop(i),
            });
          }
        }
      }
    }

    arrEl = arrEl.sort((a, b) => {
      return a.seq - b.seq;
    });
    return arrEl;
  };

  const renderTournamentGrid = () => {
    return (
      <>
        {tournaments.length === 0 && dataLoaded && (
          <div className="empty-data-error-message">
            <div className="ThereAreNoMatches-title">
              {t("feed.ThereAreNoMatches")}
            </div>
            <div className="tournamentOrDay-title">
              {t("feed.tournamentOrDay")}
            </div>
          </div>
        )}
        {tournaments.length > 0 && (
          <div className="">
            <div className="fullscreen-addPredictionTable">
              <div
                className="all-tab-scroll"
                id="all-tab-scroll"
                ref={listInnerRef}
              >
                <div className="champions-league-table">
                  {tournaments.map((item, index) => (
                    <table width="100%" key={item.id}>
                      <colgroup>
                        <col span="1" style={{ width: "40%" }} />
                        <col span="1" style={{ width: "47px" }} />
                        <col span="1" style={{ width: "47px" }} />
                        <col span="1" style={{ width: "47px" }} />
                        <col span="1" style={{ width: "47px" }} />
                        <col span="1" style={{ width: "47px" }} />
                        <col span="1" style={{ width: "47px" }} />
                        <col span="1" style={{ width: "47px" }} />
                      </colgroup>
                      {item?.events_list?.length > 0 ? (
                        <thead>
                          <tr className="champions-table-Header">
                            <th className="champions-leaue-country-name">
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    "country-logo/" +
                                    item.events_list[0].country_id +
                                    ".png"
                                  }
                                  className="pe-2"
                                  alt=""
                                ></img>
                                {localStorage.getItem("language") === "ua"
                                  ? item.tournament_name
                                  : item?.events_list[0]?.tournament_name_en}
                              </div>
                            </th>
                            <th>
                              <div>1</div>
                            </th>
                            <th>
                              <div>X</div>
                            </th>
                            <th>
                              <div>2</div>
                            </th>

                            <th className="champions-add-btn">+</th>
                          </tr>
                        </thead>
                      ) : (
                        ""
                      )}
                      <tbody className="champions-leaue-score-body">
                        {renderTableRow(item)}
                      </tbody>
                    </table>
                  ))}
                </div>
              </div>
            </div>

            <div className="show-boTTom-message my-5">
              {t("feed.showMessage")}
            </div>

            <div className="bottom-text-section">
              <h1>{t("sportLine.bottomTextTitle")}</h1>
              <p>{t("sportLine.bottomTextDescription")}</p>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderTableRow = (tournament) => {
    return tournament?.events_list?.map((event) => {
      return (
        <tr
          key={event.game_id}
          className="leauge-alldata-name-score"
          onClick={() => handleExtraMatchData(event)}
        >
          <td className="leaue-score champions-leaue-first-score champions-leaue-score-name">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex champions-league-detail w-100">
                {activeSportKey === "live" ? (
                  <div className="live-champions-leaue-score-minute d-flex align-items-center pe-4">
                    {event.game_desk === "Half time" ? (
                      <>{t("half_time")}</>
                    ) : (
                      <>
                        {Math.floor(event?.timer / 60)}
                        <span className="blinking-animation">'</span>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="champions-leaue-score-date-time">
                    <div className="champions-leaue-score-date">
                      {moment.unix(event?.game_start).format("DD/MM")}
                    </div>
                    <div className="champions-leaue-score-timer">
                      {moment.unix(event?.game_start).format("HH:mm")}
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="logo-leaueName-containt">
                    <div className="champions-leaue-name champions-top d-flex">
                      <img
                        src={formatTeamLinkIcon(event?.opp_1_icon)}
                        className="champions-leaue-logo"
                        alt=""
                      />
                      <div>
                        {localStorage.getItem("language") === "en"
                          ? event?.opp_1_name_en
                          : event?.opp_1_name}
                      </div>
                    </div>
                    <div className="champions-leaue-name d-flex">
                      <img
                         src={formatTeamLinkIcon(event?.opp_2_icon)}
                        className="champions-leaue-logo"
                        alt=""
                      />
                      <div>
                        {localStorage.getItem("language") === "en"
                          ? event?.opp_2_name_en
                          : event?.opp_2_name}
                      </div>
                    </div>
                  </div>
                  {activeSportKey === "live" && (
                    <div className="d-flex justify-content-end  live-tournament-score flex-wrap">
                      <div className="liveScore-selected">
                        {event.score_full}
                      </div>
                      <div className="liveScore-non-selected">
                        {`[` + event.score_period + `]`}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </td>
          {renderOddsData(event).map((i) => {
            return i.data;
          })}
          <td className="leaue-score champions-leaue-last-score">
            <div className="champions-leaue-score last-score-box">
              <div>{t("feed.More")}</div>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderMobileTableRow = (tournament) => {
    return tournament?.events_list?.map((event, index) => (
      <div className="champions-table-containts" key={index}>
        <div className="champions-leaue-score-date-time ">
          {activeSportKey === "live" ? (
            <div className="champions-leaue-score-date live-champions-time pb-3">
              {event.game_desk === "Half time" ? (
                <>{t("half_time")}</>
              ) : (
                <>
                  {Math.floor(event?.timer / 60)}
                  <span className="blinking-animation">'</span>
                </>
              )}
            </div>
          ) : (
            <div className="champions-leaue-score-date pb-3">
              {moment.unix(event?.game_start).format("DD/MM/YYYY")}
              <span className="px-2">|</span>{" "}
              {moment.unix(event?.game_start).format("HH:mm")}
            </div>
          )}
        </div>
        <div
          className="d-flex champions-league-detail w-100"
          style={{ maxWidth: "100%" }}
        >
          <div className="logo-leaueName-containt">
            <div className="champions-leaue-name champions-top">
              <img
                 src={formatTeamLinkIcon(event?.opp_1_icon)}
                alt=""
                className="champions-leaue-logo"
              />
              {localStorage.getItem("language") === "ua"
                ? event?.opp_1_name
                : event?.opp_1_name_en}
            </div>
            <div className="champions-leaue-name">
              <img
                src={formatTeamLinkIcon(event?.opp_2_icon)}
                className="champions-leaue-logo"
                alt=""
              />
              {localStorage.getItem("language") === "ua"
                ? event?.opp_2_name
                : event?.opp_2_name_en}
            </div>
          </div>
          {activeSportKey === "live" && (
            <div className=" d-flex justify-content-end  live-tournament-score flex-wrap pe-3">
              <div className="liveScore-selected">{event?.score_full}</div>
              <div className="liveScore-non-selected">
                {`[` + event?.score_period + `]`}
              </div>
            </div>
          )}
        </div>
        <div className="leaue-score leaue-score-test d-flex justify-content-between">
          {renderOddsData(event).map((i) => {
            return i.data;
          })}
          <div
            className="leaue-score-box champions-leaue-last-score"
            onClick={() => handleExtraMatchData(event)}
          >
            <div className="champions-leaue-score last-score-box">
              <div>{t("feed.More")}</div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const renderMobileTournamentGrid = () => {
    return (
      <div className="smallScreen-addPredictionTable">
        <div className="all-tab-scroll">
          {tournaments.length === 0 && dataLoaded && (
            <div className="empty-data-error-message">
              <div className="ThereAreNoMatches-title">
                {t("feed.ThereAreNoMatches")}
              </div>
              <div className="tournamentOrDay-title">
                {t("feed.tournamentOrDay")}
              </div>
            </div>
          )}
          <div className="champions-league-table">
            {tournaments?.map((item, index) => (
              <div className="w-100" key={index}>
                {item?.events_list?.length > 0 ? (
                  <div>
                    <div className="champions-table-Header home-page-header">
                      <div className="champions-leaue-country-name">
                        <div className="d-flex text-start">
                          <span>
                            <img
                              src={
                                "country-logo/" +
                                item?.events_list[0].country_id +
                                ".png"
                              }
                              alt=""
                            ></img>
                          </span>
                          {localStorage.getItem("language") === "ua"
                            ? item?.tournament_name
                            : item?.events_list[0].tournament_name_en}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {renderMobileTableRow(item)}
              </div>
            ))}

            <div className="show-boTTom-message my-5">
              {t("feed.showMessage")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <>
        {activeGame ? (
          <GamePage
            setShowMobilePredictionNotification={
              setShowMobilePredictionNotification
            }
          />
        ) : sportEventsStatus === STATUS_LOADING ||
          dataLoaded === false ||
          leaguesLoadingStatus === STATUS_LOADING ? (
          <TournamentTabLoaderMobile />
        ) : (
          renderMobileTournamentGrid()
        )}
      </>
    );
  };

  const renderDesktop = () => {
    return (
      <>
        {activeGame ? (
          <GamePage
            setShowMobilePredictionNotification={
              setShowMobilePredictionNotification
            }
          />
        ) : sportEventsStatus === STATUS_LOADING ||
          dataLoaded === false ||
          leaguesLoadingStatus === STATUS_LOADING ? (
          <div className="tournament-loader">
            <TournamentTabLoader />
          </div>
        ) : (
          renderTournamentGrid()
        )}
      </>
    );
  };

  const renderSmallPredictionNotification = () => {
    return (
      <div className="notification-badge-position">
        <div
          className="notification-part"
          onClick={handleOpenPredictionBetSlip}
        >
          <img src="/ticket.svg" className="" alt="" />
          <span className="button__badge">
            {JSON.parse(localStorage.getItem("createPrediction"))?.length || 0}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className="col-lg-9 col-sm-12 tournamentname-tab-col"
      id="tournament-tab"
    >
      <div
        className={`addPrediction-tab`}
        id="addPrediction-tab"
        onScroll={handleEventListScroll}
      >
        {/* {isSmallDesktop && !isTablet && renderSmallPredictionNotification()} */}
        {isTablet && (
          <div className="counties-tablet-sidebar">
            <CountriesSideBar />
            {!isMobile && renderSmallPredictionNotification()}
          </div>
        )}
        <div className="tournament-tabs" id="tournament-tabs" ref={listRef}>
          {isMobile ? renderMobile() : renderDesktop()}
        </div>
      </div>
    </div>
  );
};

export default TournamentTab;
