import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import NavigationBar from "../../../Navbar";
import HomePageHeader from "../HomePageHeader/HomePageHeader";
import { useTranslation } from "react-i18next";
import { PredictionType } from "../../../../json/allData";
import FeedSideBar from "../../feed-sidebar/FeedSideBar";
import SearchBar from "../../searchBar/SearchBar";
import InputButton from "../../InputButton";
import { Accordion } from "react-bootstrap";
import PredictionList from "../../prediction-list/PredictionList";
import RangeSlider from "../../rangeSlider/RangeSlider";
import { useRef } from "react";
import apiService from "../../../../services/apiService";
import "./homePagePreiction.css";
import Model from "../../model/Model";
import { CONSTANT } from "../../../../common/constant";
import { eventBus } from "../../../../services/events";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import HomePageFooter from "../HomePageFooter/HomePageFooter";
import Ad from "../../../General/Ad";
import Ad2 from "../../../General/Ad2";
import _ from "lodash";
import PredictionsListLoader from "../../../CustomSkeletonLoaders/PredictionsListLoader";
import MobilePredictionListLoader from "../../../Custom loader/MobilePredictionListLoader";
import { getThousandsNumber } from "../../../../helpers/formater";
import PredictionListCustomLoader from "../../../Custom loader/PredictionListCustomLoader";
import NoPredictionList from "../../prediction-list/NoPredictionList";

const HomePagePrediction = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const pageSize = 10;

  const [currPage, setCurrPage] = useState(0);
  const [pageState, setPageState] = useState(0);
  const [showPredictionLoader, setShowPredictionLoader] = useState(true);
  const [showLoadMoreLoader, setShowLoadMoreLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [filteredName, setFileteredName] = useState(t("feed.Newest"));
  const [dropDownHideShow, setDropDownHideShow] = useState(false);
  const [manageFilter, setManageFilter] = useState(false);
  const [filterBtnActive, setFilterBtnActive] = useState("filter-btn");
  const menuRef = useRef(null);
  const [sportShow, setSportShow] = useState(false);
  const [filterSportBtn, setFilterSportBtn] = useState(false);
  const [sportData, setSportData] = useState([]);
  const [sportEmptyMsg, setSportEmptyMsg] = useState(false);
  const [tournamentEmptyMsg, setTournamentEmptyMsg] = useState(false);
  const [filterSportData, setFilterSportData] = useState({});
  const [tournamentShow, setTournamentShow] = useState(false);
  const [popularLeagues, setPopularLeagues] = useState([]);
  const [countryLeaguesData, setCountryLeaguesData] = useState([]);
  const [filterTournamentData, setTournamentSportData] = useState("");
  const [filterTournamentBtn, setFilterTournamentSportBtn] = useState(false);
  const [PredictionTypeShow, setPredictionTypeShow] = useState(false);
  // const [predictionData, setPredictionData] = useState({})
  const [filterPredictionTypeData, setFilterPredictionTypeData] = useState([]);
  const [selectedPredictionType, setSelectedPredictionType] = useState("");
  const [filterPredictionTypeBtn, setFilterPredictionTypeSportBtn] =
    useState(false);
  const [RecomendedBtn, setRecomendedBtn] = useState(
    "Recomended-following-sidebar-btn-active"
  );
  const [followingBtn, setFollowingBtn] = useState(
    "Recomended-following-sidebar-btn"
  );
  const [statusShow, setStatusShow] = useState(false);
  const [OddsRange, setOddsRange] = useState(false);
  const [filterOddsRangeBtn, setFilterOddsRangeSportBtn] = useState(false);
  const [listening, setListening] = useState(false);
  const [betsData, setBetsData] = useState([]);
  const [countData, setCountData] = useState(0);
  const [sliderMinRange, setSliderMinRange] = useState(1.05);
  const [sliderMaxRange, setSliderMaxRange] = useState(20.05);
  const [alertMsg, setAlertMsg] = useState(false);
  const [typeAlertMsg, setTypeAlertMsg] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [popularLeague, setPopularLeague] = useState("");
  const [recommandBtnBorder, setRecomendedBtnBorder] = useState("#131520");
  const [followingBtnBorder, setFollowingBtnBorder] = useState("#7F87B3");
  const location = useLocation();
  const [handleHomeSport, setHandleHomeSport] = useState({});
  const [totalBetsData, setTotalBetsData] = useState("");

  const filterHandle = (data) => {
    setPopularLeague(data);
    if (data) {
      if (data) {
        setManageFilter(true);
        setFilterSportBtn(true);
        setFilterTournamentSportBtn(true);
        setFilterBtnActive("filter-btn filter-btn-active");
      } else {
        setFilterSportBtn(false);
        setFilterTournamentSportBtn(false);
      }
      setFilterData((s) => {
        return {
          ...s,
          sport_id: data.sport_id,
          tournament_id: data.tournament_id,
        };
      });
      increamentUserCount();
    }
  };
  const HandleHomePageSportFilter = () => {
    if (location.state?.data) {
      if (Object.keys(location.state?.data).length >= 1) {
        setHandleHomeSport(location.state?.data);
        if (location.state?.data) {
          if (location.state?.data) {
            setManageFilter(true);
            setFilterSportBtn(true);
            setFilterBtnActive("filter-btn filter-btn-active");
          } else {
            setFilterSportBtn(false);
            setFilterTournamentSportBtn(false);
          }
          setCurrPage(0);
          setFilterData((s) => {
            return { ...s, sport_id: location.state?.data.id };
          });
          // increamentUserCount()
        }
      }
    }
  };
  useEffect(() => {
    filterHandle();
    HandleHomePageSportFilter();
  }, []);

  const increamentUserCount = () => {
    setCountData(countData + 1);
  };

  function listenForOutsideClicks() {
    return () => {
      if (listening) return;
      if (!menuRef.current) return;
      setListening(true);
      ["mousedown"].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          if (menuRef?.current?.contains(evt.target)) {
          } else {
            setDropDownHideShow(false);
          }
        });
      });
    };
  }
  useEffect(listenForOutsideClicks(), [dropDownHideShow]);
  useEffect(() => {
    document.body.classList.add("feed-containt");
  }, []);

  const handleFilterDropDown = () => {
    if (dropDownHideShow) {
      setDropDownHideShow(false);
    } else {
      setDropDownHideShow(true);
    }
  };

  const handleFilterData = async (name) => {
    setCurrPage(0);
    console.log(name);
    setFilterData((s) => {
      return { ...s, sortBy: name };
    });
    setFileteredName(name);
    if (dropDownHideShow) {
      setDropDownHideShow(false);
    } else {
      setDropDownHideShow(true);
    }
    increamentUserCount();
  };

  const handleFilters = () => {
    if (manageFilter) {
      setManageFilter(false);
      setFilterBtnActive("filter-btn");
      setSportShow(false);
      setTournamentShow(false);
      setPredictionTypeShow(false);
      setStatusShow(false);
      setOddsRange(false);
    } else {
      setManageFilter(true);
      setFilterBtnActive("filter-btn filter-btn-active");
    }
  };
  // filter sport
  const handleFliterSport = async () => {
    if (sportShow) {
      setSportShow(false);
    } else {
      setSportShow(true);
      setTournamentShow(false);
      setPredictionTypeShow(false);
      setStatusShow(false);
      setOddsRange(false);
      const res = await apiService.getAllSports({});
      setSportData(res);
    }
    // increamentUserCount()
  };
  const handleSportSearch = async (e) => {
    const res = await apiService.getAllSports({
      search: e.target.value,
    });
    setSportData(res);
    if (res.length < 1 && e.target.value != "") {
      setSportEmptyMsg(true);
    } else {
      setSportEmptyMsg(false);
    }
  };
  const handleSelectedSport = async (data) => {
    setSportShow(false);
    setFilterSportBtn(true);
    setFilterSportData(data);
    setFilterData((s) => {
      return { ...s, sport_id: data.id };
    });
    increamentUserCount();
  };
  const handleSportHideShow = async () => {
    setFilterSportBtn(false);
    setFilterData((s) => {
      if (s.sport_id) {
        delete s.sport_id;
      }
      return s;
    });
    handleTournamentHideShow();
    handlePredictionTypeHideShow();
    setCountryLeaguesData([]);
    setPopularLeagues([]);
    setFilterPredictionTypeData([]);
    setFilterSportData("");
    setTypeAlertMsg(true);
    setAlertMsg(true);
    increamentUserCount();
    setPopularLeague("");
    setHandleHomeSport({});
  };
  // filter tournament
  const handleFliterTournament = async () => {
    if (tournamentShow) {
      setTournamentShow(false);
    } else {
      // console.log("filterSportData:::::",filterSportData)
      setTournamentShow(true);
      setSportShow(false);
      setPredictionTypeShow(false);
      setStatusShow(false);
      setOddsRange(false);
      if (filterSportData && Object.keys(filterSportData).length > 0) {
        const arr = [];
        setAlertMsg(false);
        const res = await apiService.getLeagues({
          id: filterSportData.id,
          language: apiService.getProfileFromStorage().Language,
        });
        setPopularLeagues(res.popularLeagues);
        for (const data in res.leaguseByCountries) {
          arr.push(res.leaguseByCountries[data]);
        }
        if (filterSportData.id) {
          setCountryLeaguesData(arr);
        } else {
          setCountryLeaguesData([]);
          setPopularLeagues([]);
        }
      } else {
        setAlertMsg(true);
      }
    }
  };
  const handleTournamentHideShow = async () => {
    setFilterTournamentSportBtn(false);
    setFilterData((s) => {
      if (s.tournament_id) {
        delete s.tournament_id;
      }
      return s;
    });
    setFilterPredictionTypeData([]);
    increamentUserCount();
  };
  const handleSelectedTournament = async (data) => {
    setTournamentSportData(data);
    setTournamentShow(false);
    setFilterTournamentSportBtn(true);
    setFilterData((s) => {
      return { ...s, tournament_id: data.id };
    });
    increamentUserCount();
  };
  const handleTournamentSearch = async (e) => {
    const arr = [];
    const res = await apiService.getLeagues({
      id: filterSportData.id,
      search: e.target.value,
      language: apiService.getProfileFromStorage().Language,
    });
    for (const data in res.leaguseByCountries) {
      arr.push(res.leaguseByCountries[data]);
    }
    if (e.target.value == "") {
      setPopularLeagues(res.popularLeagues);
    } else {
      setPopularLeagues([]);
    }
    setCountryLeaguesData(arr);
    if (arr.length < 1 && e.target.value != "") {
      setTournamentEmptyMsg(true);
    } else {
      setTournamentEmptyMsg(false);
    }
  };
  // filter prediction type
  const handleFliterPredictionType = () => {
    if (!filterSportData.id) {
      setTypeAlertMsg(true);
    } else {
      setTypeAlertMsg(false);
    }
    if (PredictionTypeShow) {
      setPredictionTypeShow(false);
    } else {
      setPredictionTypeShow(true);
      setSportShow(false);
      setTournamentShow(false);
      setStatusShow(false);
      setOddsRange(false);
      const prT = PredictionType[filterSportData.id?.toString()];
      let dataArray = [];
      for (const data in prT) {
        dataArray.push(data);
      }
      if (filterSportData.id) {
        setFilterPredictionTypeData(dataArray);
      } else {
        setFilterPredictionTypeData([]);
      }
    }
  };
  const handlePredictionTypeHideShow = () => {
    setFilterPredictionTypeSportBtn(false);
    setFilterData((s) => {
      if (s.predictionType) {
        delete s.predictionType;
      }
      return s;
    });
    increamentUserCount();
  };

  const handleSelectedPredictionType = (item) => {
    const prT = PredictionType[filterSportData.id.toString()];
    const value = prT[item];
    setFilterPredictionTypeSportBtn(true);
    setPredictionTypeShow(false);
    setSelectedPredictionType(item);
    setFilterData((s) => {
      return { ...s, predictionType: value };
    });
    increamentUserCount();
  };
  // filter status
  const handleFilterStatus = () => {
    if (statusShow) {
      setStatusShow(false);
    } else {
      setStatusShow(true);
      setPredictionTypeShow(false);
      setSportShow(false);
      setTournamentShow(false);
      setOddsRange(false);
    }
  };
  // odds range filter
  const handleFilterOddsRange = () => {
    if (OddsRange) {
      setOddsRange(false);
    } else {
      setOddsRange(true);
      setSportShow(false);
      setTournamentShow(false);
      setPredictionTypeShow(false);
      setStatusShow(false);
    }
  };
  const handleOddsRangeHideShow = () => {
    setFilterOddsRangeSportBtn(false);
    setFilterData((s) => {
      if (s.oddMin) {
        delete s.oddMin;
      }
      if (s.oddMax) {
        delete s.oddMax;
      }
      return s;
    });
    setSliderMinRange(1.05);
    setSliderMaxRange(20.05);
    increamentUserCount();
  };
  const ApplyOddsRange = (min, max) => {
    setOddsRange(false);
    setFilterOddsRangeSportBtn(true);
    setFilterData((s) => {
      return { ...s, oddMin: min, oddMax: max };
    });
    increamentUserCount();
  };
  const handleRangeSlider = ({ min, max }) => {
    setSliderMinRange(min);
    setSliderMaxRange(max);
  };
  // clear filter
  const handleClearFilter = () => {
    setFilterSportBtn(false);
    setFilterTournamentSportBtn(false);
    setFilterPredictionTypeSportBtn(false);
    setFilterOddsRangeSportBtn(false);
    setFilterData({});
    setCurrPage(0);
    setFilterSportData({});
    setSliderMinRange(1.05);
    setSliderMaxRange(20.05);
    setHandleHomeSport({});
    increamentUserCount();
    // setSportData([])
    // setTypeAlertMsg(true)
    // console.log("cleared sport data")
  };
  const handleRecomendedBtn = async () => {
    setFilterData((s) => {
      if (s.following) {
        delete s.following;
      }
      return s;
    });
    if (RecomendedBtn) {
      setRecomendedBtn("Recomended-following-sidebar-btn-active");
      setFollowingBtn("Recomended-following-sidebar-btn");
      setRecomendedBtnBorder("#131520");
      setFollowingBtnBorder("#7F87B3");
    }
    increamentUserCount();
  };
  const handleFollowingBtn = async () => {
    setFilterData((s) => {
      return { ...s, following: true };
    });
    if (followingBtn) {
      setFollowingBtn("Recomended-following-sidebar-btn-active");
      setRecomendedBtn("Recomended-following-sidebar-btn");
      setRecomendedBtnBorder("#7F87B3");
      setFollowingBtnBorder("#131520");
    }
    increamentUserCount();
  };
  const handleSearchData = _.debounce((e) => {
    setFilterData((s) => {
      return { ...s, search: e.target.value };
    });
    increamentUserCount();
  }, 300);

  // const manageData = async () => {
  //   if (localStorage.getItem("token")) {
  //     const res = await apiService.getPredictionOrder(filterData, pageSize);
  //     setBetsData(res.getPredictionOrder);
  //     setTotalBetsData(res.totalPredictionOrder);
  //   } else {
  //     const res = await apiService.getPredictionOrder(filterData, pageSize);
  //     setBetsData(res.getPredictionOrder);
  //     setTotalBetsData(res.totalPredictionOrder);
  //   }
  // };

  const manageData = async (pageNumber = currPage || 0) => {
    if (pageNumber === 0) {
      setShowPredictionLoader(true);
    }

    setShowLoadMoreLoader(true);

    const res = await apiService.getPredictionOrder(
      filterData,
      pageSize,
      pageNumber
    );

    if (pageNumber === 0) {
      setBetsData(res.getPredictionOrder);
    } else {
      setBetsData((oldState) => [...oldState, ...res.getPredictionOrder]);
    }

    if (res) {
      setShowPredictionLoader(false);
    }

    const pages = Math.ceil(res.totalPredictionOrder / pageSize);
    setPageState(pages);
    // console.log(currPage, pageState, res.totalPredictionOrder);

    setTotalBetsData(res.totalPredictionOrder);
    if (pages == currPage + 1) {
      setShowMessage(t("feed.showMessage"));
    }
    setShowLoadMoreLoader(false);
  };

  const ShowMorePredictionsData = async () => {
    setCurrPage(currPage + 1);
  };

  useEffect(() => {
    manageData();
  }, [filterData, currPage]);

  const handleActiveSportLogo = () => {
    if (popularLeague) {
      return "/sport-logo/" + popularLeague.sport_id + ".svg";
    } else if (Object.keys(handleHomeSport).length >= 1) {
      return "/sport-logo/" + handleHomeSport.id + ".svg";
    } else {
      return "/sport-logo/" + filterSportData.id + ".svg";
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("feed.metaTitle")}</title>
        <meta name="og:title" content={t("feed.metaSocialTitle")} />

        <meta name="og:url" content="https://capperauditor.com/predictions" />
        <meta name="twitter:title" content={t("feed.metaSocialTitle")} />

        <meta
          name="description"
          content={t("feed.metaDescription", {
            count: getThousandsNumber(
              totalBetsData,
              localStorage.getItem("language")
            ),
          })}
        />
        <meta
          name="og:description"
          content={t("feed.metaDescription", {
            count: getThousandsNumber(
              totalBetsData,
              localStorage.getItem("language")
            ),
          })}
        />
        <meta
          name="twitter:description"
          content={t("feed.metaDescription", {
            count: getThousandsNumber(
              totalBetsData,
              localStorage.getItem("language")
            ),
          })}
        />

        <link
          rel="alternate"
          href="https://capperauditor.com/predictions"
          hreflang="uk"
        />
        <link
          rel="alternate"
          href="https://capperauditor.com/en/predictions"
          hreflang="en"
        />
        <link
          rel="alternate"
          href="https://capperauditor.com/predictions"
          hreflang="x-default"
        />
      </Helmet>
      {localStorage.getItem("token") && localStorage.getItem("profile") ? (
        <NavigationBar />
      ) : (
        <HomePageHeader />
      )}
      <div className="notlogin-user-predictionList">
        <div className="prediction-list-container ">
          <div className="prediction-list-section">
            <div className="row">
              <div className="col-xs-12 col-lg-3 col-md-3 feed-left-sidebar">
                <div className="box notlogedin-Usersidebar">
                  <FeedSideBar
                    recommandBtnBorder={recommandBtnBorder}
                    followingBtnBorder={followingBtnBorder}
                    onClickRecomended={handleRecomendedBtn}
                    filterHandle={filterHandle}
                    onClickFollowingBtn={handleFollowingBtn}
                    countData={countData}
                    increamentUserCount={increamentUserCount}
                    recommandAddClass={RecomendedBtn}
                    followingAddClass={followingBtn}
                  />
                </div>
                {!isMobile && (
                  <>
                    <Ad />
                    <Ad2 />
                  </>
                )}
              </div>
              <div className="col-xs-12 col-lg-9 col-md-9 feed-right-sidebar">
                <div className="box">
                  <div className="filter-section">
                    <div className="filter-searchbar-section">
                      <SearchBar
                        addClass={"prediction-list-searchbar"}
                        inputPlaceHolder={t("profile.searchBarPlaceHolder")}
                        onChange={handleSearchData}
                        disabled={true}
                        lockSearchBarMsg={t("feed.lockSearchBarMsg")}
                      />
                      <InputButton
                        type="icon-button"
                        addBtnClass={filterBtnActive}
                        addSpan={
                          <span>
                            <img src="/filterlocked.svg" />
                          </span>
                        }
                        inputLabel={t("feed.Filters")}
                        onClick={handleFilters}
                        disabled={true}
                        lockSearchBarMsg={t("feed.lockFilterMsg")}
                        addMainClass={"filter-button-lock-tooltip"}
                      />
                    </div>
                    <div
                      className="filtered-items"
                      style={{ display: manageFilter ? "flex" : "none" }}
                    >
                      {/* sport-filter */}
                      {filterSportBtn ? (
                        <div>
                          <InputButton
                            type={"filter-button"}
                            inputLabel={
                              (popularLeague && popularLeague.sport_name) ||
                              (handleHomeSport && handleHomeSport.name) ||
                              filterSportData.name
                            }
                            addBtnClass={"filter-data-icon-button"}
                            addSpan={
                              <span>
                                <img src={handleActiveSportLogo()}></img>
                              </span>
                            }
                            filterCloseIcon={
                              <img
                                src="/filter-close.svg"
                                width="25px"
                                height="25px"
                                className="filter-close-icon"
                                onClick={handleSportHideShow}
                              />
                            }
                          />
                        </div>
                      ) : (
                        <div
                          className="sport-contains"
                          onClick={handleFliterSport}
                        >
                          <div className="sport-data">{t("feed.Sport")}</div>
                          {sportShow ? (
                            <img
                              src="/black-up.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          ) : (
                            <img
                              src="/black-down.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          )}
                        </div>
                      )}
                      {/* tournament-filter */}
                      {filterTournamentBtn ? (
                        <div>
                          <InputButton
                            type={"filter-button"}
                            inputLabel={
                              popularLeague
                                ? popularLeague.tournament_name
                                : filterTournamentData.name
                            }
                            addBtnClass={"filter-data-icon-button"}
                            addSpan={
                              <span>
                                <img
                                  src={
                                    popularLeague
                                      ? "/sport-logo/" +
                                        popularLeague.sport_id +
                                        ".svg"
                                      : "country-logo/" +
                                        filterTournamentData.country_id +
                                        ".png"
                                  }
                                  alt=""
                                ></img>
                              </span>
                            }
                            filterCloseIcon={
                              <img
                                src="/filter-close.svg"
                                width="25px"
                                height="25px"
                                className="filter-close-icon"
                                onClick={handleTournamentHideShow}
                                alt=""
                              />
                            }
                          />
                        </div>
                      ) : (
                        <div
                          className="Tournament-contains"
                          onClick={handleFliterTournament}
                        >
                          <div className="Tournament-data">
                            {t("feed.Tournament")}
                          </div>
                          {tournamentShow ? (
                            <img
                              src="/black-up.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          ) : (
                            <img
                              src="/black-down.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          )}
                        </div>
                      )}
                      {/* prediction-type */}
                      {filterPredictionTypeBtn ? (
                        <div>
                          <InputButton
                            type={"filter-button"}
                            inputLabel={selectedPredictionType}
                            addBtnClass={"filter-data-icon-button"}
                            filterCloseIcon={
                              <img
                                src="/filter-close.svg"
                                width="25px"
                                height="25px"
                                className="filter-close-icon"
                                onClick={handlePredictionTypeHideShow}
                                alt=""
                              />
                            }
                          />
                        </div>
                      ) : (
                        <div
                          className="PredictionType-contains"
                          onClick={handleFliterPredictionType}
                        >
                          <div className="PredictionType-data">
                            {t("feed.PredictionType")}
                          </div>
                          {PredictionTypeShow ? (
                            <img
                              src="/black-up.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          ) : (
                            <img
                              src="/black-down.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          )}
                        </div>
                      )}
                      <div
                        className="Status-contains"
                        onClick={handleFilterStatus}
                      >
                        <div className="Status-data">{t("feed.Status")}</div>
                        {statusShow ? (
                          <img src="black-up.svg" width="20px" height="20px" />
                        ) : (
                          <img
                            src="black-down.svg"
                            width="20px"
                            height="20px"
                            alt=""
                          />
                        )}
                      </div>
                      {/* odds range button */}
                      {filterOddsRangeBtn ? (
                        <div>
                          <InputButton
                            type={"filter-button"}
                            inputLabel={`${sliderMinRange} - ${sliderMaxRange}`}
                            addBtnClass={"filter-data-icon-button"}
                            filterCloseIcon={
                              <img
                                src="/filter-close.svg"
                                width="25px"
                                height="25px"
                                className="filter-close-icon"
                                onClick={handleOddsRangeHideShow}
                                alt=""
                              />
                            }
                          />
                        </div>
                      ) : (
                        <div
                          className="OddsRange-contains"
                          onClick={handleFilterOddsRange}
                        >
                          <div className="OddsRange-data">
                            {t("feed.OddsRange")}
                          </div>
                          {OddsRange ? (
                            <img
                              src="/black-up.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          ) : (
                            <img
                              src="/black-down.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          )}
                        </div>
                      )}
                      {/* clear all filter */}
                      <div
                        className="Clear-filters-DropDown"
                        style={{
                          display:
                            !filterData.sport_id &&
                            !filterData.tournament_id &&
                            !filterData.predictionType &&
                            !filterData.oddMin &&
                            !filterData.oddMax
                              ? "none"
                              : "flex",
                        }}
                      >
                        <div
                          className="Clear-filters-contains"
                          onClick={handleClearFilter}
                        >
                          <div className="Clear-filters-data">
                            {t("feed.ClearFilters")}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* sport dropdown */}
                    <div
                      className="sport-DropDown"
                      style={{ display: sportShow ? "block" : "none" }}
                    >
                      <SearchBar
                        onChange={handleSportSearch}
                        showSuggetions={sportShow}
                        addMainContainer={"filter-sport-container"}
                        suggetionList={sportData}
                        inputPlaceHolder={t("profile.search")}
                        suggetionData={"Data"}
                        errorMsg={t("feed.NoResultsFound")}
                        displayMsg={sportEmptyMsg}
                      >
                        <div>
                          {sportData.map((item, index) => (
                            <div
                              className="filtered-items-containter"
                              key={index}
                              onClick={() => handleSelectedSport(item)}
                            >
                              <div className="d-flex">
                                <div className="item-icon">
                                  <img
                                    src={"sport-logo/" + item.id + ".svg"}
                                    alt=""
                                  />
                                </div>
                                <div className="filtered-items-name">
                                  {item.name}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </SearchBar>
                    </div>
                    {/* tournament - dropDown */}
                    <div
                      className="tournament-DropDown"
                      style={{ display: tournamentShow ? "block" : "none" }}
                    >
                      <SearchBar
                        showSuggetions={tournamentShow}
                        onChange={handleTournamentSearch}
                        inputPlaceHolder={t("profile.search")}
                        suggetionData={"Data"}
                        addClass={"tournament-container"}
                        addMainContainer={"filter-tournament-container"}
                        errorMsg={t("feed.NoResultsFound")}
                        displayMsg={tournamentEmptyMsg}
                      >
                        <div style={{ display: alertMsg ? "none" : "block" }}>
                          {/* popular-league */}
                          {popularLeagues.length > 0 ? (
                            <div>
                              <div className="popular-league-title">
                                {t("profile.popularLeagues")}
                              </div>
                              {popularLeagues.map((item, index) => (
                                <div
                                  className="tournament-container"
                                  key={index}
                                  onClick={() => handleSelectedTournament(item)}
                                >
                                  <div className="d-flex">
                                    <div className="item-icon">
                                      <img
                                        src={`/country-logo/${item.country_id}.png`}
                                        alt=""
                                      />
                                    </div>
                                    <div className="filtered-items-name">
                                      {item.name}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            ""
                          )}
                          {/* country */}
                          {countryLeaguesData.length > 0 ? (
                            <div className="country-title">
                              {t("profile.countries")}
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="accordion-country-container">
                            {countryLeaguesData.map((item, index) => (
                              <Accordion key={index}>
                                {item.id ? (
                                  <Accordion.Item
                                    eventKey={"countryId-" + item.id}
                                  >
                                    <Accordion.Header>
                                      <div className="d-flex">
                                        <div className="model-country-icon">
                                          <img
                                            src={
                                              "country-logo/" + item.id + ".png"
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div className="model-country-name">
                                          {item.name}
                                        </div>
                                      </div>
                                    </Accordion.Header>
                                    {item.leagues.map((data, index) => (
                                      <Accordion.Body key={index}>
                                        <div
                                          className=""
                                          id="countryDropDownId"
                                        >
                                          <div
                                            className=" filter-country-contains"
                                            onClick={() =>
                                              handleSelectedTournament(data)
                                            }
                                          >
                                            <div className="country-leaguees-name">
                                              {data.name}
                                            </div>
                                          </div>
                                        </div>
                                      </Accordion.Body>
                                    ))}
                                  </Accordion.Item>
                                ) : (
                                  ""
                                )}
                              </Accordion>
                            ))}
                          </div>
                        </div>
                        <div
                          className="alert-message"
                          style={{ display: alertMsg ? "block" : "none" }}
                        >
                          {t("feed.alertMsg")}
                        </div>
                      </SearchBar>
                    </div>

                    {/* Prediction type */}
                    <div
                      className="PredictionType-DropDown"
                      style={{ display: PredictionTypeShow ? "block" : "none" }}
                    >
                      <SearchBar
                        showSuggetions={PredictionTypeShow}
                        addMainContainer={"filter-sport-container"}
                        inputPlaceHolder={t("profile.search")}
                        suggetionData={"Data"}
                      >
                        <div
                          style={{ display: typeAlertMsg ? "none" : "block" }}
                        >
                          {filterPredictionTypeData.map((item, index) => (
                            <div
                              className="filtered-items-containter"
                              key={index}
                              onClick={() => handleSelectedPredictionType(item)}
                            >
                              <div className="d-flex">
                                <div className="filtered-items-name">
                                  {item}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div
                          className="alert-message"
                          style={{ display: typeAlertMsg ? "block" : "none" }}
                        >
                          {t("feed.alertMsg")}
                        </div>
                      </SearchBar>
                    </div>
                    {/* Status-DropDown */}
                    <div
                      className="Status-DropDown"
                      style={{ display: statusShow ? "block" : "none" }}
                    >
                      <SearchBar
                        showSuggetions={statusShow}
                        suggetionList={[
                          { id: "1", name: "Active" },
                          { id: "2", name: "Pending" },
                        ]}
                        inputPlaceHolder={t("profile.search")}
                        suggetionData={"Data"}
                      />
                    </div>

                    {/* odds range */}
                    <div
                      className="OddsRange-DropDown"
                      style={{ display: OddsRange ? "block" : "none" }}
                    >
                      <div className="range-container">
                        <div className="range-slider">
                          <RangeSlider
                            min={1.05}
                            max={20.05}
                            onChange={handleRangeSlider}
                            up={
                              sliderMinRange == 1.05 && sliderMaxRange == 20.05
                            }
                            countData={countData}
                            increamentUserCount={increamentUserCount}
                          />
                        </div>
                        <div className="d-flex justify-content-end">
                          <InputButton
                            type="primary"
                            inputLabel={t("feed.Apply")}
                            onClick={() =>
                              ApplyOddsRange(sliderMinRange, sliderMaxRange)
                            }
                            addBtnClass={"apply-btn"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="predictionfound-sortby-contains">
                    <div className="prediction-found-section d-flex">
                      <div className="img-padding">
                        <img src="/ticket.svg" alt="" />
                      </div>
                      <div className="prediction-found-title">
                        <span>{totalBetsData}</span>
                        {t("feed.predictionsFound")}
                      </div>
                    </div>
                    <div className="sortby-section">
                      <div className="SortBy-name">{t("feed.SortBy")}</div>
                      <div className="sortby-container">
                        <div className="sortby-dropdown">
                          <div
                            className="d-flex selected-fliter ignore-dropdown"
                            ref={menuRef}
                            onClick={handleFilterDropDown}
                          >
                            <div className="filter-name d-flex align-items-center">
                              {filteredName === "Odds"
                                ? t("feed.Odds")
                                : filteredName == "Popular"
                                ? t("feed.Popular")
                                : t("feed.Newest")}
                            </div>
                            <div>
                              {dropDownHideShow ? (
                                <img src="up.svg" alt="" />
                              ) : (
                                <img src="down.svg" alt="" />
                              )}
                            </div>
                          </div>
                          <ul className="fliter-type ignore-dropdown">
                            <div
                              className="list-drop-down"
                              style={{
                                display: dropDownHideShow ? "block" : "none",
                              }}
                            >
                              <li
                                className="d-flex filter-menu"
                                onClick={() => handleFilterData("Newest")}
                              >
                                <div className="filter-name">
                                  {t("feed.Newest")}
                                </div>
                              </li>
                              <li
                                className="d-flex filter-menu"
                                onClick={() => handleFilterData(t("Popular"))}
                              >
                                <div className="filter-name ">
                                  {t("feed.Popular")}
                                </div>
                              </li>
                              <li
                                className="d-flex filter-menu"
                                onClick={() => handleFilterData(t("Odds"))}
                              >
                                <div className="filter-name ">
                                  {t("feed.Odds")}
                                </div>
                              </li>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  {showPredictionLoader ? (
                    <div>
                      <div className="web-PredictionListLOader">
                        <PredictionsListLoader />
                      </div>
                      <div className="mobile-predictionlistLoader">
                        <MobilePredictionListLoader />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="pb-3 feed-prediction-list"
                      style={{
                        display: betsData.length > 0 ? "block" : "block",
                      }}
                    >
                      {betsData.length === 0 ? (
                        <NoPredictionList />
                      ) : (
                        <PredictionList
                          betsData={betsData}
                          countData={countData}
                          currPage={currPage}
                          pageSize={pageSize}
                          increamentUserCount={increamentUserCount}
                          onClick={ShowMorePredictionsData}
                          totalBetsData={totalBetsData}
                          showMessage={showMessage}
                          showLoadMoreLoader={showLoadMoreLoader}
                        />
                      )}
                    </div>
                  )}
                  {showPredictionLoader ? (
                    <div className="">
                      <div className="web-PredictionListLOader mt-3">
                        <PredictionListCustomLoader />
                      </div>
                      <div className="mobile-predictionlistLoader mt-3">
                        <MobilePredictionListLoader />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {showPredictionLoader ? (
                    <div className="">
                      <div className="web-PredictionListLOader mt-3">
                        <PredictionListCustomLoader />
                      </div>
                      <div className="mobile-predictionlistLoader mt-3">
                        <MobilePredictionListLoader />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* {betsData.length > 0 ? (
                    <div
                      className="pb-3"
                      style={{
                        display: betsData.length > 0 ? "block" : "none",
                      }}
                    >
                      <PredictionList
                        betsData={betsData}
                        pageSize={pageSize}
                        countData={countData}
                        currPage={currPage}
                        totalBetsData={totalBetsData}
                        showMessage={showMessage}
                        showLoadMoreLoader={showLoadMoreLoader}
                        onClick={ShowMorePredictionsData}
                        increamentUserCount={increamentUserCount}
                      />
                      <div className="bottom-text-section predictions__bottom-text-section">
                        <h1>{t("predictions.bottomTextTitle")}</h1>
                        <p>
                          {t("predictions.bottomTextDescription", {
                            count: getThousandsNumber(
                              totalBetsData,
                              localStorage.getItem("language")
                            ),
                          })}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="web-PredictionListLOader">
                        <PredictionsListLoader />
                      </div>
                      <div className="mobile-predictionlistLoader">
                        <MobilePredictionListLoader />
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <HomePageFooter />
    </>
  );
};
export default HomePagePrediction;
