import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { FaRegCircleQuestion } from "react-icons/fa6";
import { formatPrice } from "../../../../helpers/formater";

import ToolTip from "../../../General/ToolTip/ToolTip";

import "./TopGamePredictions.css";

const TopGamePredictions = ({ data, isLoading }) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const renderTableItems = () => {
    const numberOfRows = 5;

    if (isLoading) {
      return Array(5)
        .fill(0)
        .map((item, index) => {
          let place = index + 1;
          const imagePath = `/Leaderboard-img/${place}.svg`;
          return (
            <tr className="top-predictions-table__row" key={index}>
              <td className="prediction-place-cell">
                {place <= 3 ? <img src={imagePath} alt="avatar"></img> : place}
              </td>
              <td>
                <div className="top-predictions__oc_name-wrapper">
                  <div className="top-predictions__oc_name">
                    <div className="skeleton-placeholder-light top-predictions__oc-name-loader"></div>
                  </div>
                  <div className="top-predictions__oc_group_name">
                    <div className="skeleton-placeholder-light top-predictions__oc-group-name-loader"></div>
                  </div>
                </div>
              </td>
              <td>
                <div className="skeleton-placeholder-light top-predictions__sm-value-loader"></div>
              </td>
              {!isMobile && (
                <>
                  <td>
                    <div className="skeleton-placeholder-light top-predictions__sm-value-loader"></div>
                  </td>
                  <td>
                    <div className="skeleton-placeholder-light top-predictions__md-value-loader"></div>
                  </td>
                </>
              )}
            </tr>
          );
        });
    }

    if (data.length > 0) {
      const expandedData = [
        ...data,
        ...Array.from({ length: numberOfRows - data.length }, () => []),
      ];

      return expandedData.map((item, index) => {
        let place = index + 1;
        const imagePath = `/Leaderboard-img/${place}.svg`;

        return (
          <tr className="top-predictions-table__row" key={item._id || index}>
            <td className="prediction-place-cell">
              {place <= 3 ? <img src={imagePath} alt="avatar"></img> : place}
            </td>
            <td>
              {item?._id ? (
                <div className="top-predictions__oc_name-wrapper">
                  <div className="top-predictions__oc_name">{item._id}</div>
                  <div className="top-predictions__oc_group_name">
                    {item.ocGroupNames[0]}
                  </div>
                </div>
              ) : (
                <div className="top-predictions__oc_name">-</div>
              )}
            </td>
            <td>{item.count ? item.count : "-"}</td>
            {!isMobile && (
              <>
                <td>{item.avgOcRate ? item.avgOcRate.toFixed(2) : "-"}</td>
                <td>{item.totalCoins ? formatPrice(item.totalCoins) : "-"}</td>
              </>
            )}
          </tr>
        );
      });
    }
  };

  return (
    <div className="top-game-predictions">
      <div className="top-game-predictions__title">
        {t("gameDetail.topPredictionsTitle")}
      </div>
      <table className="game-top-predictions__table">
        <thead className="top-predictions-table__header">
          <tr className="top-predictions-table__header">
            <th className="prediction-place-header">#</th>
            <th className="prediction-name-header">
              {t("gameDetail.topPredictionsTable.headers.prediction")}
            </th>
            <th className="prediction-count-header">
              <ToolTip
                text={t("gameDetail.topPredictionsTable.hints.predictions")}
              >
                <div className="top-predictions-table__header-hint">
                  {t("gameDetail.topPredictionsTable.headers.predictions")}
                  <FaRegCircleQuestion />
                </div>
              </ToolTip>
            </th>
            {!isMobile && (
              <>
                <th className="prediction-avg-header">
                  <ToolTip
                    text={t("gameDetail.topPredictionsTable.hints.avgCoef")}
                  >
                    <div className="top-predictions-table__header-hint">
                      {t("gameDetail.topPredictionsTable.headers.avgCoef")}
                      <FaRegCircleQuestion />
                    </div>
                  </ToolTip>
                </th>
                <th className="prediction-coins-header">
                  <ToolTip
                    text={t("gameDetail.topPredictionsTable.hints.placed")}
                  >
                    <div className="top-predictions-table__header-hint">
                      {t("gameDetail.topPredictionsTable.headers.placed")}
                      <FaRegCircleQuestion />
                    </div>
                  </ToolTip>
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>{renderTableItems()}</tbody>
      </table>
    </div>
  );
};

export default TopGamePredictions;
