import React from "react";
import { useTranslation } from "react-i18next";
import { FaRegCircleQuestion } from "react-icons/fa6";

import ToolTip from "../../../General/ToolTip/ToolTip";

const GamePredictions = ({ gameStatictic, isLoading }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="game-details__predictions">
      <div className="game-details__predictions-title">
        {t("gameDetail.predictionOverview.title")}
      </div>
      <div className="game-details__predictions-content">
        <div className={isLoading ? "game-details__predictions-item total-count loading" : "game-details__predictions-item total-count"}>
          <div className="predictions-item__title">
            {t("gameDetail.predictionOverview.total")}
          </div>
          <div className="predictions-item__info">
            {Object.keys(gameStatictic).length !== 0 ? (
              gameStatictic?.counts?.total
            ) : (
              <div className="skeleton-placeholder-light game-details_count-loader"></div>
            )}
          </div>
        </div>
        <div className="game-details__predictions-item">
          <ToolTip text={t("gameDetail.predictionOverview.beforeHint")}>
            <div className="predictions-item__title">
              {t("gameDetail.predictionOverview.before")}
              <FaRegCircleQuestion />
            </div>
          </ToolTip>
          <div className="predictions-item__info">
            {Object.keys(gameStatictic).length !== 0 ? (
              gameStatictic?.counts?.beforeGame
            ) : (
              <div className="skeleton-placeholder-light game-details_count-loader"></div>
            )}
          </div>
        </div>
        <div className="game-details__predictions-item">
          <ToolTip text={t("gameDetail.predictionOverview.liveHint")}>
            <div className="predictions-item__title">
              {t("gameDetail.predictionOverview.live")}
              <FaRegCircleQuestion />
            </div>
          </ToolTip>
          <div className="predictions-item__info">
            {Object.keys(gameStatictic).length !== 0 ? (
              gameStatictic?.counts?.live
            ) : (
              <div className="skeleton-placeholder-light game-details_count-loader"></div>
            )}
          </div>
        </div>
        <div className="game-details__predictions-item">
          <ToolTip text={t("gameDetail.predictionOverview.singleHint")}>
            <div className="predictions-item__title">
              {t("gameDetail.predictionOverview.single")}
              <FaRegCircleQuestion />
            </div>
          </ToolTip>
          <div className="predictions-item__info">
            {Object.keys(gameStatictic).length !== 0 ? (
              gameStatictic?.counts?.single
            ) : (
              <div className="skeleton-placeholder-light game-details_count-loader"></div>
            )}
          </div>
        </div>
        <div className="game-details__predictions-item">
          <ToolTip text={t("gameDetail.predictionOverview.expressHint")}>
            <div className="predictions-item__title">
              {t("gameDetail.predictionOverview.express")}
              <FaRegCircleQuestion />
            </div>
          </ToolTip>
          <div className="predictions-item__info">
            {Object.keys(gameStatictic).length !== 0 ? (
              gameStatictic?.counts?.express
            ) : (
              <div className="skeleton-placeholder-light game-details_count-loader"></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamePredictions;
