import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import "./ChartTooltip.css";
import { formatNumber } from "../../../../helpers/formater";

const ChartTooltip = ({ item, children }) => {
  const { t, i18n } = useTranslation();

  const tooltipRef = useRef(null);
  const [xPosition, setXPosition] = useState(0);

  const handleMouseMove = (event) => {
    if (tooltipRef.current) {
      const rect = tooltipRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      setXPosition(x);
    }
  };

  return (
    <div
      className="chart-tooltip-wrapper"
      onMouseMove={handleMouseMove}
      ref={tooltipRef}
      style={{
        width: item.percentage ? item.percentage + "%" : "3px",
      }}
    >
      {children}

      <div
        className="chart-tooltip-text"
        style={{
          left: xPosition,
        }}
      >
        <div className="chart-tooltip-title">{t("gameDetail.predictions")}</div>
        <div className="chart-tooltip__value-wrapper">
          <div className="chart-tooltip__main-value">{item.count}</div>
          <div className="chart-tooltip__second-value">
            ({formatNumber(item.percentage)}%)
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartTooltip;
