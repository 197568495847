import React, { useEffect, useState } from "react";
import "../css/wallet.css";
import NavigationBar from "./Navbar";
import HomePageFooter from "./molecule/homepage/HomePageFooter/HomePageFooter";
import { useTranslation } from "react-i18next";
import InputButton from "./molecule/InputButton";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import Model from "./molecule/model/Model";
import apiService from "../services/apiService";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import TopPredictionListDetailsModel from "./molecule/Chart/TopPredictionListDetailsModel";
import { Helmet } from "react-helmet";
import Ad from "./General/Ad";
import WalletRewardsSection from "./Rewards/WalletRewardsSection";
import DropDownInput from "./General/DropDownInput/DropDownInput";
import { useMediaQuery } from "react-responsive";
import WalletHistoryLoader from "./CustomSkeletonLoaders/WalletHistoryLoader";

const Wallet = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [currPage, setCurrPage] = useState(0);
  const pageSize = currPage == 0 ? 5 : 10;

  const TAB_OPTIONS = [
    {
      id: "collect",
      text: "Зібрані монети",
      text_en: "Collected coins",
    },
    {
      id: "earn",
      text: "Зароблені монети",
      text_en: "Earned coins",
    },
    {
      id: "spent",
      text: "Витрачені монети",
      text_en: "Spent coins",
    },
    {
      id: "reward",
      text: "Досягнення",
      text_en: "Achievements",
    },
  ];

  const [isHistoryLoaded, setIsHistoryLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState("earn");
  const [activeDropDownTab, setActiveDropDownTab] = useState(TAB_OPTIONS[0]);
  const [modelShow, setModelShow] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [timeStampData, setTimeStampData] = useState({});
  const [isIntervalData, setIsIntervalData] = useState(false);
  const [selectedCoinData, setSelectedCoinData] = useState({});
  const [collectCoinModel, setCollectCoinModel] = useState(false);
  const [selectedTab, setSelectedTab] = useState("collected-coins");
  const [getTotalCoinData, setTotalCoinAllData] = useState({});
  const [getSpentCoinData, setSpentCoinAllData] = useState({});
  const [getCollectCoinData, setCollectCoinData] = useState({});
  const [getEarnCoinData, setEarnCoinData] = useState({});
  const [getEarningHistoryData, setEarningHistoryData] = useState([]);
  const [getEarningHistoryCount, setEarningHistoryCount] = useState(0);
  const [pageRefresh, setPageRefersh] = useState(0);
  const [moreDataLoaded, setMoreDataLoaded] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState("");
  const [isTebletSize, setIsTebletSize] = useState(false);
  const [isMobileSize, setIsMobileSize] = useState(false);
  const [buttonDisableData, setButtonDisableData] = useState(false);
  const [predictionDetail, setPredictionDetail] = useState({});
  const [selectedDayCollectData, setSelectedDayCollectData] = useState({});
  const [coinAllData, setCoinAllData] = useState([
    {
      id: 1,
      day: `${t("wallet.Day")} 1`,
      icon: "/wallet-coin/card-coin-icon/1.png",
      coinValue: "+ 10",
    },
    {
      id: 2,
      day: `${t("wallet.Day")} 2`,
      icon: "/wallet-coin/card-coin-icon/2.png",
      coinValue: "+ 15",
    },
    {
      id: 3,
      day: `${t("wallet.Day")} 3`,
      icon: "/wallet-coin/card-coin-icon/3.png",
      coinValue: "+ 20",
    },
    {
      id: 4,
      day: `${t("wallet.Day")} 4`,
      icon: "/wallet-coin/card-coin-icon/4.png",
      coinValue: "+ 25",
    },
    {
      id: 5,
      day: `${t("wallet.Day")} 5`,
      icon: "/wallet-coin/card-coin-icon/5.png",
      coinValue: "+ 30",
    },
    {
      id: 6,
      day: `${t("wallet.Day")} 6`,
      icon: "/wallet-coin/card-coin-icon/6.png",
      coinValue: "+ 35",
    },
    {
      id: 7,
      day: `${t("wallet.Day")} 7`,
      icon: "/wallet-coin/card-coin-icon/7.png",
      coinValue: "+ 40",
    },
    {
      id: 8,
      day: t("wallet.eachNextDay"),
      icon: "/wallet-coin/card-coin-icon/8.png",
      coinValue: "+ 50",
    },
  ]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/signin");
    }

    getCoins();
  }, []);

  useEffect(() => {
    setActiveTab(activeDropDownTab.id);
    setCurrPage(0);
  }, [activeDropDownTab]);

  const getCoins = async () => {
    const res = await apiService.getCollectCoin();
    setTimeStampData(res);
  };

  const handleTopPredictionModel = (item) => {
    setPredictionDetail(item);
    setModelShow(true);
  };

  const handleTopPredictionModelHide = () => {
    setModelShow(false);
  };

  useEffect(() => {
    const screenWidth = window.innerWidth;
    const mobileSize = screenWidth < 768;
    setIsMobileSize(mobileSize);
    const size = screenWidth >= 768 && screenWidth <= 1125;
    setIsTebletSize(size);
  }, [window.innerWidth]);

  const getUserCoin = async () => {
    const response = await apiService.getUserCoin();
    response.map((item) => {
      if (item.coinType == "COLLECT") {
        setCollectCoinData({
          lastWeekAmount: item.lastWeekAmount,
          totalAmount: item.totalAmount,
        });
      }
      if (item.coinType == "TOTAL") {
        setTotalCoinAllData({
          lastWeekAmount: item.lastWeekAmount,
          totalAmount: item.totalAmount,
        });
      }
      if (item.coinType == "EARN") {
        setEarnCoinData({
          lastWeekAmount: item.lastWeekAmount,
          totalAmount: item.totalAmount,
        });
      }
      if (item.coinType == "SPENT") {
        setSpentCoinAllData({
          lastWeekAmount: item.lastWeekAmount,
          totalAmount: item.totalAmount,
        });
      }
    });
    // earning histrory
  };

  const earningHistroy = async (pageNumber = currPage || 0) => {
    if (pageNumber === 0) {
      setIsHistoryLoaded(false);
    }

    let bodyKey = "";
    if (activeTab === "collect") {
      bodyKey = "COLLECT";
    } else if (activeTab === "reward") {
      bodyKey = "REWARD";
    } else if (activeTab === "spent") {
      bodyKey = "SPENT";
    } else {
      bodyKey = "EARN";
    }
    const data = {
      // pageSize: pageSize,
      pageNumber,
      data: bodyKey,
    };
    const histrory = await apiService.getEarningHistory(data);
    if (currPage == 0) {
      setEarningHistoryData(histrory?.getCoinHistory);
    } else {
      setEarningHistoryData((oldState) => [
        ...oldState,
        ...histrory?.getCoinHistory,
      ]);
    }
    if (histrory?.getCoinHistory) {
      setMoreDataLoaded(false);
    }
    setEarningHistoryCount(histrory?.countData);
    const recordsPerPageFirstPage = 5; // Number of records displayed on the first page
    const recordsPerPageOtherPages = 10;

    const remainingRecords = histrory?.countData - recordsPerPageFirstPage;
    const additionalPages = Math.ceil(
      remainingRecords / recordsPerPageOtherPages
    );
    const totalPages = 1 + additionalPages;
    if (histrory?.countData <= 5 || totalPages == currPage + 1) {
      setShowMessage(t("feed.showMessage"));
    } else {
      setShowMessage("");
    }

    setIsHistoryLoaded(true);
  };

  const handleSelectedTab = (e) => {
    setSelectedTab(e);
    setCurrPage(0);
  };

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
    setCurrPage(0);
  };
  // show more btn
  const ShowMorePredictionsData = async () => {
    setCurrPage(currPage + 1);
    setMoreDataLoaded(true);
  };
  const handleLanguageLocalization = (data, isFullDate) => {
    var localLocale = moment.unix(data);
    localLocale.locale(localStorage.getItem("language") == "ua" ? "uk" : "en");
    if (isFullDate) {
      return (
        localLocale.format("MMMM D, YYYY").charAt(0).toUpperCase() +
        localLocale.format("MMMM D, YYYY").slice(1)
      );
    } else {
      return (
        localLocale.format("MMMM D").charAt(0).toUpperCase() +
        localLocale.format("MMMM D").slice(1)
      );
    }
  };

  useEffect(() => {
    getUserCoin();
    document.body.classList.add("wallet-bosy-background");
  }, [pageRefresh]);

  useEffect(() => {
    earningHistroy();
  }, [activeTab, currPage, pageRefresh]);

  const handleCollectCoinsModel = async (data) => {
    setSelectedCoinData(data);
    setCollectCoinModel(true);
    setButtonDisableData(false);
  };

  const handleEmptyStateDiv = () => {
    let title = t("wallet.noPostDataTitle");
    let subTitle = t("wallet.noPostDataTitle");
    let titleBtn = t("wallet.noPostDataTitle");
    let link = "/sportline";

    if (activeTab === "reward") {
      title = t("wallet.noRewardTitle");
      subTitle = t("wallet.noRewardSubTitle");
    } else if (activeTab === "spent") {
      title = t("wallet.emptymainTitle");
      subTitle = t("wallet.emptyMainSubtitle");
      titleBtn = t("wallet.emptyStateBtn");
      link = "/marketplace";
    }

    return (
      <>
        <div className="table-empty-state">
          <div className="title text-center">{title}</div>
          <div className="sub-title text-center pb-4">{subTitle}</div>
          {activeTab !== "reward" && (
            <div className="d-flex justify-content-center">
              <InputButton
                type={"primary"}
                addBtnClass={"collect-coin-btn w-0"}
                inputLabel={titleBtn}
                onClick={() => navigate(link)}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  const handleShowMoreBtn = () => {
    return (
      <>
        <div
          className="ShowMorePredictions-button"
          style={{
            display: showMessage != "" ? "none" : "flex",
          }}
        >
          <InputButton
            type={"secondary"}
            addBtnClass={"mt-3 ShowMorePredictions-btn"}
            inputLabel={
              moreDataLoaded ? (
                <Spinner
                  className="pagination-spinner"
                  animation="border"
                  variant="dark"
                />
              ) : (
                t("wallet.showMore")
              )
            }
            onClick={ShowMorePredictionsData}
            addMainClass={"showMorePrediction-div"}
            // pointerEventsStyle={moreDataLoaded}
          />
        </div>
      </>
    );
  };
  // collect coin data
  const handleCollectCoinData = async () => {
    setButtonDisableData(true);
    setCollectCoinModel(false);
    const res = await apiService.collectCoins();
    if (res) {
      // collected btn
      coinAllData?.map((item) => {
        if (res?.day == item?.day?.split(" ")[1]) {
          item.isCollectedBtn = true;
          item.isTimerBtn = false;
          item.isPrimaryCollectBtn = false;
        } else if (res?.day >= 8) {
          item.isCollectedBtn = false;
          item.isTimerBtn = false;
          item.isPrimaryCollectBtn = false;
        } else {
          setIsIntervalData(false);
        }
        if (res?.day > 7 && item?.id == 8) {
          setReloadFlag(true);
        }
      });
      setPageRefersh(pageRefresh + 1);
    }
  };
  const [reloadFlag, setReloadFlag] = useState(false);

  useEffect(() => {
    if (reloadFlag) {
      handleGetCoinData();
      setReloadFlag(false);
    }
  }, [reloadFlag]);

  const handleAllDayCoin = () => {
    const data = [
      {
        id: 1,
        day: `${t("wallet.Day")} 1`,
        icon: "/wallet-coin/card-coin-icon/1.png",
        coinValue: "+ 10",
      },
      {
        id: 2,
        day: `${t("wallet.Day")} 2`,
        icon: "/wallet-coin/card-coin-icon/2.png",
        coinValue: "+ 15",
      },
      {
        id: 3,
        day: `${t("wallet.Day")} 3`,
        icon: "/wallet-coin/card-coin-icon/3.png",
        coinValue: "+ 20",
      },
      {
        id: 4,
        day: `${t("wallet.Day")} 4`,
        icon: "/wallet-coin/card-coin-icon/4.png",
        coinValue: "+ 25",
      },
      {
        id: 5,
        day: `${t("wallet.Day")} 5`,
        icon: "/wallet-coin/card-coin-icon/5.png",
        coinValue: "+ 30",
      },
      {
        id: 6,
        day: `${t("wallet.Day")} 6`,
        icon: "/wallet-coin/card-coin-icon/6.png",
        coinValue: "+ 35",
      },
      {
        id: 7,
        day: `${t("wallet.Day")} 7`,
        icon: "/wallet-coin/card-coin-icon/7.png",
        coinValue: "+ 40",
      },
      {
        id: 8,
        day: t("wallet.eachNextDay"),
        icon: "/wallet-coin/card-coin-icon/8.png",
        coinValue: "+ 50",
      },
    ];
    setCoinAllData(data);
  };

  useEffect(() => {
    handleAllDayCoin();
  }, [pageRefresh]);

  // timer
  useEffect(() => {
    const timer = setInterval(() => {
      const nowInGMT = moment().utc();
      const midnightInGMT = moment().utc().endOf("day");
      const diff = moment.duration(midnightInGMT.diff(nowInGMT));
      const formattedCountdown = `${diff.hours()}:${diff.minutes()}:${diff.seconds()}`;

      setTimeRemaining(formattedCountdown);

      const currentDate = new Date();
      const coinTimestamp = timeStampData?.updatedAt;
      const coinDate = new Date(coinTimestamp * 1000);

      const isToday =
        currentDate.getFullYear() === coinDate.getFullYear() &&
        currentDate.getMonth() === coinDate.getMonth() &&
        currentDate.getDate() === coinDate.getDate();

      // console.log(currentDate, coinDate);
      if (!isToday && timeStampData?.updatedAt) {
        clearInterval(timer);
        setIsIntervalData(true);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [pageRefresh, timeStampData]);

  const handleGetCoinData = async () => {
    const res = await apiService.getCollectCoin();

    setTimeStampData(res);

    coinAllData?.map((item) => {
      // default day one
      if (!res || res == "" || res == null || res?.day == 0) {
        if (item.id == 1) {
          item.isPrimaryCollectBtn = true;
          coinAllData.isPrimaryCollectBtn = true;
          setSelectedDayCollectData(item);
        }
      }

      if (res?.day >= 1) {
        if (res?.day > 7 && item?.id == 8) {
          if (isIntervalData) {
            item.isTimerBtn = false;
            item.isCollectedBtn = false;
            item.isPrimaryCollectBtn = true;
            coinAllData.isTimerBtn = false;
            coinAllData.isPrimaryCollectBtn = true;
            setSelectedDayCollectData(item);
          } else {
            item.isTimerBtn = true;
            item.isCollectedBtn = false;
            item.isPrimaryCollectBtn = false;
            coinAllData.isTimerBtn = true;
            coinAllData.isPrimaryCollectBtn = false;
          }
        } else if (res?.day + 1 == item?.id) {
          if (isIntervalData) {
            item.isTimerBtn = false;
            item.isCollectedBtn = false;
            item.isPrimaryCollectBtn = true;
            coinAllData.isTimerBtn = false;
            coinAllData.isPrimaryCollectBtn = true;
            setSelectedDayCollectData(item);
          } else {
            item.isTimerBtn = true;
            item.isCollectedBtn = false;
            item.isPrimaryCollectBtn = false;
            coinAllData.isTimerBtn = true;
            coinAllData.isPrimaryCollectBtn = false;
          }
        } else {
          if (res?.day < item.id) {
            item.isCollectedBtn = false;
            item.isTimerBtn = false;
            item.isPrimaryCollectBtn = false;
          } else {
            item.isCollectedBtn = true;
            item.isTimerBtn = false;
            item.isPrimaryCollectBtn = false;
          }
        }
      }
    });
    setCoinAllData(coinAllData);
  };

  useEffect(() => {
    handleGetCoinData();
  }, [pageRefresh, isIntervalData]);

  const renderRewardTabContent = () => {
    if (isMobile)
      return (
        <>
          {getEarningHistoryCount === 0 ? (
            handleEmptyStateDiv()
          ) : (
            <>
              {getEarningHistoryData?.map((item, index) => (
                <div className="mobile-reward-history-item" key={index}>
                  <div className="mobile-reward-history-title">
                    <img
                      src={`/rewards/${item.reward?.reward_name}.svg`}
                      alt=""
                    />
                    <div className="mobile-reward-history-text-wrapper">
                      <h1>
                        {localStorage.getItem("language") === "ua"
                          ? item.reward?.title
                          : item.reward?.title_en}
                      </h1>
                      <div className="mobile-reward-history-level">
                        {t("rewards.level")}: {item?.reward.level}
                      </div>
                    </div>
                  </div>
                  <div className="mobile-reward-history-values">
                    <div className="mobile-reward-history-value">
                      <div className="mobile-reward-history-value-title">
                        {t("rewards.award")}
                      </div>
                      <div className="mobile-reward-history-value-data">
                        <img
                          className="walet-coins-value-img"
                          src="/CAP coin.png"
                          alt=""
                        />{" "}
                        +{item?.amount}
                      </div>
                    </div>
                    <div className="mobile-reward-history-value date-value">
                      <div className="mobile-reward-history-value-title">
                        {t("wallet.Date")}
                      </div>
                      <div className="mobile-reward-history-value-data">
                        {handleLanguageLocalization(item.unixTime, true)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      );

    return (
      <div>
        <div className="wallet-history-table-titles pb-2">
          <div className="reward-title">{t("rewards.reward")}</div>
          <div className="reward-level">{t("rewards.level")}</div>
          <div className="reward-award">{t("rewards.award")}</div>
          <div className="Date-table-Odds">{t("wallet.Date")}</div>
        </div>
        {/* empty state design */}
        {getEarningHistoryCount === 0 ? (
          handleEmptyStateDiv()
        ) : (
          <div>
            {getEarningHistoryData?.map((item, index) => (
              <div className="wallet-history-table-row" key={index}>
                {/* purchase-table data */}
                <div className="reward-title-data d-flex flex-wrap align-items-center">
                  <img
                    src={`/rewards/${item.reward?.reward_name}.svg`}
                    width={40}
                    height={"auto"}
                    alt=""
                  />
                  <div>
                    {localStorage.getItem("language") === "ua"
                      ? item.reward?.title
                      : item.reward?.title_en}
                  </div>
                </div>
                <div className="reward-level-data d-flex align-items-center">
                  {item?.reward.level}
                </div>
                <div className="reward-award-data d-flex align-items-center">
                  <img
                    className="walet-coins-value-img"
                    src="/CAP coin.png"
                    alt=""
                  />{" "}
                  +{item?.amount}
                </div>
                {/* collected-data date */}
                <div className="date-div reward-date-div">
                  <div className="date-div-data">
                    {handleLanguageLocalization(item.unixTime, true)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderCollectTabContent = () => {
    return (
      <div>
        <div className="collect-table-title-row pb-2">
          <div className="collect-table-title">{t("wallet.Activity")}</div>
          <div className="coins-amount-title">
            {isMobile ? t("wallet.amount") : t("wallet.CoinsAmount")}
          </div>
          <div className="Date-table-Odds">{t("wallet.Date")}</div>
        </div>
        <div>
          {getEarningHistoryData?.map((item, index) => (
            <div className="collect-table-data-row" key={index}>
              <div className="collect-activity-data d-flex flex-wrap">
                {item.collect
                  ? localStorage.getItem("language") == "en"
                    ? item?.collect?.activity_en?.split("(")[0]
                    : item?.collect?.activity_ua?.split("(")[0]
                  : ""}
                {item?.collect.activity_en?.split("(")[1] ? (
                  localStorage.getItem("language") == "en" ? (
                    <span className="day-sceduled d-flex ps-1">{` (${
                      item?.collect?.activity_en?.split("(")[1]
                    }`}</span>
                  ) : (
                    <span className="day-sceduled d-flex ps-1">{` (${
                      item?.collect?.activity_ua?.split("(")[1]
                    }`}</span>
                  )
                ) : (
                  ""
                )}
              </div>
              <div className="coin-amount-data">+ {item.amount}</div>
              {/* collected-data date */}
              <div className="date-div">
                <div className="date-div-data">
                  {handleLanguageLocalization(item.unixTime, true)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderEarnTabContent = () => {
    return (
      <div>
        <div className="Top-predictions-tabletitle pb-2">
          <div className="Eventdata-table-title">{t("wallet.Event")}</div>
          <div className="Staked-coins-title">{t("wallet.StakedCoins")}</div>
          <div className="Odd-table">{t("wallet.Odd")}</div>
          <div className="Earned-coins-table">{t("wallet.EarnedCoins")}</div>
          <div className="date-table">{t("wallet.Date")}</div>
        </div>
        {getEarningHistoryCount === 0 ? (
          handleEmptyStateDiv()
        ) : (
          <div>
            {getEarningHistoryData?.map((item, index) => (
              <div className="Top-predictions-main-row" key={index}>
                <div className="Top-predictions-row d-flex">
                  {/* Eventdata-table data */}
                  <div className="Eventdata-table-data">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <div className="">
                        <div className="both-team-league-time-sport d-flex">
                          <span className="d-flex align-items-center">
                            <img
                              src={
                                "/sport-logo/" +
                                item.earn?.event?.sport_id +
                                ".svg"
                              }
                              width="16px"
                              height="16px"
                              alt=""
                            />
                          </span>
                          {apiService.getProfileFromStorage()?.Language === "en"
                            ? item.earn?.event?.sport_name_en
                              ? item.earn?.event?.sport_name_en
                              : item.earn?.event?.sport_name
                            : item.earn?.event?.sport_name}
                          .{" "}
                          <span className="earning-coin-tournament_name">
                            {apiService.getProfileFromStorage()?.Language ===
                            "en"
                              ? item.earn?.event?.tournament_name_en
                                ? item.earn?.event?.tournament_name_en
                                : item.earn?.event?.tournament_name
                              : item.earn?.event?.tournament_name}
                          </span>
                          |{" "}
                          {handleLanguageLocalization(
                            item.earn?.event?.game_start
                          )}
                          ,{" "}
                          {moment
                            .unix(item.earn?.event?.game_start)
                            .format("HH: mm")}
                        </div>
                        <div className="d-flex align-items-center oc_teams_details">
                          <div className="both-Team-logo">
                            <span className="team1-logo">
                              <img
                                src={
                                  process.env.REACT_APP_teamLogo +
                                  item.earn?.event?.opp_1_icon
                                }
                                height="28px"
                                width="28px"
                                style={{ borderRadius: "50px" }}
                                alt=""
                              />
                            </span>
                            <span className="team2-logo">
                              <img
                                className="team-2-logo"
                                src={
                                  process.env.REACT_APP_teamLogo +
                                  item.earn?.event?.opp_2_icon
                                }
                                height="28px"
                                width="28px"
                                alt=""
                                style={{ borderRadius: "50px" }}
                              />
                            </span>
                          </div>
                          <div className="both-team-contains">
                            <div className="both-team-name">
                              <span>
                                {apiService.getProfileFromStorage()
                                  ?.Language === "en"
                                  ? item.earn?.event?.opp_1_name_en
                                    ? item.earn?.event?.opp_1_name_en
                                    : item.earn?.event?.opp_1_name
                                  : item.earn?.event?.opp_1_name}
                              </span>
                              <span>
                                {item.earn?.event?.game_score
                                  ? item.earn?.event?.game_score?.split("(")[0]
                                  : "-:-"}
                              </span>
                              <span>
                                {apiService.getProfileFromStorage()?.Language ==
                                "en"
                                  ? item.earn?.event?.opp_2_name_en
                                    ? item.earn?.event?.opp_2_name_en
                                    : item.earn?.event?.opp_2_name
                                  : item.earn?.event?.opp_2_name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {item.earn?.multipleOdds ? (
                        <div
                          className="date-div-data mt-2"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleTopPredictionModel(item.predictionId)
                          }
                        >
                          +{" "}
                          {item.earn?.multipleOdds
                            ? item.earn?.multipleOdds?.split(" ")[1] -
                              1 +
                              " " +
                              t("profile.events")
                            : ""}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* Staked-coins-data */}
                  <div className="Staked-coins-data largescreen-data">
                    {item.earn?.stakedCoins}
                  </div>
                  <div className="Odd-table-data largescreen-data">
                    {item.earn?.odd}
                  </div>
                  <div
                    className="Earned-coins-table-data largescreen-data"
                    style={{
                      color:
                        item.earn?.resultType === "Won"
                          ? "#208B8E"
                          : item.earn?.resultType === "Lost"
                          ? "#D14343"
                          : item.earn?.resultType === "Returned"
                          ? "#C09E16"
                          : "#131520",
                    }}
                  >
                    {(item.cashIn && "+" + item.amount) ||
                      (item.cashOut && "-" + item.amount)}
                  </div>
                  {/* collected-data date */}
                  <div className="date-div largescreen-data">
                    <div className="date-div-data">
                      {handleLanguageLocalization(item.unixTime, true)}
                    </div>
                  </div>
                  <div className="mobile-coin-odds-dateData w-100">
                    <div className="row">
                      <div className="col-6">
                        <div className="Staked-coins-title Top-predictions-tabletitle">
                          {t("wallet.StakedCoins")}
                        </div>
                        <div className="Staked-coins-data">
                          {item.earn?.stakedCoins}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="Top-predictions-tabletitle">
                          {t("wallet.Odd")}
                        </div>
                        <div className="Staked-coins-data">
                          {item.earn?.odd}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="Top-predictions-tabletitle pt-3">
                          {t("wallet.EarnedCoins")}
                        </div>
                        <div
                          className="Staked-coins-data"
                          style={{
                            color:
                              item.earn?.resultType == "Won"
                                ? "#208B8E"
                                : item.earn?.resultType == "Lost"
                                ? "#D14343"
                                : item.earn?.resultType == "Returned"
                                ? "#C09E16"
                                : "#131520",
                          }}
                        >
                          {(item.cashIn && "+" + item.amount) ||
                            (item.cashOut && "-" + item.amount)}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="Top-predictions-tabletitle pt-3">
                          {t("wallet.Date")}
                        </div>
                        <div className="date-div p-0">
                          <div className="date-div-data">
                            {handleLanguageLocalization(item.unixTime, true)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderSpentTabContent = () => {
    return (
      <div>
        <div className="spent-table-title-row pb-2">
          <div className="spent-table-title">{t("wallet.Purchase")}</div>
          <div className="spent-coins-title">{t("wallet.SpentCoins")}</div>
          <div className="spent-date">{t("wallet.Date")}</div>
        </div>
        {/* empty state design */}
        {getEarningHistoryCount == 0 ? (
          handleEmptyStateDiv()
        ) : (
          <>
            {getEarningHistoryData?.map((item, index) => (
              <div className="spent-table-data-row" key={index}>
                <div className="spent-purchase-data d-flex flex-wrap align-items-center">
                  <img
                    src={item.spent?.productImgUrl}
                    width={32}
                    height={32}
                    alt=""
                  />
                  <div className="ps-2">{item.spent?.title}</div>
                </div>
                <div className="spent-coins-data d-flex align-items-center">
                  {item?.amount}
                </div>
                {/* collected-data date */}
                <div className="spent-date-div">
                  <div className="date-div-data">
                    {handleLanguageLocalization(item.unixTime, true)}
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  const renderTabContent = () => {
    if (activeTab === "spent") {
      return renderSpentTabContent();
    }
    if (activeTab === "reward") {
      return renderRewardTabContent();
    }
    if (activeTab === "collect") {
      return renderCollectTabContent();
    }
    if (activeTab === "earn") {
      return renderEarnTabContent();
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("wallet.metaTitle")}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <NavigationBar pageRefresh={pageRefresh} />
      <div className="wallet-conitener">
        <div className="wallet-section">
          {/* title subtitle */}
          <div className="wallet-title-tab">
            <div className="title pb-1">
              {t("wallet.MyCAPcoinsTitle")}
              <span className="ps-1">👋</span>
            </div>
            <div className="sub-title">{t("wallet.MyCAPcoinsSubTitle")}</div>
          </div>
          {/* card */}
          <div className="row">
            {/* balance-card +++++++++ */}
            <div className="col-lg-4 col-sm-12 col-md-6 p-0 setgradiant-background">
              <div className="balance-card row ms-3 me-2">
                <div className="col-lg-7 col-sm-7 col-6 balance-card-left-side p-0">
                  <div className="title pb-4">{t("wallet.Balance")}</div>
                  {/* usercoin */}
                  <div className="d-flex align-items-center pb-3">
                    <img src="/ul-iconimg.svg" alt="" />
                    <div className="user-coin-count ps-1">
                      {getTotalCoinData?.totalAmount || 0}
                    </div>
                    <div></div>
                  </div>
                  {/* right */}
                  <div className="d-flex">
                    <img
                      src="balanceArrow---Up.svg"
                      style={{
                        transform:
                          getTotalCoinData?.lastWeekAmount
                            ?.toString()
                            ?.charAt() === "-"
                            ? "rotate(180deg)"
                            : "",
                      }}
                      alt=""
                    />
                    <div className="last-week-coin-count ps-2">
                      {getTotalCoinData?.lastWeekAmount?.toString()?.charAt() ==
                      "-"
                        ? "" + getTotalCoinData?.lastWeekAmount
                        : "+ " + getTotalCoinData?.lastWeekAmount}{" "}
                      {t("wallet.coinsLastWeek")}
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-sm-5 col-6 p-0 d-flex justify-content-end align-items-center">
                  <img
                    src="/CAP coin full size.svg"
                    width={120}
                    height={120}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-8 collect-coin-earn-coin-card">
              <div className="row">
                {/* Collected coins:::::::::: */}
                <div className="col-lg-4 col-sm-12 col-md-4 p-0 Collected-coins-cards">
                  <div className="Collected-coins-card me-2 ms-2 h-100">
                    <div className="title pb-4">
                      {t("wallet.CollectedCoins")}
                    </div>
                    <div className="d-flex align-items-center pb-3">
                      <img src="/gray_ul_icon.svg" alt="" />
                      <div className="coin-count ps-1">
                        {getCollectCoinData?.totalAmount}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          getCollectCoinData?.lastWeekAmount
                            ?.toString()
                            ?.charAt() === "-"
                            ? "/lose arrow down.svg"
                            : "/win arrow up.svg"
                        }
                        alt=""
                      />
                      <div
                        className="last-week-coin-count ps-2"
                        style={{
                          color:
                            getCollectCoinData?.lastWeekAmount
                              ?.toString()
                              ?.charAt() === "-"
                              ? "#D14343"
                              : "#208B8E",
                        }}
                      >
                        {getCollectCoinData?.lastWeekAmount
                          ?.toString()
                          ?.charAt() === "-"
                          ? "" + getCollectCoinData?.lastWeekAmount
                          : "+ " + getCollectCoinData?.lastWeekAmount}{" "}
                        {t("wallet.coinsLastWeek")}
                      </div>
                    </div>
                  </div>
                </div>
                {/* last */}
                <div className="col-lg-4 col-sm-12 col-md-4 p-0 earn-coins-card">
                  <div className="Earned-coins mx-2 h-100">
                    <div className="title pb-4">{t("wallet.EarnedCoins")}</div>
                    <div className="d-flex align-items-center pb-3">
                      <img src="/gray_ul_icon.svg" alt="" />
                      <div className="coin-count ps-1">
                        {getEarnCoinData?.totalAmount || 0}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          getEarnCoinData?.lastWeekAmount
                            ?.toString()
                            ?.charAt() === "-"
                            ? "/lose arrow down.svg"
                            : "/win arrow up.svg"
                        }
                        alt=""
                      />
                      <div
                        className="last-week-coin-count ps-2"
                        style={{
                          color:
                            getEarnCoinData?.lastWeekAmount
                              ?.toString()
                              ?.charAt() === "-"
                              ? "#D14343"
                              : "#208B8E",
                        }}
                      >
                        {getEarnCoinData?.lastWeekAmount
                          ?.toString()
                          ?.charAt() === "-"
                          ? "" + getEarnCoinData?.lastWeekAmount
                          : "+ " + getEarnCoinData?.lastWeekAmount}{" "}
                        {t("wallet.coinsLastWeek")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 col-md-4 p-0 earn-coins-card">
                  <div className="Earned-coins spent-coin ms-2 h-100">
                    <div className="title pb-4">{t("wallet.SpentCoins")}</div>
                    <div className="d-flex align-items-center pb-3">
                      <img src="/gray_ul_icon.svg" alt="" />
                      <div className="coin-count ps-1">
                        {getSpentCoinData?.totalAmount || 0}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          getSpentCoinData?.lastWeekAmount
                            ?.toString()
                            ?.charAt() === "-"
                            ? "/lose arrow down.svg"
                            : "/win arrow up.svg"
                        }
                        alt=""
                      />
                      <div
                        className="last-week-coin-count ps-2"
                        style={{
                          color:
                            getSpentCoinData?.lastWeekAmount
                              ?.toString()
                              ?.charAt() === "-"
                              ? "#D14343"
                              : "#208B8E",
                        }}
                      >
                        {getSpentCoinData?.lastWeekAmount
                          ?.toString()
                          ?.charAt() === "-"
                          ? "" + getSpentCoinData?.lastWeekAmount
                          : "+ " + getSpentCoinData?.lastWeekAmount}{" "}
                        {t("wallet.coinsLastWeek")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* collect coin div */}
          <div className="">
            <div className="collect-cpa-coin-title">
              <div className="title pb-1">
                {t("wallet.collectCoinEveryDayTitle")}
              </div>
              <div className="sub-title">
                {t("wallet.collectCoinEveryDaySubTitle")}
              </div>
            </div>
            {/* coins - card */}
            <div className="d-flex flex-wrap justify-content-between">
              {/* coin-card */}
              {coinAllData?.map((item, index) => (
                <div className="daily-coin-card col-4" key={index}>
                  <img
                    src="/true sign.svg"
                    className="mobile-coin-collected-btn-sign"
                    style={{
                      display: isMobileSize
                        ? item.isCollectedBtn
                          ? "block"
                          : "none"
                        : "none",
                    }}
                    alt=""
                  ></img>
                  <div
                    className="daily-coin-box"
                    style={{
                      backgroundColor: isMobileSize
                        ? item.isCollectedBtn
                          ? "#FFFFFF"
                          : item.isPrimaryCollectBtn
                          ? "#F7FDFD"
                          : item.isTimerBtn
                          ? "#F7FDFD"
                          : ""
                        : "",
                      border: isMobileSize
                        ? item.isCollectedBtn
                          ? "1px solid #208B8E"
                          : item.isPrimaryCollectBtn
                          ? "1px solid #208B8E"
                          : item.isTimerBtn
                          ? "1px solid #208B8E"
                          : ""
                        : "",
                    }}
                  >
                    <div className="day-title text-center">{item.day}</div>
                    <div className="d-flex justify-content-center pb-2">
                      <img
                        src={item.icon}
                        className="text-center card-coin-image"
                        alt=""
                      />
                    </div>
                    <div className="coin-qty text-center">{item.coinValue}</div>
                  </div>
                  {/* buttons */}
                  <div className="mt-3 large-screen-coin-btn">
                    {/* CollectCoinsBtn */}
                    {item?.isPrimaryCollectBtn ? (
                      <InputButton
                        type={"primary"}
                        addBtnClass={"collect-coin-btn"}
                        inputLabel={
                          isTebletSize
                            ? t("wallet.Collect")
                            : t("wallet.CollectCoinsBtn")
                        }
                        onClick={() => handleCollectCoinsModel(item)}
                      />
                    ) : item.isCollectedBtn ? (
                      <InputButton
                        type="icon-button"
                        inputLabel={t("wallet.CollectedBtn")}
                        addBtnClass={"collected-btn"}
                        addSpan={
                          <span>
                            <img src="/wallet-coin/checkmark.svg" alt="" />
                          </span>
                        }
                      />
                    ) : (
                      ""
                    )}
                    {item.isTimerBtn ? (
                      <InputButton
                        type={"filter-button"}
                        addBtnClass="to-collected-btn"
                        inputLabel={`${timeRemaining}  ${
                          isTebletSize ? "" : t("wallet.toCollectBtn")
                        }`}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}
              {/* mobile - btn */}
              <div className="mobile-collect-coin-btn w-100">
                {coinAllData.isTimerBtn ? (
                  <InputButton
                    type={"filter-button"}
                    addBtnClass="to-collected-btn"
                    inputLabel={`${timeRemaining}  ${
                      isTebletSize ? "" : t("wallet.toCollectBtn")
                    }`}
                  />
                ) : coinAllData.isPrimaryCollectBtn ? (
                  <InputButton
                    type={"primary"}
                    addBtnClass={"collect-coin-btn"}
                    inputLabel={
                      isTebletSize
                        ? t("wallet.Collect")
                        : t("wallet.CollectCoinsBtn")
                    }
                    onClick={() =>
                      handleCollectCoinsModel(selectedDayCollectData)
                    }
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <WalletRewardsSection />
          {/* Earning history tab*/}
          <div>
            <div className="Earning-history-title">
              <div className="title pb-1">
                {t("wallet.EarningHistoryTitle")}
              </div>
              <div className="sub-title">
                {t("wallet.EarningHistorySubTitle")}
              </div>
            </div>
            {/* tab +++++++++= */}
            <div
              className={`earning-history-tab ${
                localStorage.getItem("language") == "ua"
                  ? "earning-ua-history-tab"
                  : ""
              }`}
            >
              {isMobile ? (
                <DropDownInput
                  listItems={TAB_OPTIONS}
                  selectedItem={activeDropDownTab}
                  setSelectedItem={setActiveDropDownTab}
                />
              ) : (
                <div className="wallet-tabs-section">
                  <div
                    className={
                      activeTab === "collect"
                        ? "wallet-tabs-item active"
                        : "wallet-tabs-item"
                    }
                    onClick={() => handleActiveTab("collect")}
                  >
                    {t("wallet.EarnCollectedCoinsTab")}
                  </div>
                  <div
                    className={
                      activeTab === "earn"
                        ? "wallet-tabs-item active"
                        : "wallet-tabs-item"
                    }
                    onClick={() => handleActiveTab("earn")}
                  >
                    {t("wallet.EarnedCoinsTab")}
                  </div>
                  <div
                    className={
                      activeTab === "spent"
                        ? "wallet-tabs-item active"
                        : "wallet-tabs-item"
                    }
                    onClick={() => handleActiveTab("spent")}
                  >
                    {t("wallet.SpentCoins")}
                  </div>
                  <div
                    className={
                      activeTab === "reward"
                        ? "wallet-tabs-item active"
                        : "wallet-tabs-item"
                    }
                    onClick={() => handleActiveTab("reward")}
                  >
                    {t("rewards.metaTitle")}
                  </div>
                </div>
              )}
              <div className="wallet-tabs-content">
                {!isHistoryLoaded ? (
                  <WalletHistoryLoader />
                ) : (
                  renderTabContent()
                )}
                {isHistoryLoaded && (
                  <div
                    style={{
                      display: getEarningHistoryCount === 0 ? "none" : "block",
                    }}
                  >
                    {getEarningHistoryCount >= 5 && handleShowMoreBtn()}
                    {showMessage != "" || getEarningHistoryCount <= 5 ? (
                      <div className="show-boTTom-message my-5">
                        {showMessage}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
              {/* model::::::::::::: */}
              <Model
                isModel={collectCoinModel}
                hideModel={() => setCollectCoinModel(false)}
                modelClassName={"collect-coin-model-div"}
              >
                <div>
                  <div className="d-flex justify-content-center pb-4">
                    <img
                      src={`/wallet-coin/model-coin-icon/${selectedCoinData.id}.png`}
                      width={200}
                      height={200}
                      alt=""
                    />
                  </div>
                  {/* <Ad /> */}
                  <div className="text-center coin-model-collected-message pb-2">
                    {t("wallet.modelTitle") +
                      " " +
                      selectedCoinData.coinValue?.split(" ")[1] +
                      " CAP" +
                      " " +
                      t("wallet.coins")}{" "}
                  </div>
                  <div className="coin-model-subtitle text-center pb-4">
                    {t("wallet.comeBackMessage")}
                  </div>
                  <div className="d-flex justify-content-center">
                    <InputButton
                      type="primary"
                      addBtnClass={"thankYou-btn"}
                      onClick={handleCollectCoinData}
                      inputLabel={t("wallet.OkThanks")}
                      disabled={buttonDisableData}
                    />
                  </div>
                </div>
              </Model>
              {/* top predition */}
              <TopPredictionListDetailsModel
                isWalletModel={true}
                addModelclass={"wallet-model-container"}
                predictionDetail={predictionDetail}
                hide={handleTopPredictionModelHide}
                isModel={modelShow}
              />
            </div>
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="walletFooter">
        <HomePageFooter />
      </div>
    </>
  );
};
export default Wallet;
