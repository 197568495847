import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDistanceToNow } from "date-fns";
import { uk, enUS } from "date-fns/locale";

import io from "socket.io-client";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

import UserAvatar from "../../UserAvatar";
import Model from "../../molecule/model/Model";

import { prepareUserName } from "../../../helpers/formater";

import "./Chat.css";
import ChatMessage from "./components/ChatMessage";

const socket = io(process.env.REACT_APP_Url, {
  extraHeaders: {
    ...(localStorage.getItem("token")
      ? { Authorization: "Bearer " + localStorage.getItem("token") }
      : {}),
  },
});

const PAGE_SIZE = 50;
const MESSAGE_LIMIT = 500;

const Chat = ({ gameId, user }) => {
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("language") === "en" ? enUS : uk;

  const messagesContainerRef = useRef(null);
  const emojiRef = useRef(null);
  const textareaRef = useRef(null);
  const emojiButtonRef = useRef(null);

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isTextareaActive, setIsTextareaActive] = useState(false);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);

  const [isMessageLimit, setIsMessageLimit] = useState(false);
  const [isSentActive, setIsSentActive] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editMsgId, setEditMsgId] = useState(null);
  const [removeMsgId, setRemoveMsgId] = useState(null);
  const [parentMessage, setParentMessage] = useState(null);

  const [showRemoveMsgModal, setShowRemoveMsgModal] = useState(false);

  useEffect(() => {
    socket.emit("joinGame", gameId);

    socket.on("loadMessages", (messages) => {
      setMessages(messages);
    });

    socket.on("message", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    socket.on("editMessage", (updatedMessage) => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg._id === updatedMessage._id ? updatedMessage : msg
        )
      );
    });

    socket.on("deleteMessage", (deletedMessageId) => {
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg._id !== deletedMessageId)
      );
    });

    return () => {
      socket.disconnect();
    };
  }, [gameId]);

  useEffect(() => {
    const messageLength = message.length;
    console.log(messageLength);

    const isMessageExceedLimit = messageLength > MESSAGE_LIMIT;
    const isMessageNonEmpty = messageLength > 0;

    setIsSentActive(isMessageNonEmpty && !isMessageExceedLimit);
    setIsMessageLimit(isMessageExceedLimit);
  }, [message]);

  const handleClickOutside = (event) => {
    const clickedOutsideTextarea =
      textareaRef.current && !textareaRef.current.contains(event.target);
    const clickedOutsideEmojiButton =
      emojiButtonRef.current && !emojiButtonRef.current.contains(event.target);
    const clickedOutsideEmojiPicker =
      emojiRef.current && !emojiRef.current.contains(event.target);

    if (
      clickedOutsideTextarea &&
      clickedOutsideEmojiButton &&
      (clickedOutsideEmojiPicker || clickedOutsideEmojiPicker === null) &&
      message.length === 0
    ) {
      setIsTextareaActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [message]);

  const sendMessage = () => {
    if (message.trim()) {
      if (isEdit) {
        socket.emit("editMessage", {
          gameId,
          newContent: message,
          messageId: editMsgId,
        });

        setIsEdit(false);
      } else {
        socket.emit("sendMessage", {
          gameId,
          message,
        });
      }

      setIsTextareaActive(false);
      setIsEmojiOpen(false);
      setMessage("");
    }
  };

  const onEmojiClick = (emojiObject) => {
    setMessage((prevInput) => prevInput + emojiObject.native);
  };

  const handleFocus = () => {
    setIsTextareaActive(true);
  };

  const handleDeleteMsg = () => {
    socket.emit("deleteMessage", { messageId: removeMsgId, gameId });
    setShowRemoveMsgModal(false);
  };

  const handleEditMsg = (msg) => {
    setMessage(msg.message);
    setEditMsgId(msg._id);
    setIsEdit(true);
    setIsTextareaActive(true);
  };

  const handleCancelEdit = () => {
    setMessage("");
    setIsEdit(false);
    setIsTextareaActive(false);
  };

  const handleEmojiClick = (e) => {
    e.stopPropagation();
    setIsTextareaActive(true);

    setIsEmojiOpen((prevState) => !prevState);
  };

  return (
    <div className="chat__wrapper">
      <div className="chat__messages-section" ref={messagesContainerRef}>
        {messages.map((msg, index) => (
          <div className="chat__message-wrapper" key={index}>
            <UserAvatar user={msg.userId} />
            <div className="message__msg-content">
              <div className="message__msg-title">
                <div className="message__msg-username">
                  {prepareUserName(msg.userId)}
                </div>
                <div className="chat__separator">•</div>
                <div className="message__msg-date">
                  {formatDistanceToNow(new Date(msg.timestamp), {
                    addSuffix: true,
                    locale: lang,
                  })}
                </div>
              </div>
              <ChatMessage msg={msg} />
              <div className="message__msg-controls">
                {user?._id === msg.userId._id ? (
                  <>
                    <div onClick={() => handleEditMsg(msg)}>
                      {t("chat.edit")}
                    </div>
                    <div className="chat__separator">•</div>
                    <div
                      onClick={() => {
                        setShowRemoveMsgModal(true);
                        setRemoveMsgId(msg._id);
                      }}
                    >
                      {t("chat.delete")}
                    </div>
                  </>
                ) : (
                  <div onClick={() => setParentMessage(msg)}>
                    {t("chat.replay")}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {parentMessage && (
        <div className="chat__replayTo-wrapper">
          <div className="chat__replayTo-title">{t("chat.replayTo")}</div>
          <div className="chat__replayTo-user">
            {prepareUserName(parentMessage.userId)}
          </div>
        </div>
      )}
      <div className={`chat__input-form ${isTextareaActive ? "active" : ""}`}>
        <div className="chat__input-wrapper">
          <UserAvatar user={user} />
          <textarea
            ref={textareaRef}
            type="text"
            className="chat__input"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onFocus={handleFocus}
            placeholder="Що у вас на думці?"
          />
        </div>

        {isEmojiOpen && (
          <div className="emoji-wrapper" ref={emojiRef}>
            <Picker
              emojiButtonSize={48}
              emojiSize={24}
              perLine={8}
              maxFrequentRows={2}
              locale="uk"
              theme="light"
              data={data}
              previewPosition="none"
              onEmojiSelect={onEmojiClick}
              onClickOutside={() => setIsEmojiOpen(false)}
            />
          </div>
        )}

        {/* <ReactTinyLink
          cardSize="small"
          showGraphic={true}
          maxLine={2}
          minLine={1}
          url="https://www.youtube.com/watch?v=RckYbL7fH1k&themeRefresh=1"
        /> */}

        {isTextareaActive && (
          <div
            className={
              isMessageLimit
                ? "chat__message-limit limited"
                : "chat__message-limit"
            }
          >{`${message.length}/${MESSAGE_LIMIT}`}</div>
        )}
        <div className="chat__input-controls">
          {isTextareaActive && (
            <div
              ref={emojiButtonRef}
              className={
                isEmojiOpen ? "chat__emoji_btn active" : "chat__emoji_btn"
              }
              onClick={(e) => handleEmojiClick(e)}
            >
              <img src="/Smiling.svg" alt="smiling" />
            </div>
          )}
          <div className="chat__input-controls-btn">
            {isEdit && (
              <div
                className="chat__cancel_btn cancel-btn"
                onClick={() => handleCancelEdit()}
              >
                {t("chat.cancel")}
              </div>
            )}
            <div
              className="chat__send_btn primary-btn"
              onClick={sendMessage}
              style={{
                opacity: !isSentActive && isTextareaActive ? "60%" : "",
                pointerEvents: !isSentActive && isTextareaActive ? "none" : "",
              }}
            >
              {isEdit ? t("chat.save") : t("chat.sent")}
            </div>
          </div>
        </div>
      </div>
      {/* <CustomInput
        placeholder="Type something here..."
        message={message}
        setMessage={setMessage}
      /> */}

      <Model
        isModel={showRemoveMsgModal}
        modelClassName={"remove-game-msg__modal"}
        hideModel={() => setShowRemoveMsgModal(false)}
        modelTitle={t("chat.removeMsg")}
      >
        <div className="chat-modal__wrapper">
          <div className="chat-modal__warning-text">
            {t("chat.removeMsgWarning")}
          </div>
          <div className="chat-modal__controls">
            <div className="primary-btn" onClick={() => handleDeleteMsg()}>
              {t("chat.delete")}
            </div>
            <div
              className="cancel-btn"
              onClick={() => setShowRemoveMsgModal(false)}
            >
              {t("chat.cancel")}
            </div>
          </div>
        </div>
      </Model>
      {/* 
      {showUpdateNotification && (
        <AlertNotification
          message={t("gameDetail.videoReview.videoUpdated")}
          onClose={() => setShowUpdateNotification(false)}
          duration={2000}
          isVisible={showUpdateNotification}
        />
      )} */}
    </div>
  );
};

export default Chat;
