import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { eventBus } from "../../services/events";
import { CONSTANT } from "../../common/constant";
import { Helmet } from "react-helmet";

import apiService from "../../services/apiService";
import Header from "../Header";

import "../../css/login.css";

const Login = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState();

  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [getUserName, setGetUserName] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [errorClass, setErrorClass] = useState("mb-3");
  const [error, setError] = useState("form-error-line-hide");
  const [profileImgUrl, setProfileImgUrl] = useState("");

  useEffect(() => {
    document.body.classList.add("login-containt");

    if (location.state == null) {
      navigate("/signin");
    } else {
      setEmail(location.state?.email);
      setUserName(location.state?.userName);
      setGetUserName(location.state?.name);
      setProfileImgUrl(location.state?.profileImgUrl);
    }
  }, []);

  const string = getUserName?.charAt(0);

  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (email === "" || password === "") {
      return;
    }

    eventBus.emit(CONSTANT.SHOW_LOADER);
    const res = await apiService.loginApi({ email, password });
    eventBus.emit(CONSTANT.HIDE_LOADER);

    if (!res.data) {
      setErrorClass("mb-3 form-error");
      setError("form-error-line-show");
    } else if (!res.data.user || !res.data.user.userName) {
      navigate(`/register?token=${res.data.Token}&id=${res.data.user._id}`);
    } else {
      navigate("/userprofile");
    }
  };

  const handlePasswordError = (e) => {
    if (e.target.value === "") {
      setErrorClass("mb-3");
      setError("form-error-line-hide");
    }
    setPassword(e.target.value);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("logIn.tabTitle")}</title>
      </Helmet>
      <Header />
      <section className="form-section">
        <div className="">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="card login-card">
              <div className="card-body p-0">
                <div className="login-avatar mt-0">
                  <div
                    className="profile-avtar rounded-circle d-flex align-items-center justify-content-center"
                    alt="Avatar"
                  >
                    {profileImgUrl !== "" ? (
                      <img
                        src={profileImgUrl}
                        value={profileImgUrl}
                        className="navbar-uploaded-img"
                        alt="avatar"
                        width="44px"
                        height="44px"
                        style={{ display: "block" }}
                      />
                    ) : (
                      ""
                    )}
                    {profileImgUrl === "" ? (
                      <p className="useName-letter">{string}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="card-title ">
                  <div className="welcome-carl text-center mt-4">
                    <p className="mb-2">
                      {t("logIn.Welcome")} {getUserName}!
                    </p>
                  </div>
                  <p className="text-center mb-4 login-email">
                    {email}
                    <Link to="/signup" className="login-change-email ms-1">
                      {location?.state?.userName === ""
                        ? t("logIn.Changeemail")
                        : t("logIn.ChangeUserName")}
                    </Link>
                  </p>
                </div>
                <form className="login-pass-form">
                  <div className="mb-3">
                    <div className="login-input-label">
                      <label
                        htmlFor="exampleInputPassword"
                        className="form-label form-input-label"
                      >
                        {t("logIn.YourPassword")}
                      </label>
                      <Link
                        to="/resetpassword"
                        htmlFor="exampleInputPassword"
                        className="form-label form-input-label forgot-password"
                      >
                        {t("logIn.ForgotPassword")}
                      </Link>
                    </div>
                    <div className={errorClass}>
                      <div className="input-icons">
                        <input
                          className="input-field form-control form-input-field"
                          type={type}
                          id="exampleInputPassword"
                          name="password"
                          value={password}
                          onChange={handlePasswordError}
                          placeholder={t("logIn.Enteryourpassword")}
                          style={{
                            fontSize: "14px",
                            fontWeight: "300",
                            color: "#7F87B3",
                          }}
                        />
                        <span className="toggle-icon" onClick={handleToggle}>
                          <i
                            className={
                              type === "password"
                                ? "fa fa-eye-slash"
                                : "fa fa-eye"
                            }
                          ></i>
                        </span>
                      </div>
                      <p style={{ fontSize: "14px" }} className={error}>
                        {t("logIn.ErrorMsg")}
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <button
                      className="form-control mb-0 form-input-field-button"
                      id="exampleInputbutton"
                      onClick={handleLogin}
                      disabled={password === ""}
                      style={{
                        pointerEvents: password === "" ? "none" : "auto",
                      }}
                    >
                      {t("logIn.LogIn")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;
