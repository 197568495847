import React, { useEffect } from "react";
import "./AlertNotification.css";

const AlertNotification = ({
  message,
  onClose,
  duration = 3000,
  isVisible,
  removed = false,
}) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [isVisible, duration, onClose]);

  return (
    <div className={`alert-notification ${isVisible ? "show" : ""}`}>
      <img src={removed ? "/alert/Delete.svg" : "/alert/good.svg"} alt="about" />
      <span>{message}</span>
    </div>
  );
};

export default AlertNotification;
