import React, { useEffect, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import _ from "lodash";

import NavigationBar from "./Navbar";
import "../css/feed.css";
import FeedSideBar from "./molecule/feed-sidebar/FeedSideBar";
import SearchBar from "./molecule/searchBar/SearchBar";
import InputButton from "./molecule/InputButton";
import PredictionList from "./molecule/prediction-list/PredictionList";
import RangeSlider from "./molecule/rangeSlider/RangeSlider";
import apiService from "../services/apiService";
import { Accordion } from "react-bootstrap";
import { PredictionType } from "../json/allData";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import PredictionListCustomLoader from "./Custom loader/PredictionListCustomLoader";
import FeedPredictionListLoader from "./Custom loader/FeedPredictionListLoader";
import MobilePredictionListLoader from "./Custom loader/MobilePredictionListLoader";
import { Helmet } from "react-helmet";
import HomePageFooter from "./molecule/homepage/HomePageFooter/HomePageFooter";
import Ad from "./General/Ad";
import Ad2 from "./General/Ad2";
import NoPredictionList from "./molecule/prediction-list/NoPredictionList";
import PredictionsListLoader from "./CustomSkeletonLoaders/PredictionsListLoader";

const Feed = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { t, i18n } = useTranslation();
  const pageSize = 10;
  const navigate = useNavigate();

  const [isFollowing, setIsFollowing] = useState(false);
  const [currPage, setCurrPage] = useState(0);
  const [pageState, setPageState] = useState(0);
  const listInnerRef = useRef();
  const [filteredName, setFileteredName] = useState(t("feed.Newest"));
  const [dropDownHideShow, setDropDownHideShow] = useState(false);
  const [manageFilter, setManageFilter] = useState(false);
  const [filterBtnActive, setFilterBtnActive] = useState("filter-btn");
  const menuRef = useRef(null);
  const [sportShow, setSportShow] = useState(false);
  const [filterSportBtn, setFilterSportBtn] = useState(false);
  const [sportData, setSportData] = useState([]);
  const [sportEmptyMsg, setSportEmptyMsg] = useState(false);
  const [predictionTypeEmptyMsg, setPredictionTypeEmptyMsg] = useState(false);
  const [tournamentEmptyMsg, setTournamentEmptyMsg] = useState(false);
  const [filterSportData, setFilterSportData] = useState({});
  const [tournamentShow, setTournamentShow] = useState(false);
  const [popularLeagues, setPopularLeagues] = useState([]);
  const [countryLeaguesData, setCountryLeaguesData] = useState([]);
  const [filterTournamentData, setTournamentSportData] = useState("");
  const [filterTournamentBtn, setFilterTournamentSportBtn] = useState(false);
  const [PredictionTypeShow, setPredictionTypeShow] = useState(false);
  const [predictionModel, setPredictionModle] = useState(false);
  // const [predictionData, setPredictionData] = useState({})
  const [filterPredictionTypeData, setFilterPredictionTypeData] = useState([]);
  const [selectedPredictionType, setSelectedPredictionType] = useState("");
  const [showMessage, setShowMessage] = useState("");
  const [filterPredictionTypeBtn, setFilterPredictionTypeSportBtn] =
    useState(false);
  const [filterStatusBtn, setFilterStatusBtn] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [RecomendedBtn, setRecomendedBtn] = useState(
    "Recomended-following-sidebar-btn-active"
  );
  const [followingBtn, setFollowingBtn] = useState(
    "Recomended-following-sidebar-btn"
  );
  const [statusShow, setStatusShow] = useState(false);
  const [OddsRange, setOddsRange] = useState(false);
  const [filterOddsRangeBtn, setFilterOddsRangeSportBtn] = useState(false);
  const [listening, setListening] = useState(false);
  const [betsData, setBetsData] = useState([]);
  const [initialIndex, setInitialIndex] = useState(0);
  const [countData, setCountData] = useState(0);
  const [sliderMinRange, setSliderMinRange] = useState(1.05);
  const [sliderMaxRange, setSliderMaxRange] = useState(20.05);
  const [alertMsg, setAlertMsg] = useState(false);
  const [typeAlertMsg, setTypeAlertMsg] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [popularLeague, setPopularLeague] = useState("");
  const [recommandBtnBorder, setRecomendedBtnBorder] = useState("#131520");
  const [followingBtnBorder, setFollowingBtnBorder] = useState("#7F87B3");
  const location = useLocation();
  const [handleHomeSport, setHandleHomeSport] = useState({});
  const [totalBetsData, setTotalBetsData] = useState("");
  const [showPredictionLoader, setShowPredictionLoader] = useState(true);
  const [showLoadMoreLoader, setShowLoadMoreLoader] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/signin");
    }
  }, []);

  const filterHandle = (data) => {
    setPopularLeague(data);
    if (data) {
      if (data) {
        setManageFilter(true);
        setFilterSportBtn(true);
        setFilterTournamentSportBtn(true);
        setFilterBtnActive("filter-btn filter-btn-active");
      } else {
        setFilterSportBtn(false);
        setFilterTournamentSportBtn(false);
      }
      setCurrPage(0);
      setFilterData((s) => {
        return {
          ...s,
          sport_id: data.sport_id,
          tournament_id: data.tournament_id,
        };
      });
      increamentUserCount();
    }
  };

  const HandleHomePageSportFilter = () => {
    if (location.state?.data) {
      if (Object.keys(location.state?.data).length >= 1) {
        setHandleHomeSport(location.state?.data);
        if (location.state?.data) {
          if (location.state?.data) {
            setManageFilter(true);
            setFilterSportBtn(true);
            setFilterBtnActive("filter-btn filter-btn-active");
          } else {
            setFilterSportBtn(false);
            setFilterTournamentSportBtn(false);
          }
          setCurrPage(0);
          setFilterData((s) => {
            return { ...s, sport_id: location.state?.data.id };
          });
          // increamentUserCount()
        }
      }
    }
  };

  useEffect(() => {
    filterHandle();
    HandleHomePageSportFilter();
  }, []);

  const increamentUserCount = () => {
    setCountData(countData + 1);
  };

  function listenForOutsideClicks() {
    return () => {
      if (listening) return;
      if (!menuRef.current) return;
      setListening(true);
      ["mousedown"].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          if (menuRef?.current?.contains(evt.target)) {
          } else {
            setDropDownHideShow(false);
          }
        });
      });
    };
  }

  useEffect(listenForOutsideClicks(), [dropDownHideShow]);

  useEffect(() => {
    document.body.classList.add("feed-containt");
  }, []);

  const handleFilterDropDown = () => {
    if (dropDownHideShow) {
      setDropDownHideShow(false);
    } else {
      setDropDownHideShow(true);
    }
  };

  const handleFilterData = async (name) => {
    setCurrPage(0);
    
    setFilterData((s) => {
      return { ...s, sortBy: name };
    });
    setFileteredName(name);
    if (dropDownHideShow) {
      setDropDownHideShow(false);
    } else {
      setDropDownHideShow(true);
    }
    increamentUserCount();
  };

  const handleFilters = () => {
    if (manageFilter) {
      setManageFilter(false);
      setFilterBtnActive("filter-btn");
      setSportShow(false);
      setTournamentShow(false);
      setPredictionTypeShow(false);
      setStatusShow(false);
      setOddsRange(false);
    } else {
      setManageFilter(true);
      setFilterBtnActive("filter-btn filter-btn-active");
    }
  };

  // filter sport
  const handleFliterSport = async () => {
    if (sportShow) {
      setSportShow(false);
    } else {
      setSportShow(true);
      setTournamentShow(false);
      setPredictionTypeShow(false);
      setStatusShow(false);
      setOddsRange(false);
      const res = await apiService.getAllSports({});
      setSportData(res);
    }
    // increamentUserCount()
  };
  const handleSportSearch = async (e) => {
    const res = await apiService.getAllSports({
      search: e.target.value,
    });
    setSportData(res);
    if (res.length < 1 && e.target.value != "") {
      setSportEmptyMsg(true);
    } else {
      setSportEmptyMsg(false);
    }
  };
  const handleSelectedSport = async (data) => {
    setSportShow(false);
    setFilterSportBtn(true);
    setFilterSportData(data);
    setCurrPage(0);
    setFilterData((s) => {
      return { ...s, sport_id: data.id };
    });
    increamentUserCount();
  };

  const handleSportHideShow = async () => {
    setFilterSportBtn(false);
    setCurrPage(0);
    setFilterData((s) => {
      if (s.sport_id) {
        delete s.sport_id;
      }
      return s;
    });
    handleTournamentHideShow();
    handlePredictionTypeHideShow();
    setCountryLeaguesData([]);
    setPopularLeagues([]);
    setFilterPredictionTypeData([]);
    setFilterSportData("");
    setTypeAlertMsg(true);
    setAlertMsg(true);
    increamentUserCount();
    setPopularLeague("");
    setHandleHomeSport({});
  };

  // filter tournament
  const handleFliterTournament = async () => {
    if (tournamentShow) {
      setTournamentShow(false);
    } else {
      // console.log("filterSportData:::::",filterSportData)
      setTournamentShow(true);
      setSportShow(false);
      setPredictionTypeShow(false);
      setStatusShow(false);
      setOddsRange(false);
      if (filterSportData && Object.keys(filterSportData).length > 0) {
        const arr = [];
        setAlertMsg(false);
        const res = await apiService.getLeagues({
          id: filterSportData.id,
          language: apiService.getProfileFromStorage().Language,
        });
        setPopularLeagues(res.popularLeagues);
        for (const data in res.leaguseByCountries) {
          arr.push(res.leaguseByCountries[data]);
        }
        if (filterSportData.id) {
          setCountryLeaguesData(arr);
        } else {
          setCountryLeaguesData([]);
          setPopularLeagues([]);
        }
      } else {
        setAlertMsg(true);
      }
    }
  };

  const handleTournamentHideShow = async () => {
    setFilterTournamentSportBtn(false);
    setCurrPage(0);
    setFilterData((s) => {
      if (s.tournament_id) {
        delete s.tournament_id;
      }
      return s;
    });
    setFilterPredictionTypeData([]);
    increamentUserCount();
  };

  const handleSelectedTournament = async (data) => {
    setTournamentSportData(data);
    setTournamentShow(false);
    setFilterTournamentSportBtn(true);
    setCurrPage(0);
    setFilterData((s) => {
      return { ...s, tournament_id: data.id };
    });
    increamentUserCount();
  };

  const handleTournamentSearch = async (e) => {
    const arr = [];
    const res = await apiService.getLeagues({
      id: filterSportData.id,
      language: apiService.getProfileFromStorage().Language,
      search: e.target.value,
    });
    for (const data in res.leaguseByCountries) {
      arr.push(res.leaguseByCountries[data]);
    }
    if (e.target.value == "") {
      setPopularLeagues(res.popularLeagues);
    } else {
      setPopularLeagues([]);
    }
    setCountryLeaguesData(arr);
    if (arr.length < 1 && e.target.value != "") {
      setTournamentEmptyMsg(true);
    } else {
      setTournamentEmptyMsg(false);
    }
  };

  // filter prediction type

  const handleFliterPredictionType = () => {
    if (!filterSportData.id) {
      setTypeAlertMsg(true);
    } else {
      setTypeAlertMsg(false);
    }
    if (PredictionTypeShow) {
      setPredictionTypeShow(false);
    } else {
      setPredictionTypeShow(true);
      setSportShow(false);
      setTournamentShow(false);
      setStatusShow(false);
      setOddsRange(false);
      let dataArray = [];
      // console.log("PredictionType.en:::", PredictionType.ua)
      if (localStorage.getItem("language") == "ua") {
        const prT = PredictionType.ua[filterSportData.id?.toString()];
        for (const data in prT) {
          dataArray.push(data);
        }
      } else {
        const prT = PredictionType.en[filterSportData.id?.toString()];
        for (const data in prT) {
          dataArray.push(data);
        }
      }
      // let SearchRegex = searchPredictionType
      // console.log("SearchRegex::::", SearchRegex)
      // let filteredData = dataArray?.filter(str => SearchRegex.includes(str))
      // console.log("filteredData :::", filteredData)

      if (filterSportData.id) {
        setFilterPredictionTypeData(dataArray);
      } else {
        setFilterPredictionTypeData([]);
      }
    }
  };

  const handlePredictionTypeSearch = (e) => {
    let dataArray = [];
    // console.log("PredictionType.en:::", PredictionType.ua)
    if (localStorage.getItem("language") == "ua") {
      const prT = PredictionType.ua[filterSportData.id?.toString()];
      for (const data in prT) {
        dataArray.push(data);
      }
    } else {
      const prT = PredictionType.en[filterSportData.id?.toString()];
      for (const data in prT) {
        dataArray.push(data);
      }
    }
    let SearchRegex = e.target.value;
    if (SearchRegex == "") {
      setFilterPredictionTypeData(dataArray);
    } else {
      let results = dataArray.filter(
        (str) => str.toLowerCase().indexOf(SearchRegex.toLowerCase()) !== -1
      );
      setFilterPredictionTypeData(results);
      if (results.length < 1 && e.target.value != "") {
        setPredictionTypeEmptyMsg(true);
      } else {
        setPredictionTypeEmptyMsg(false);
      }
    }
    increamentUserCount();
  };
  const handlePredictionTypeHideShow = () => {
    setFilterPredictionTypeSportBtn(false);
    setCurrPage(0);
    setFilterData((s) => {
      if (s.predictionType) {
        delete s.predictionType;
      }
      return s;
    });
    increamentUserCount();
  };

  const handleSelectedPredictionType = (item) => {
    const prT =
      localStorage.getItem("language") == "ua"
        ? PredictionType.ua[filterSportData.id.toString()]
        : PredictionType.en[filterSportData.id.toString()];
    const value = prT[item];
    setFilterPredictionTypeSportBtn(true);
    setPredictionTypeShow(false);
    setSelectedPredictionType(item);
    setCurrPage(0);
    setFilterData((s) => {
      return { ...s, predictionType: item };
    });
    increamentUserCount();
  };

  // filter status
  const handleFilterStatus = () => {
    if (statusShow) {
      setStatusShow(false);
    } else {
      setStatusShow(true);
      setPredictionTypeShow(false);
      setSportShow(false);
      setTournamentShow(false);
      setOddsRange(false);
    }
  };

  const handleStatusHideShow = () => {
    setFilterStatusBtn(false);
    setCurrPage(0);
    setFilterData((s) => {
      if (s.statusType) {
        delete s.statusType;
      }
      return s;
    });
    increamentUserCount();
  };
  const handleStutusType = (item, lang) => {
    setFilterStatusBtn(true);
    setSelectedStatus(lang);
    setStatusShow(false);
    setCurrPage(0);
    setFilterData((s) => {
      return { ...s, statusType: item };
    });
    increamentUserCount();
  };
  // odds range filter
  const handleFilterOddsRange = () => {
    if (OddsRange) {
      setOddsRange(false);
    } else {
      setOddsRange(true);
      setSportShow(false);
      setTournamentShow(false);
      setPredictionTypeShow(false);
      setStatusShow(false);
    }
  };
  const handleOddsRangeHideShow = () => {
    setFilterOddsRangeSportBtn(false);
    setCurrPage(0);
    setFilterData((s) => {
      if (s.oddMin) {
        delete s.oddMin;
      }
      if (s.oddMax) {
        delete s.oddMax;
      }
      return s;
    });
    setSliderMinRange(1.05);
    setSliderMaxRange(20.05);
    increamentUserCount();
  };
  const ApplyOddsRange = (min, max) => {
    setOddsRange(false);
    setFilterOddsRangeSportBtn(true);
    setCurrPage(0);
    setFilterData((s) => {
      return { ...s, oddMin: min, oddMax: max };
    });
    increamentUserCount();
  };
  const handleRangeSlider = ({ min, max }) => {
    setSliderMinRange(min);
    setSliderMaxRange(max);
  };
  // clear filter
  const handleClearFilter = () => {
    setFilterSportBtn(false);
    setFilterStatusBtn(false);
    setFilterTournamentSportBtn(false);
    setFilterPredictionTypeSportBtn(false);
    setFilterOddsRangeSportBtn(false);
    setCurrPage(0);
    setFilterData({});
    setFilterSportData({});
    setSliderMinRange(1.05);
    setSliderMaxRange(20.05);
    setHandleHomeSport({});
    increamentUserCount();
    // setSportData([])
    // setTypeAlertMsg(true)
    // console.log("cleared sport data")
  };

  const handleRecomendedBtn = async () => {
    setShowPredictionLoader(true);
    setCurrPage(0);
    setIsFollowing(false);

    setFilterData((s) => {
      if (s.following) {
        delete s.following;
      }
      return s;
    });
    if (RecomendedBtn) {
      setRecomendedBtn("Recomended-following-sidebar-btn-active");
      setFollowingBtn("Recomended-following-sidebar-btn");
      setRecomendedBtnBorder("#131520");
      setFollowingBtnBorder("#7F87B3");
    }
    setShowPredictionLoader(false);
    increamentUserCount();
  };

  const handleFollowingBtn = async () => {
    setShowPredictionLoader(true);
    setCurrPage(0);
    setIsFollowing(true);
    setFilterData((s) => {
      return {
        ...s,
        following: true,
        userId: apiService.getProfileFromStorage()?._id,
      };
    });
    if (followingBtn) {
      setFollowingBtn("Recomended-following-sidebar-btn-active");
      setRecomendedBtn("Recomended-following-sidebar-btn");
      setRecomendedBtnBorder("#7F87B3");
      setFollowingBtnBorder("#131520");
    }
    increamentUserCount();
    setShowPredictionLoader(false);
  };

  const handleSearchData = _.debounce((e) => {
    setCurrPage(0);
    if (e.target.value?.length == 0) {
      setShowMessage("");
    }

    setFilterData((s) => {
      return { ...s, search: e.target.value };
    });

    increamentUserCount();
  }, 300);

  const manageData = async (pageNumber = currPage || 0) => {
    if (pageNumber === 0) {
      setShowPredictionLoader(true);
    }

    setShowLoadMoreLoader(true);

    const res = await apiService.getPredictionOrder(
      filterData,
      pageSize,
      pageNumber
    );

    if (pageNumber === 0) {
      setBetsData(res.getPredictionOrder);
    } else {
      setBetsData((oldState) => [...oldState, ...res.getPredictionOrder]);
    }

    if (res) {
      setShowPredictionLoader(false);
    }

    const pages = Math.ceil(res.totalPredictionOrder / pageSize);
    setPageState(pages);
    // console.log(currPage, pageState, res.totalPredictionOrder);

    setTotalBetsData(res.totalPredictionOrder);
    if (pages == currPage + 1) {
      setShowMessage(t("feed.showMessage"));
    }
    setShowLoadMoreLoader(false);
  };

  const ShowMorePredictionsData = async () => {
    setCurrPage(currPage + 1);
  };
  useEffect(() => {
    manageData();
  }, [filterData, currPage]);

  const handleActiveSportLogo = () => {
    if (popularLeague) {
      return "/green-sport-logo/" + popularLeague.sport_id + ".svg";
    } else if (Object.keys(handleHomeSport).length >= 1) {
      return "/green-sport-logo/" + handleHomeSport.id + ".svg";
    } else {
      return "/green-sport-logo/" + filterSportData.id + ".svg";
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("feed.metaTitle")}</title>
        <meta name="description" content={t("feed.metaDescription")} />
        <link
          rel="alternate"
          href="https://capperauditor.com/feed"
          hreflang="uk"
        />
        <link
          rel="alternate"
          href="https://capperauditor.com/en/feed"
          hreflang="en"
        />
        <link
          rel="alternate"
          href="https://capperauditor.com/feed"
          hreflang="x-default"
        />
      </Helmet>
      <NavigationBar countData={countData} />
      <div className="prediction-list-container">
        <div className="prediction-list-section">
          <div className="row mx-0">
            <div className="col-xs-12 col-lg-3 col-md-3 feed-left-sidebar">
              <div className="box">
                <FeedSideBar
                  recommandBtnBorder={recommandBtnBorder}
                  followingBtnBorder={followingBtnBorder}
                  onClickRecomended={handleRecomendedBtn}
                  filterHandle={filterHandle}
                  onClickFollowingBtn={handleFollowingBtn}
                  countData={countData}
                  increamentUserCount={increamentUserCount}
                  recommandAddClass={RecomendedBtn}
                  followingAddClass={followingBtn}
                />
              </div>
              {!isMobile && (
                <>
                  <Ad />
                  <Ad2 />
                </>
              )}
            </div>
            <div className="col-xs-12 col-lg-9 col-md-9 feed-right-sidebar">
              <div className="box">
                <div className="filter-section">
                  <div className="filter-searchbar-section">
                    <SearchBar
                      addClass={"prediction-list-searchbar"}
                      inputPlaceHolder={t("profile.searchBarPlaceHolder")}
                      onChange={handleSearchData}
                    />
                    <InputButton
                      type="icon-button"
                      addBtnClass={filterBtnActive}
                      addSpan={
                        <span>
                          <img src="/Filter.svg" alt="" />
                        </span>
                      }
                      inputLabel={t("feed.Filters")}
                      onClick={handleFilters}
                    />
                  </div>
                  <div
                    className="filtered-items"
                    style={{ display: manageFilter ? "flex" : "none" }}
                  >
                    {/* sport-filter */}
                    {filterSportBtn ? (
                      <div>
                        <InputButton
                          type={"filter-button"}
                          inputLabel={
                            popularLeague
                              ? popularLeague.sport_name
                              : apiService.getProfileFromStorage()?.Language ==
                                "ua"
                              ? filterSportData.name_ua
                                ? filterSportData.name_ua
                                : filterSportData.name
                              : filterSportData.name
                          }
                          addBtnClass={"filter-data-icon-button"}
                          addSpan={
                            <span>
                              <img src={handleActiveSportLogo()} alt=""></img>
                            </span>
                          }
                          filterCloseIcon={
                            <img
                              src="/filter-close.svg"
                              width="25px"
                              height="25px"
                              className="filter-close-icon"
                              onClick={handleSportHideShow}
                              alt=""
                            />
                          }
                        />
                      </div>
                    ) : (
                      <div className="position-relative">
                        <div
                          className="sport-contains"
                          onClick={handleFliterSport}
                        >
                          <div className="sport-data">{t("feed.Sport")}</div>
                          {sportShow ? (
                            <img
                              src="/black-up.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          ) : (
                            <img
                              src="/black-down.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          )}
                        </div>
                        {/* sport dropdown */}
                        <div
                          className="sport-DropDown"
                          style={{ display: sportShow ? "block" : "none" }}
                        >
                          <SearchBar
                            onChange={handleSportSearch}
                            showSuggetions={sportShow}
                            addMainContainer={"filter-sport-container"}
                            suggetionList={sportData}
                            inputPlaceHolder={t("profile.search")}
                            suggetionData={"Data"}
                            errorMsg={t("feed.NoResultsFound")}
                            displayMsg={sportEmptyMsg}
                          >
                            <div>
                              {sportData.map((item, index) => (
                                <div
                                  className="filtered-items-containter"
                                  key={index}
                                  onClick={() => handleSelectedSport(item)}
                                >
                                  <div className="d-flex">
                                    <div className="item-icon">
                                      <img
                                        src={"sport-logo/" + item.id + ".svg"}
                                        alt=""
                                      />
                                    </div>
                                    <div className="filtered-items-name">
                                      {apiService.getProfileFromStorage()
                                        ?.Language == "ua"
                                        ? item.name_ua
                                          ? item.name_ua
                                          : item.name
                                        : item.name}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </SearchBar>
                        </div>
                      </div>
                    )}
                    {/* tournament-filter */}
                    {filterTournamentBtn ? (
                      <div>
                        <InputButton
                          type={"filter-button"}
                          inputLabel={
                            popularLeague
                              ? popularLeague.tournament_name
                              : filterTournamentData.name
                          }
                          addBtnClass={"filter-data-icon-button"}
                          addSpan={
                            <span>
                              <img
                                src={
                                  popularLeague
                                    ? "/green-sport-logo/" +
                                      popularLeague.sport_id +
                                      ".svg"
                                    : "country-logo/" +
                                      filterTournamentData.country_id +
                                      ".png"
                                }
                                alt=""
                              ></img>
                            </span>
                          }
                          filterCloseIcon={
                            <img
                              src="/filter-close.svg"
                              width="25px"
                              height="25px"
                              className="filter-close-icon"
                              onClick={handleTournamentHideShow}
                              alt=""
                            />
                          }
                        />
                      </div>
                    ) : (
                      <div className="position-relative">
                        <div
                          className="Tournament-contains"
                          onClick={handleFliterTournament}
                        >
                          <div className="Tournament-data">
                            {t("feed.Tournament")}
                          </div>
                          {tournamentShow ? (
                            <img
                              src="/black-up.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          ) : (
                            <img
                              src="/black-down.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          )}
                        </div>
                        {/*tournament-DropDown  */}
                        <div
                          className="tournament-DropDown"
                          style={{ display: tournamentShow ? "block" : "none" }}
                        >
                          <SearchBar
                            showSuggetions={tournamentShow}
                            onChange={handleTournamentSearch}
                            inputPlaceHolder={t("profile.search")}
                            suggetionData={"Data"}
                            addClass={"tournament-container"}
                            addMainContainer={"filter-tournament-container"}
                            errorMsg={t("feed.NoResultsFound")}
                            displayMsg={tournamentEmptyMsg}
                          >
                            <div
                              style={{ display: alertMsg ? "none" : "block" }}
                            >
                              {/* popular-league */}
                              {popularLeagues.length > 0 ? (
                                <div>
                                  <div className="popular-league-title">
                                    {t("profile.popularLeagues")}
                                  </div>
                                  {popularLeagues.map((item, index) => (
                                    <div
                                      className="tournament-container"
                                      key={index}
                                      onClick={() =>
                                        handleSelectedTournament(item)
                                      }
                                    >
                                      <div className="d-flex">
                                        <div className="item-icon">
                                          <img
                                            src={`/country-logo/${item.country_id}.png`}
                                            alt=""
                                          />
                                        </div>
                                        <div className="filtered-items-name">
                                          {item.name}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                ""
                              )}
                              {/* country */}
                              {countryLeaguesData.length > 0 ? (
                                <div className="country-title">
                                  {t("profile.countries")}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="accordion-country-container">
                                {countryLeaguesData.map((item, index) => (
                                  <Accordion key={index}>
                                    {item.id ? (
                                      <Accordion.Item
                                        eventKey={"countryId-" + item.id}
                                      >
                                        <Accordion.Header>
                                          <div className="d-flex">
                                            <div className="model-country-icon">
                                              <img
                                                src={
                                                  "country-logo/" +
                                                  item.id +
                                                  ".png"
                                                }
                                                alt=""
                                              />
                                            </div>
                                            <div className="model-country-name">
                                              {item.name}
                                            </div>
                                          </div>
                                        </Accordion.Header>
                                        {item.leagues.map((data, index) => (
                                          <Accordion.Body key={index}>
                                            <div
                                              className=""
                                              id="countryDropDownId"
                                            >
                                              <div
                                                className=" filter-country-contains"
                                                onClick={() =>
                                                  handleSelectedTournament(data)
                                                }
                                              >
                                                <div className="country-leaguees-name">
                                                  {data.name}
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        ))}
                                      </Accordion.Item>
                                    ) : (
                                      ""
                                    )}
                                  </Accordion>
                                ))}
                              </div>
                            </div>
                            <div
                              className="alert-message"
                              style={{ display: alertMsg ? "block" : "none" }}
                            >
                              {t("feed.alertMsg")}
                            </div>
                          </SearchBar>
                        </div>
                      </div>
                    )}
                    {/* prediction-type */}
                    {filterPredictionTypeBtn ? (
                      <div>
                        <InputButton
                          type={"filter-button"}
                          inputLabel={selectedPredictionType}
                          addBtnClass={"filter-data-icon-button"}
                          filterCloseIcon={
                            <img
                              src="/filter-close.svg"
                              width="25px"
                              height="25px"
                              className="filter-close-icon"
                              onClick={handlePredictionTypeHideShow}
                              alt=""
                            />
                          }
                        />
                      </div>
                    ) : (
                      <div className="position-relative">
                        <div
                          className="PredictionType-contains"
                          onClick={handleFliterPredictionType}
                        >
                          <div className="PredictionType-data">
                            {t("feed.PredictionType")}
                          </div>
                          {PredictionTypeShow ? (
                            <img
                              src="/black-up.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          ) : (
                            <img
                              src="/black-down.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          )}
                        </div>
                        {/* Prediction type */}
                        <div
                          className="PredictionType-DropDown"
                          style={{
                            display: PredictionTypeShow ? "block" : "none",
                          }}
                        >
                          <SearchBar
                            // (e) => setSearchPredictionType(e.target.value)
                            onChange={handlePredictionTypeSearch}
                            showSuggetions={PredictionTypeShow}
                            addMainContainer={"filter-sport-container"}
                            inputPlaceHolder={t("profile.search")}
                            suggetionData={"Data"}
                            displayMsg={predictionTypeEmptyMsg}
                            errorMsg={t("feed.NoResultsFound")}
                          >
                            <div
                              style={{
                                display: typeAlertMsg ? "none" : "block",
                              }}
                            >
                              {/* {console.log("filterPredictionTypeData:::",filterPredictionTypeData)} */}
                              {filterPredictionTypeData.map((item, index) => (
                                <div
                                  className="filtered-items-containter"
                                  key={index}
                                  onClick={() =>
                                    handleSelectedPredictionType(item)
                                  }
                                >
                                  <div className="d-flex">
                                    <div className="filtered-items-name">
                                      {item}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div
                              className="alert-message"
                              style={{
                                display: typeAlertMsg ? "block" : "none",
                              }}
                            >
                              {t("feed.alertMsg")}
                            </div>
                          </SearchBar>
                        </div>
                      </div>
                    )}

                    {/* status */}
                    {filterStatusBtn ? (
                      <div>
                        <InputButton
                          type={"filter-button"}
                          inputLabel={selectedStatus}
                          addBtnClass={"filter-data-icon-button"}
                          filterCloseIcon={
                            <img
                              src="/filter-close.svg"
                              width="25px"
                              height="25px"
                              className="filter-close-icon"
                              onClick={handleStatusHideShow}
                              alt=""
                            />
                          }
                        />
                      </div>
                    ) : (
                      <div className="position-relative">
                        <div
                          className="Status-contains"
                          onClick={handleFilterStatus}
                        >
                          <div className="Status-data">{t("feed.Status")}</div>
                          {statusShow ? (
                            <img
                              src="black-up.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          ) : (
                            <img
                              src="black-down.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          )}
                        </div>
                        {/* Status-DropDown */}
                        <div
                          className="Status-DropDown"
                          style={{ display: statusShow ? "block" : "none" }}
                        >
                          <SearchBar
                            showSuggetions={statusShow}
                            inputPlaceHolder={t("profile.search")}
                            suggetionData={"Data"}
                            isSearchBar={true}
                          >
                            <div
                              className="Pending-containter"
                              onClick={() =>
                                handleStutusType("Pending", t("feed.Pending"))
                              }
                            >
                              {t("feed.Pending")}
                            </div>
                            <div
                              className="Won-containter"
                              onClick={() =>
                                handleStutusType(
                                  "Won",
                                  t("predictionFilter.filterWon")
                                )
                              }
                            >
                              {t("predictionFilter.filterWon")}
                            </div>
                            <div
                              className="Lost-containter"
                              onClick={() =>
                                handleStutusType("Lost", t("feed.statusLost"))
                              }
                            >
                              {t("feed.statusLost")}
                            </div>
                            <div
                              className="Returned-containter"
                              onClick={() =>
                                handleStutusType(
                                  "Returned",
                                  t("predictionFilter.filterReturned")
                                )
                              }
                            >
                              {t("predictionFilter.filterReturned")}
                            </div>
                          </SearchBar>
                        </div>
                      </div>
                    )}

                    {/* odds range button */}
                    {filterOddsRangeBtn ? (
                      <div>
                        <InputButton
                          type={"filter-button"}
                          inputLabel={`${sliderMinRange} - ${sliderMaxRange}`}
                          addBtnClass={"filter-data-icon-button"}
                          filterCloseIcon={
                            <img
                              src="/filter-close.svg"
                              width="25px"
                              height="25px"
                              className="filter-close-icon"
                              onClick={handleOddsRangeHideShow}
                              alt=""
                            />
                          }
                        />
                      </div>
                    ) : (
                      <div className="position-relative">
                        <div
                          className="OddsRange-contains"
                          onClick={handleFilterOddsRange}
                        >
                          <div className="OddsRange-data">
                            {t("feed.OddsRange")}
                          </div>
                          {OddsRange ? (
                            <img
                              src="/black-up.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          ) : (
                            <img
                              src="/black-down.svg"
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          )}
                        </div>
                        {/* odds range */}
                        <div
                          className="OddsRange-DropDown"
                          style={{ display: OddsRange ? "block" : "none" }}
                        >
                          <div className="range-container">
                            <div className="range-slider">
                              <RangeSlider
                                min={1.05}
                                max={20.05}
                                onChange={handleRangeSlider}
                                up={
                                  sliderMinRange == 1.05 &&
                                  sliderMaxRange == 20.05
                                }
                                countData={countData}
                                increamentUserCount={increamentUserCount}
                              />
                            </div>
                            <div className="d-flex justify-content-end">
                              <InputButton
                                type="primary"
                                inputLabel={t("feed.Apply")}
                                onClick={() =>
                                  ApplyOddsRange(sliderMinRange, sliderMaxRange)
                                }
                                addBtnClass={"apply-btn"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* clear all filter */}
                    <div
                      className="Clear-filters-DropDown"
                      style={{
                        display:
                          !filterData.sport_id &&
                          !filterData.tournament_id &&
                          !filterData.predictionType &&
                          !filterData.statusType &&
                          !filterData.oddMin &&
                          !filterData.oddMax
                            ? "none"
                            : "flex",
                      }}
                    >
                      <div
                        className="Clear-filters-contains"
                        onClick={handleClearFilter}
                      >
                        <div className="Clear-filters-data">
                          {t("feed.ClearFilters")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="predictionfound-sortby-contains">
                  <div className="prediction-found-section d-flex">
                    <div className="img-padding">
                      <img src="/ticket.svg" alt="" />
                    </div>
                    <div className="prediction-found-title">
                      <span>{totalBetsData || 0}</span>
                      {t("feed.predictionsFound")}
                    </div>
                  </div>
                  <div className="sortby-section">
                    <div className="SortBy-name">{t("feed.SortBy")}</div>
                    <div className="sortby-container">
                      <div className="sortby-dropdown">
                        <div
                          className="d-flex selected-fliter ignore-dropdown"
                          ref={menuRef}
                          onClick={handleFilterDropDown}
                        >
                          <div className="filter-name d-flex align-items-center">
                            {filteredName === "Odds"
                              ? t("feed.Odds")
                              : filteredName == "Popular"
                              ? t("feed.Popular")
                              : t("feed.Newest")}
                          </div>
                          <div>
                            {dropDownHideShow ? (
                              <img className="ps-3" src="up.svg" alt="" />
                            ) : (
                              <img className="ps-3" src="down.svg" alt="" />
                            )}
                          </div>
                        </div>
                        <ul className="fliter-type ignore-dropdown">
                          <div
                            className="list-drop-down"
                            style={{
                              display: dropDownHideShow ? "block" : "none",
                            }}
                          >
                            <li
                              className="d-flex filter-menu"
                              onClick={() => handleFilterData("Newest")}
                            >
                              <div className="filter-name">
                                {t("feed.Newest")}
                              </div>
                            </li>
                            <li
                              className="d-flex filter-menu"
                              onClick={() => handleFilterData("Popular")}
                            >
                              <div className="filter-name ">
                                {t("feed.Popular")}
                              </div>
                            </li>
                            <li
                              className="d-flex filter-menu"
                              onClick={() => handleFilterData("Odds")}
                            >
                              <div className="filter-name ">
                                {t("feed.Odds")}
                              </div>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {showPredictionLoader ? (
                  <div>
                    <div className="web-PredictionListLOader">
                      <PredictionsListLoader />
                    </div>
                    <div className="mobile-predictionlistLoader">
                      <MobilePredictionListLoader />
                    </div>
                  </div>
                ) : (
                  <div
                    className="pb-3 feed-prediction-list"
                    style={{
                      display: betsData.length > 0 ? "block" : "block",
                    }}
                  >
                    {betsData.length === 0 ? (
                      <NoPredictionList isFollowing={isFollowing} />
                    ) : (
                      <PredictionList
                        listInnerRef={listInnerRef}
                        betsData={betsData}
                        countData={countData}
                        currPage={currPage}
                        pageSize={pageSize}
                        increamentUserCount={increamentUserCount}
                        onClick={ShowMorePredictionsData}
                        totalBetsData={totalBetsData}
                        showMessage={showMessage}
                        showLoadMoreLoader={showLoadMoreLoader}
                      />
                    )}
                  </div>
                )}
                {showPredictionLoader ? (
                  <div className="">
                    <div className="web-PredictionListLOader mt-3">
                      <PredictionListCustomLoader />
                    </div>
                    <div className="mobile-predictionlistLoader mt-3">
                      <MobilePredictionListLoader />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {showPredictionLoader ? (
                  <div className="">
                    <div className="web-PredictionListLOader mt-3">
                      <PredictionListCustomLoader />
                    </div>
                    <div className="mobile-predictionlistLoader mt-3">
                      <MobilePredictionListLoader />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomePageFooter />
    </>
  );
};
export default Feed;
